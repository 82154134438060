import { Classes, Intent } from "@blueprintjs/core";
import { FeatureGate, FeatureGateClickedSource, OrgRole } from "@hex/common";
import React, { ReactNode, useCallback } from "react";
import styled from "styled-components";

import {
  HexLinkButton,
  HexNonIdealState,
  HexNonIdealStateProps,
} from "../../hex-components";
import { useCurrentUser } from "../../hooks/me/useCurrentUser";
import { Routes } from "../../route/routes";
import Diamond from "../../static/illustration/illustration-diamond.inline.svg";
import { trackFeatureGateClicked } from "../../util/featureGateUtils";
import { ContactAnAdmin } from "../common/ContactAnAdmin";

export const ZeroState = styled(HexNonIdealState)`
  padding: 20px;
  .${Classes.NON_IDEAL_STATE_VISUAL} {
    width: 90px;
    height: 90px;
    margin-bottom: 0;

    border: none;
  }
`;

export const FeatureGateIconWrapper = styled.div`
  svg path {
    color: ${({ theme }) => theme.logoColor};
  }
`;
interface FeatureGateIconProps {
  icon?: ReactNode;
}
export const FeatureGateIcon: React.ComponentType<FeatureGateIconProps> =
  React.memo(function FeatureGateIcon({ icon }: FeatureGateIconProps) {
    return (
      <FeatureGateIconWrapper>{icon ?? <Diamond />}</FeatureGateIconWrapper>
    );
  });

type HexLinkButtonProps = React.ComponentProps<typeof HexLinkButton>;

export interface FeatureGateCTAProps {
  /**
   * Name of feature gated feature used for event tracking
   */
  featureGate: FeatureGate;

  /**
   * Props of HexLinkButton
   */
  rel?: HexLinkButtonProps["rel"];
  rightIcon?: HexLinkButtonProps["rightIcon"] | null;
  small?: HexLinkButtonProps["small"];
  subtle?: HexLinkButtonProps["subtle"];
}

export const FeatureGateCTA: React.ComponentType<FeatureGateCTAProps> =
  React.memo(function FeatureGateCTA({
    featureGate,
    rel,
    rightIcon = null,
    small,
    subtle,
  }: FeatureGateCTAProps) {
    const currentUser = useCurrentUser();
    const isAdmin = currentUser?.orgRole === OrgRole.ADMIN;

    const onClick = useCallback(() => {
      trackFeatureGateClicked({
        feature: featureGate,
        source: FeatureGateClickedSource.FEATURE_DISABLED,
        orgRole: currentUser?.orgRole,
      });
    }, [currentUser?.orgRole, featureGate]);

    return (
      <>
        {isAdmin && (
          <div
            css={`
              a {
                text-decoration: none;
              }
            `}
          >
            <HexLinkButton
              intent={Intent.PRIMARY}
              rel={rel}
              rightIcon={rightIcon}
              small={small}
              subtle={subtle}
              target="_blank"
              text="View plans"
              to={Routes.SETTINGS.getUrl({
                subView: "tiers",
              })}
              onClick={onClick}
            />
          </div>
        )}
      </>
    );
  });
export type FeatureGateZeroStateProps = Omit<HexNonIdealStateProps, "action"> &
  FeatureGateCTAProps;

export const FeatureGateZeroState: React.ComponentType<FeatureGateZeroStateProps> =
  React.memo(function FeatureGateZeroState({
    description,
    featureGate,
    icon,
    small,
    ...props
  }: FeatureGateZeroStateProps) {
    const desc = description ?? "Upgrade your plan to use this feature.";
    const currentUser = useCurrentUser();
    const isAdmin = currentUser?.orgRole === OrgRole.ADMIN;
    return (
      <ZeroState
        $small={true}
        action={<FeatureGateCTA featureGate={featureGate} small={small} />}
        description={
          isAdmin ? (
            desc
          ) : (
            <>
              {desc} <ContactAnAdmin />
            </>
          )
        }
        icon={<FeatureGateIcon icon={icon} />}
        title="Time to upgrade?"
        {...props}
      />
    );
  });

// Selector to fade out child elements
export const FeatureGateDisabledOverlay = styled.span<{ $disabled: boolean }>`
  ${({ $disabled }) =>
    $disabled &&
    `
    > * > * {
      opacity: 0.05;
      pointer-events: none;
    }
  `}
  z-index: 0;
`;
