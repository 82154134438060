import React from "react";

import {
  AppIcon,
  GraphIcon,
  NotebookIcon,
} from "../components/icons/CustomIcons.js";

export interface IconReturn {
  logicIcon: JSX.Element;
  appIcon: JSX.Element;
  graphIcon: JSX.Element;
}

// For some reason, having these constants defined in the same file as the `useTerminology` hook
// caused extra files to get imported into e2e tests and a bunch of type errors to be triggered.
// Avoid that all by having these constants in their own file.

export const TERMINOLOGY_ICONS: IconReturn = {
  logicIcon: <NotebookIcon />,
  appIcon: <AppIcon />,
  graphIcon: <GraphIcon />,
};
