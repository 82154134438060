import {
  CellId,
  MagicCellEditTypeLiteral,
  MagicEventId,
  MagicEventStatus,
  MagicKeyword,
  stableEmptyArray,
} from "@hex/common";
import { sortBy } from "lodash";
import { useCallback } from "react";
import { shallowEqual } from "react-redux";

import { useStableRef } from "../../hooks/useStableRef.js";
import { useSelector, useStore } from "../../redux/hooks.js";
import {
  MagicEventMP,
  hexVersionMPSelectors,
} from "../../redux/slices/hexVersionMPSlice.js";
import { useProjectContext } from "../../util/projectContext.js";
import { MagicEventFragment } from "../HexVersionMPModel.generated.js";

export interface UseMagicEventsSelectorArgs<T> {
  selector: (magicEvents: Record<MagicEventId, MagicEventMP | undefined>) => T;
  equalityFn?: (left: T, right: T) => boolean;
}

export function useMagicEventsSelector<T>({
  equalityFn,
  selector,
}: UseMagicEventsSelectorArgs<T>): T {
  const { hexVersionId } = useProjectContext();

  return useSelector((state) => {
    const magicEventsState = hexVersionMPSelectors
      .getMagicEventSelectors(hexVersionId)
      .selectEntities(state);

    if (magicEventsState == null) {
      throw new Error(
        `Missing magicEvents state for hex version: ${hexVersionId}`,
      );
    }

    return selector(magicEventsState);
  }, equalityFn ?? shallowEqual);
}

export function useMagicEventsForCellSelector<T>({
  cellId,
  equalityFn,
  selector,
}: UseMagicEventsForCellSelectorArgs<T>): T {
  const { hexVersionId } = useProjectContext();

  return useSelector((state) => {
    const magicEventsState = hexVersionMPSelectors
      .getMagicEventSelectors(hexVersionId)
      .selectMagicEventsByCellId(state, cellId);

    if (magicEventsState == null) {
      throw new Error(
        `Missing magicEvents state for hex version: ${hexVersionId} and cell ${cellId}`,
      );
    }

    return selector(magicEventsState);
  }, equalityFn ?? shallowEqual);
}

export interface UseMagicEventsForCellSelectorArgs<T> {
  cellId: CellId;
  selector: (magicEvents: MagicEventMP[]) => T;
  equalityFn?: (left: T, right: T) => boolean;
}

export type UseMagicEventSelectorArgs<T> =
  | {
      magicEventId: MagicEventId;
      selector: (magicEvent: MagicEventMP) => T;
      equalityFn?: (left: T, right: T) => boolean;
      safe?: false;
    }
  | {
      magicEventId: MagicEventId | undefined;
      selector: (magicEvent: MagicEventMP | undefined) => T;
      equalityFn?: (left: T, right: T) => boolean;
      safe: true;
    };

export function useMagicEventSelector<T>(
  args: UseMagicEventSelectorArgs<T>,
): T {
  const { hexVersionId } =
    useProjectContext({ safe: args.safe ?? false }) ?? {};

  return useSelector((state) => {
    if (args.magicEventId == null) {
      if (args.safe) {
        const selector = args.selector;
        return selector(undefined);
      } else {
        throw new Error(`Missing state for magicEvent: ${args.magicEventId}`);
      }
    }
    const magicEventState =
      hexVersionId != null
        ? hexVersionMPSelectors
            .getMagicEventSelectors(hexVersionId)
            .selectById(state, args.magicEventId) ?? undefined
        : undefined;

    if (magicEventState == null) {
      if (args.safe) {
        const selector = args.selector;
        return selector(undefined);
      } else {
        throw new Error(`Missing state for magicEvent: ${args.magicEventId}`);
      }
    }

    const selector = args.selector;
    return selector(magicEventState);
  }, args.equalityFn);
}

export type UseChildMagicEventsSelectorArgs<T> = {
  parentEventId: MagicEventId | undefined;
  selector: (magicEvents: readonly MagicEventMP[]) => T;
  equalityFn?: (left: T, right: T) => boolean;
};

export function useChildMagicEventsSelector<T>(
  args: UseChildMagicEventsSelectorArgs<T>,
): T {
  const { hexVersionId } = useProjectContext();
  return useSelector((state) => {
    if (!args.parentEventId)
      return args.selector(stableEmptyArray<MagicEventFragment>());
    const events = hexVersionMPSelectors
      .getMagicEventSelectors(hexVersionId)
      .selectMagicEventsByParentId(state, args.parentEventId);
    return args.selector(events);
  });
}

export type UseChildMagicEventTypeSelector<T> = {
  parentEventId: MagicEventId | undefined;
  eventType: MagicKeyword;
  selector: (magicEvent: MagicEventMP | undefined) => T;
  equalityFn?: (left: T, right: T) => boolean;
};

export function useChildMagicEventTypeSelector<T>(
  args: UseChildMagicEventTypeSelector<T>,
): T {
  return useChildMagicEventsSelector({
    parentEventId: args.parentEventId,
    equalityFn: args.equalityFn ?? shallowEqual,
    selector: (children) => {
      const target = sortBy(children, ["createdDate"])
        .reverse()
        .find((e) => e.eventType === args.eventType);
      return args.selector(target);
    },
  });
}

export type UseLatesteMagicEventForCellSelectorArgs<T> = {
  cellId?: CellId;
  selector: (magicEvent: MagicEventMP | undefined) => T;
  equalityFn?: (left: T, right: T) => boolean;
  safe: boolean;
};

export function useLatestMagicEventForCellSelector<T>(
  args: UseLatesteMagicEventForCellSelectorArgs<T>,
): T {
  const safe = args.safe ?? true;
  const { hexVersionId } = useProjectContext({ safe: safe }) ?? {};

  return useSelector((state) => {
    const cellState =
      hexVersionId != null && args.cellId != null
        ? hexVersionMPSelectors
            .getCellSelectors(hexVersionId)
            .selectById(state, args.cellId) ?? undefined
        : undefined;
    if (cellState?.latestMagicEventId == null || hexVersionId == null) {
      if (safe) {
        return args.selector(undefined);
      } else {
        throw new Error(
          `Missing state for magic event for cell: ${args.cellId}`,
        );
      }
    }
    const magicEventState =
      hexVersionMPSelectors
        .getMagicEventSelectors(hexVersionId)
        .selectById(state, cellState.latestMagicEventId) ?? undefined;

    if (magicEventState == null) {
      if (safe) {
        const selector = args.selector;
        return selector(undefined);
      } else {
        throw new Error(`Missing state for cellid: ${args.cellId}`);
      }
    }
    return args.selector(magicEventState);
  }, args.equalityFn);
}

type SafeMagicEventsMP<S extends boolean> = S extends false
  ? Record<MagicEventId, MagicEventMP | undefined>
  : Record<MagicEventId, MagicEventMP | undefined> | undefined;

export type UseMagicEventsGetterArgs<
  ARGS extends unknown[],
  SAFE extends boolean,
  T,
> = {
  selector?: (cells: SafeMagicEventsMP<SAFE>, ...args: ARGS) => T;
  /**
   * @default false
   */
  safe?: SAFE;
};

export type UseMagicEventsGetterResult<ARGS extends unknown[], T> = (
  ...args: ARGS
) => T;

export function useLatestMagicEventForCellGetter() {
  const store = useStore();
  const { hexVersionId } = useProjectContext();

  return useCallback(
    (cellId: CellId) => {
      const state = store.getState();
      const cellState =
        hexVersionMPSelectors
          .getCellSelectors(hexVersionId)
          .selectById(state, cellId) ?? undefined;

      const lastestEventId = cellState?.latestMagicEventId;

      return (
        lastestEventId &&
        hexVersionMPSelectors
          .getMagicEventSelectors(hexVersionId)
          .selectById(state, lastestEventId)
      );
    },
    [store, hexVersionId],
  );
}

export function useMagicEventsGetter<
  ARGS extends unknown[],
  SAFE extends boolean = false,
  T = Record<MagicEventId, MagicEventMP | undefined>,
>({
  safe,
  selector,
}: UseMagicEventsGetterArgs<ARGS, SAFE, T> = {}): UseMagicEventsGetterResult<
  ARGS,
  T
> {
  const projectContext = useProjectContext({ safe: safe ?? false });
  const store = useStore();
  const selectorRef = useStableRef(selector);

  return useCallback(
    (...args: ARGS) => {
      const magicEvents = projectContext?.hexVersionId
        ? hexVersionMPSelectors
            .getMagicEventSelectors(projectContext.hexVersionId)
            .selectEntities(store.getState())
        : undefined;

      if (magicEvents == null && !safe) {
        throw new Error(
          `Missing magic events state for hex version: ${projectContext?.hexVersionId}`,
        );
      }

      // this cannot be conditionally chained/nullish coalesced since
      // the provided selector may intentionally return undefined
      return selectorRef.current
        ? selectorRef.current(magicEvents as SafeMagicEventsMP<SAFE>, ...args)
        : (magicEvents as T);
    },
    [projectContext?.hexVersionId, store, safe, selectorRef],
  );
}

/*
 * Determines if a MagicEvent can be run and have its results previewed
 **/
export function canRunPendingMagicEvent(event: MagicEventFragment): boolean {
  return (
    event.status === MagicEventStatus.PENDING_REVIEW && // must be pending
    MagicCellEditTypeLiteral.guard(event.eventType) && // must be one of insert/edit/fix/etc
    // MA inserts are special, because even though we leave the event PENDING_REVIEW, we've already updated the cell source.
    // When you run a MA sql cell where the "insert" is the most recent event, we want it to run that cell as if it was a regular cell, and to not show "previeweing magic edit"
    // For single-cell however, inserts do not update the cell source (until accepted), and so running in the case _should_ run the code from the magic event.
    !(event.eventType === MagicKeyword.INSERT && event.parentEventId)
  );
}
