import { HexId } from "@hex/common";
import React from "react";

import { useGlobalHotKey } from "../../hooks/useGlobalHotKey";
import { useDialog } from "../../util/dialogs";
import { HotKeys } from "../../util/hotkeys";

import { ImportHexDialogContents } from "./ImportHexDialogContents.js";

export const ImportNewProjectDialog: React.ComponentType = React.memo(
  function ImportNewProjectDialog() {
    // TODO(file-format): Can uncomment this once we delete the standalone ipynb import dialog,
    // const { isOpen, openDialog } = useDialog("project-import");
    //   useGlobalHotKey(
    //     (evt) => {
    //       if (!isOpen) {
    //         evt.preventDefault();
    //         openDialog();
    //       }
    //     },
    //     [isOpen, openDialog],
    //     { keys: HotKeys.IMPORT_PROJECT },
    //   );
    return <ImportHexDialogContents dialog="project-import" />;
  },
);

interface ImportVersionDialogProps {
  hexId: HexId;
}
export const ImportNewVersionDialog: React.ComponentType<ImportVersionDialogProps> =
  React.memo(function ImportNewVersionDialog({ hexId }) {
    const { isOpen, openDialog } = useDialog("version-import");

    useGlobalHotKey(
      (evt) => {
        if (!isOpen) {
          evt.preventDefault();
          openDialog();
          return true;
        }
        return false;
      },
      [isOpen, openDialog],
      { keys: HotKeys.IMPORT_VERSION, name: "Import new version" },
    );
    return <ImportHexDialogContents dialog="version-import" hexId={hexId} />;
  });
