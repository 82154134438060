import { Classes } from "@blueprintjs/core";
import { ORG_ROLE_ORDERING, OrgRole } from "@hex/common";
import React, { useMemo } from "react";
import styled, { css } from "styled-components";

import { MyOrgRoleDependentRenderer } from "../MyOrgRoleDependentRenderer";

import { CategoryLabel, CategoryLabelProps } from "./CategoryLabel";
import type { LabelNavProps } from "./useLabelNavProps";
import { useLabelNavProps } from "./useLabelNavProps";

// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- filter
const ORG_ROLES_WITH_NAV_ACCESS = ORG_ROLE_ORDERING.filter(
  (orgRole): boolean =>
    ({
      [OrgRole.ADMIN]: true,
      [OrgRole.MANAGER]: true,
      [OrgRole.EDITOR]: true,
      [OrgRole.EXPLORER]: true,
      [OrgRole.MEMBER]: true,
      [OrgRole.GUEST]: false,
      [OrgRole.EMBEDDED_USER]: false,
      [OrgRole.ANONYMOUS]: false,
    })[orgRole],
);

const sharedStyles = css`
  display: flex;

  .${Classes.POPOVER_TARGET} {
    display: flex;
  }
`;

const CategoryButton = styled.div`
  ${sharedStyles}
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.hoverColor};
  }

  &:active {
    background-color: ${({ theme }) => theme.activeColor};
  }
`;

const CategoryNonButton = styled.div`
  ${sharedStyles}
`;

export type CategoryLabelNavButtonProps = LabelNavProps & CategoryLabelProps;

export const CategoryLabelNavButton: React.ComponentType<CategoryLabelNavButtonProps> =
  React.memo(function CategoryLabelNavButton({
    category,
    homeSubView,
    target,
    ...categoryOmittedLabelProps
  }: CategoryLabelNavButtonProps) {
    const categoryNames = useMemo(() => {
      return [category.name];
    }, [category]);

    const onClickProps = useLabelNavProps({
      categoryNames,
      homeSubView,
      target,
    });

    const label = (
      <CategoryLabel {...categoryOmittedLabelProps} category={category} />
    );
    return (
      <MyOrgRoleDependentRenderer allowedOrgRoles={ORG_ROLES_WITH_NAV_ACCESS}>
        {(allowed) =>
          allowed ? (
            <CategoryButton {...onClickProps}>{label}</CategoryButton>
          ) : (
            <CategoryNonButton>{label}</CategoryNonButton>
          )
        }
      </MyOrgRoleDependentRenderer>
    );
  });
