import {
  AppSessionId,
  ModifiedParamCellsAndFilters,
  SavedViewId,
  stableEmptyArray,
} from "@hex/common";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { DisplayTableFilterSessionStateMpFragment } from "../../components/saved-views/queries.generated.js";
import { RootState } from "../store.js";

import {
  FilterCellSessionStateMP,
  ParameterValueMP,
  SharedFilterSessionStateMP,
} from "./appSessionMPSlice.js";

type DefaultAppSessionState = {
  parameterValues: ParameterValueMP[];
  filterCellSessionStates: FilterCellSessionStateMP[];
  displayTableFilterStates: DisplayTableFilterSessionStateMpFragment[];
  sharedFilterSessionStates: SharedFilterSessionStateMP[];
};

interface SavedViewDefaultAppSession {
  // The "base" data for the saved view that is currently selected, based on user
  // interaction of editing or selecting a new view.
  id: SavedViewId | null;
  isEditing: boolean | null;

  // The saved view data that is currently selected. This data is fetched from the server when
  // toggling between views, and is used to populate the saved view changes dropdown or local changes.
  savedView: {
    name: string;
    shared: boolean;
    defaultAppSessionId: AppSessionId;
    defaultState: DefaultAppSessionState;
    modifiedParams: ModifiedParamCellsAndFilters | null;
  } | null;

  // If there is no saved view, we need to track the default app session state of the published version for view comparison.
  defaultAppSessionState: DefaultAppSessionState | null;
}

const savedViewDefaultAppSessionSlice = createSlice({
  name: "savedViewDefaultAppSession",
  initialState: null as SavedViewDefaultAppSession | null,
  reducers: {
    /**
     * When there is no saved view default app session, we should load in the published version's default app session for comparison. This
     * is because editors can save app session states during the publish dialog, meaning some params will be assigned to the published app.
     */
    intializePublishedAppDefaultSession(
      state,
      action: PayloadAction<Omit<
        SavedViewDefaultAppSession,
        "isEditing"
      > | null>,
    ) {
      if (action.payload == null) {
        return null;
      }
      return {
        ...action.payload,
        // Respect the previous editing state when initializing the saved view default app session state.
        isEditing: state?.isEditing ?? false,
      };
    },
    /**
     * Use this reducer when initializing the saved view default app session state,
     * once its been fetched from the server.
     */
    initializeSavedViewDefaultAppSession(
      state,
      action: PayloadAction<Omit<
        SavedViewDefaultAppSession,
        "isEditing"
      > | null>,
    ) {
      if (action.payload == null) {
        return null;
      }
      return {
        ...action.payload,
        // Respect the previous editing state when initializing the saved view default app session state.
        isEditing: state?.isEditing ?? false,
      };
    },
    setSavedViewId(state, action: PayloadAction<SavedViewId | null>) {
      if (action.payload == null) {
        return null;
      }

      const isSameViewId = state?.id === action.payload;
      return {
        // clear the data for the savedView if we are changing the savedViewId.
        ...(isSameViewId ? state : null),
        isEditing: isSameViewId ? state.isEditing : false,
        id: action.payload,
        savedView: state?.savedView ?? null,
        defaultAppSessionState: state?.defaultAppSessionState ?? null,
      };
    },
    enableEditing(state, action: PayloadAction<SavedViewId>) {
      return {
        ...(state?.id === action.payload ? state : null),
        id: action.payload,
        isEditing: true,
        savedView: state?.savedView ?? null,
        defaultAppSessionState: state?.defaultAppSessionState ?? null,
      };
    },
    disableEditing(state) {
      if (state) {
        state.isEditing = false;
      }
    },
  },
});

/**
 * Helper method to choose the default state to use.
 */
const getAppSessionState = (state: RootState) => {
  return (
    state.savedViewDefaultAppSession?.savedView?.defaultState ??
    state.savedViewDefaultAppSession?.defaultAppSessionState
  );
};

export const savedViewDefaultAppSessionSelectors = {
  selectParameterValues: (state: RootState) =>
    getAppSessionState(state)?.parameterValues ?? stableEmptyArray(),
  selectFilterCellSessionStates: (state: RootState) =>
    getAppSessionState(state)?.filterCellSessionStates ?? stableEmptyArray(),
  selectSharedFilterSessionStates: (state: RootState) =>
    getAppSessionState(state)?.sharedFilterSessionStates ?? stableEmptyArray(),
  selectDisplayTableFilterStates: (state: RootState) =>
    getAppSessionState(state)?.displayTableFilterStates ?? stableEmptyArray(),
  selectCurrentSavedViewId: (state: RootState) =>
    state.savedViewDefaultAppSession?.id,
  selectIsEditing: (state: RootState) =>
    state.savedViewDefaultAppSession?.isEditing ?? false,
  selectModifiedParams: (state: RootState) =>
    state.savedViewDefaultAppSession?.savedView?.modifiedParams,
};

export const savedViewDefaultAppSessionActions = {
  ...savedViewDefaultAppSessionSlice.actions,
};

export const {
  disableEditing,
  enableEditing,
  initializeSavedViewDefaultAppSession,
  intializePublishedAppDefaultSession,
  setSavedViewId,
} = savedViewDefaultAppSessionSlice.actions;

export const savedViewDefaultAppSessionReducer =
  savedViewDefaultAppSessionSlice.reducer;
