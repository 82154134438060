/**
 * Cell References V3 changes how we store, parse, and use cell references for the project graph V3. The main changes are:
 * - Cells that have multiple editable sections may now store cell references per section instead of a single merged global value.
 *   (e.g. SQL cell storing as both SQL + Jinja, or Single Value Cell storing "title", "description", and "value" separately)
 * - References and Parse Errors are now stored together in a single value instead of as different fields, to halve the number of columns needed from above,
 *   as well as make it easier to reference them since they were usually referenced together. Updating in place should always use the `updateStoredCellReferencesV3`
 *   function in order to merge parse errors + previous references if applicable.
 *
 * - References have been split into 3 stages + types:
 *   - StoredReferences:   The minimal, raw references computed by the AST service. Nothing in these should be computable by the backend, only what is necessary via the AST service.
 *                         Changes to anything that might affect these must always recompute these by remotely calling the ast service. These are completely unaware of any other cells/nodes in the project,
 *                         meaning they don't know if/where variables are defined upstream etc.
 *   - ComputedReferences: The combination of StoredReferences as well as any references that can be computed via other DB fields such as "resultVariable" for SQL cells or the input dataframes for chart cells.
 *                         These also should be completely independent of other cells/nodes in the project like StoredReferences.
 *   - GraphNodes:         A single node in the fully-linked graph that combines all of the ComputedReferences for cells as well as any non-cell references.
 *                         These contain not only input/output variables, but the nodeIds of where they are linked as well.
 */

/* eslint-disable tree-shaking/no-side-effects-in-initialization */
import {
  Boolean,
  Literal,
  Null,
  Optional,
  Array as RArray,
  Record as RRecord,
  Static,
  String,
  Undefined,
  Union,
} from "runtypes";

import { assertNever } from "../errors.js";
import { DataConnectionId } from "../idTypeBrands";
import { notEmpty } from "../notEmpty";
import { getNormalEnum } from "../runtypeEnums";
import { safeCastVariableName } from "../safeCastVariableName";
import {
  DataSourceTableConfig,
  NoCodeCellDataframe,
  getDataframeName,
} from "../sql/dataSourceTableConfig.js";
import { ParameterName, VariableName } from "../typeBrands";

import {
  CellReferencesParseError,
  CellReferencesV1,
  FunctionDefinition,
  ParamReference,
  SqlCellReferencesV1,
} from "./cellReferencesV1";
import {
  CellReferencesV2,
  ErrorDefinition,
  SqlCellReferencesV2,
  mergeCellReferencesV2,
  upgradeCellReferencesV1,
} from "./cellReferencesV2";
import {
  SqlSafeExpression,
  SqlSafeTableReference,
  StaticTableReference,
  TableReference,
  applyTransform,
} from "./jinjasql";

export function upgradeCellReferencesV2<T extends CellReferencesV2>(
  references: T,
  parseError: CellReferencesParseError | null,
): Omit<T, keyof CellReferencesV2> & StoredCellReferencesV3 {
  return {
    ...references,
    parseError: parseError?.error ?? null,
  };
}

export function mergeStoredCellReferencesV3(
  ...refs: Array<StoredCellReferencesV3 | null | undefined>
): StoredCellReferencesV3 {
  const firstParseError = refs.map((ref) => ref?.parseError).find(notEmpty);
  return upgradeCellReferencesV2(
    mergeCellReferencesV2(...refs),
    firstParseError != null
      ? {
          error: firstParseError,
        }
      : null,
  );
}

/**
 * Takes in two cell references for the same section, and combines them by either merging in a parseError, or replacing the old references entierly
 */
export function updateStoredCellReferencesV3<T extends StoredCellReferencesV3>(
  existingRefs: T,
  newRefs: T,
): T {
  // If we have a parse error, keep the previous known state just with the parse error as an extra field
  if (newRefs.parseError != null) {
    return {
      ...existingRefs,
      parseError: newRefs.parseError,
    };
  } else {
    return newRefs;
  }
}

export const StoredCellReferencesV3 = RRecord({
  referencedParams: RArray(ParamReference).asReadonly(),
  newParams: RArray(ParamReference).asReadonly(),
  parseError: Union(String, Null),
});
export type StoredCellReferencesV3 = Static<typeof StoredCellReferencesV3>;

export const upgradeToStoredCellReferencesV3 = (
  references:
    | CellReferencesV1
    | CellReferencesV2
    | StoredCellReferencesV3
    | null,
  parseError: CellReferencesParseError | null,
): StoredCellReferencesV3 => {
  return StoredCellReferencesV3.guard(references)
    ? references
    : CellReferencesV2.guard(references)
      ? {
          ...upgradeCellReferencesV2(references, parseError),
        }
      : CellReferencesV1.guard(references)
        ? {
            ...upgradeCellReferencesV2(
              upgradeCellReferencesV1(references),
              parseError,
            ),
          }
        : references ?? {
            newParams: [],
            referencedParams: [],
            parseError: parseError?.error ?? null,
          };
};

export const StoredSqlReferencesV3 = StoredCellReferencesV3.extend({
  // These are just guesses, as depending on the jinja template, we might not be able to parse it at all
  singleStatement: Boolean,
  onlySelects: Boolean,
  isDynamicallyGenerated: Boolean, // Whether or not "unsafe" sql is used. e.g. `sqlsafe` or jinja blocks
  accurateTables: Optional(Boolean), // set for newer Dataframe SQL cells which use duckdb itself to parse out tables
});
export type StoredSqlReferencesV3 = Static<typeof StoredSqlReferencesV3>;

export const upgradeToSqlCellSQLReferencesV3 = (
  references:
    | CellReferencesV1
    | SqlCellReferencesV1
    | SqlCellReferencesV2
    | StoredSqlReferencesV3
    | null,
  parseError: CellReferencesParseError | null,
): StoredSqlReferencesV3 => {
  // Assume the best case to avoid warnings when first converting
  const defaults = {
    isDynamicallyGenerated: false,
    onlySelects: true,
    singleStatement: true,
  };
  return CellReferencesV1.guard(references)
    ? {
        ...upgradeCellReferencesV2(
          upgradeCellReferencesV1(references),
          parseError,
        ),
        ...defaults,
      }
    : CellReferencesV2.guard(references)
      ? {
          ...upgradeCellReferencesV2(references, parseError),
          ...defaults,
        }
      : references ?? {
          newParams: [],
          referencedParams: [],
          parseError: parseError?.error ?? null,
          ...defaults,
        };
};

export const StoredJinjaSqlReferences = StoredCellReferencesV3.extend({
  // `referencedParams` inherited from `StoredCellReferencesV3` contains only
  // params referenced via jinja
  sqlsafe: RArray(SqlSafeExpression).asReadonly(),
  tables: RArray(TableReference).asReadonly(),
  singleStatement: Boolean,
  onlySelects: Boolean,
});
export type StoredJinjaSqlReferences = Static<typeof StoredJinjaSqlReferences>;

export function convertJinjaSqlReferences({
  jinjaSqlReferences: {
    onlySelects,
    referencedParams: jinjaReferencedParams,
    singleStatement,
    sqlsafe,
    tables,
  },
  oldJinjaRefs = null,
  parameterOptions,
}: {
  jinjaSqlReferences: StoredJinjaSqlReferences;
  parameterOptions: Map<ParameterName, string[]>;
  // SUP-1720: jinjaSqlReferences is supposed to supersede jinjaCellReferencesV3, but
  // there is a bug where SQL parsing errors cause Jinja references not to be included.
  // we still have the separately parsed jinjaCellReferencesV3 so merge them in here
  oldJinjaRefs?: StoredCellReferencesV3 | null;
}): [StoredSqlReferencesV3, StoredCellReferencesV3] {
  let isDynamicallyGenerated = false;
  const sqlReferencedParams: ParamReference[] = [];
  const expandSegmentOptions = (
    segment: StaticTableReference | SqlSafeTableReference,
  ): string[] => {
    if (segment.type === "static") {
      return [segment.name];
    } else if (segment.type === "sqlsafe") {
      const expression = sqlsafe[segment.index]!;
      return expression.outputs.flatMap(({ name, transform }) => {
        const knownOptions = parameterOptions.get(name as ParameterName);
        if (knownOptions == null || transform?.type === "unknown") {
          isDynamicallyGenerated = true;
          return [];
        } else {
          return knownOptions.map((option) =>
            applyTransform(option, transform),
          );
        }
      });
    } else {
      assertNever(segment, segment);
    }
  };
  for (const table of tables) {
    if (table.type === "static") {
      sqlReferencedParams.push({
        param: safeCastVariableName(table.name),
        locations: table.locations.flat(),
      });
    } else if (table.type === "dynamic") {
      const dynamicCandidates = table.segments.reduce<string[]>(
        (candidatePrefixes, segment) => {
          return expandSegmentOptions(segment).flatMap((option) =>
            candidatePrefixes.map((prefix) => prefix + option),
          );
        },
        [""],
      );
      sqlReferencedParams.push(
        ...dynamicCandidates.map((param) => ({
          param: safeCastVariableName(param),
          locations: [],
        })),
      );
    } else {
      assertNever(table, table);
    }
  }
  const sqlReferences: StoredSqlReferencesV3 = {
    referencedParams: sqlReferencedParams,
    singleStatement,
    onlySelects,
    isDynamicallyGenerated,
    newParams: [],
    parseError: null,
  };
  const jinjaReferences: StoredCellReferencesV3 = {
    referencedParams: jinjaReferencedParams,
    newParams: [],
    parseError: null,
  };
  return [
    sqlReferences,
    mergeStoredCellReferencesV3(jinjaReferences, oldJinjaRefs),
  ];
}

export const StoredPythonReferencesV3 = StoredCellReferencesV3.extend({
  imports: RArray(VariableName).asReadonly(),
  importStrings: RArray(String).asReadonly(),
  functions: RArray(FunctionDefinition).asReadonly(),
  errors: RArray(ErrorDefinition).asReadonly().optional(),
});
export type StoredPythonReferencesV3 = Static<typeof StoredPythonReferencesV3>;

export const StoredRReferencesV3 = StoredPythonReferencesV3;
export type StoredRReferencesV3 = Static<typeof StoredRReferencesV3>;

export const ComputedGenericReferencesTypeV3 = Literal("GENERIC");
export type ComputedGenericReferencesTypeV3 = Static<
  typeof ComputedGenericReferencesTypeV3
>;

export const ComputedCodeReferencesTypeV3 = Literal("CODE");
export type ComputedCodeReferencesTypeV3 = Static<
  typeof ComputedCodeReferencesTypeV3
>;

// SQL is distinct from CODE, because it can either be remote or dataframe and its references change meaning depending on which it is
export const ComputedSqlReferencesTypeV3 = Literal("SQL");
export type ComputedSqlReferencesTypeV3 = Static<
  typeof ComputedSqlReferencesTypeV3
>;

// FILTER needs its own type because it has a query mode
export const ComputedFilterReferencesTypeV3 = Literal("FILTER");
export type ComputedFilterReferencesTypeV3 = Static<
  typeof ComputedFilterReferencesTypeV3
>;

// No code cells like chart, table that can have NoCodeCellDataframes
export const ComputedNoCodeReferencesTypeV3 = Literal("NO_CODE");
export type ComputedNoCodeReferencesTypeV3 = Static<
  typeof ComputedNoCodeReferencesTypeV3
>;

export const ComputedReferencesTypeV3Literal = Union(
  ComputedGenericReferencesTypeV3,
  ComputedCodeReferencesTypeV3,
  ComputedSqlReferencesTypeV3,
  ComputedFilterReferencesTypeV3,
  ComputedNoCodeReferencesTypeV3,
);

export type ComputedReferencesTypeV3 = Static<
  typeof ComputedReferencesTypeV3Literal
>;
export const ComputedReferencesTypeV3 = getNormalEnum(
  ComputedReferencesTypeV3Literal,
);

export const TypedComputedCellReferencesV3 = StoredCellReferencesV3.extend({
  type: ComputedReferencesTypeV3Literal,
});
export type TypedComputedCellReferencesV3 = Static<
  typeof TypedComputedCellReferencesV3
>;

export const ComputedGenericCellReferencesV3 =
  TypedComputedCellReferencesV3.extend({
    type: ComputedGenericReferencesTypeV3,
  });
export type ComputedGenericCellReferencesV3 = Static<
  typeof ComputedGenericCellReferencesV3
>;

export function getComputedGenericCellReferencesV3(
  references: StoredCellReferencesV3,
): ComputedGenericCellReferencesV3 {
  return {
    ...references,
    type: ComputedReferencesTypeV3.GENERIC,
  };
}

export const ComputedCodeCellReferencesV3 =
  TypedComputedCellReferencesV3.extend({
    type: ComputedCodeReferencesTypeV3,
    imports: RArray(VariableName).asReadonly(),
    functions: RArray(FunctionDefinition).asReadonly(),
    errors: RArray(ErrorDefinition).asReadonly(),
  });
export type ComputedCodeCellReferencesV3 = Static<
  typeof ComputedCodeCellReferencesV3
>;

export function getComputedCodeCellReferencesV3(
  references: StoredPythonReferencesV3 | StoredRReferencesV3,
): ComputedCodeCellReferencesV3 {
  return {
    ...references,
    errors: references.errors ?? [],
    type: ComputedReferencesTypeV3.CODE,
  };
}

export const ComputedSqlCellReferencesV3 = TypedComputedCellReferencesV3.extend(
  {
    type: ComputedSqlReferencesTypeV3,
    dataConnectionId: Union(DataConnectionId, Null, Undefined),
    isDataFrame: Boolean,
    preview: Boolean,
    isDynamicallyGenerated: Boolean,
    onlySelects: Boolean,
  },
);
export type ComputedSqlCellReferencesV3 = Static<
  typeof ComputedSqlCellReferencesV3
>;

export function getComputedSqlCellReferencesV3(
  references: StoredSqlReferencesV3 | undefined = {
    newParams: [],
    referencedParams: [],
    parseError: null,
    onlySelects: true,
    singleStatement: true,
    isDynamicallyGenerated: false,
  },
  sqlCell: {
    connectionId: DataConnectionId | null | undefined;
    dataFrameCell: boolean;
    resultVariable: string;
    loadIntoDataFrame: boolean;
  },
): ComputedSqlCellReferencesV3 {
  return {
    ...references,
    // New params aren't parsed in the SQL itself, so we inject it via this
    newParams: [
      { param: safeCastVariableName(sqlCell.resultVariable), locations: [] },
    ],
    dataConnectionId: sqlCell.connectionId,
    isDataFrame: sqlCell.dataFrameCell,
    isDynamicallyGenerated: references.isDynamicallyGenerated,
    preview: !sqlCell.loadIntoDataFrame,
    type: ComputedReferencesTypeV3.SQL,
  };
}

export const ComputedFilterCellReferencesV3 =
  TypedComputedCellReferencesV3.extend({
    type: ComputedFilterReferencesTypeV3,
    sourceParam: Union(ParamReference, Null),
    preview: Boolean,
    dataConnectionId: Union(DataConnectionId, Null, Undefined),
    isNoCodeDataFrame: Boolean,
  });
export type ComputedFilterCellReferencesV3 = Static<
  typeof ComputedFilterCellReferencesV3
>;

export function getComputedFilterCellReferencesV3(filterCell: {
  dataframe: NoCodeCellDataframe | null;
  useQueryMode: boolean;
  resultVariable: string;
  filtersReferences: CellReferencesV2 | null;
  filtersReferencesParseError: CellReferencesParseError | null;
}): ComputedFilterCellReferencesV3 {
  const sourceParam: ParamReference | null = filterCell.dataframe
    ? {
        param: safeCastVariableName(getDataframeName(filterCell.dataframe)),
        locations: [],
      }
    : null;
  const referencedParams = [sourceParam];

  const resultParam: ParamReference = {
    param: safeCastVariableName(filterCell.resultVariable),
    locations: [],
  };

  if (filterCell.filtersReferences != null) {
    const filtersReferencesV3 = upgradeCellReferencesV2(
      filterCell.filtersReferences,
      filterCell.filtersReferencesParseError,
    );
    referencedParams.push(...filtersReferencesV3.referencedParams);
  }

  let isNoCodeDataFrame: boolean;
  let dataConnectionId: DataConnectionId | null | undefined;
  if (DataSourceTableConfig.guard(filterCell.dataframe)) {
    isNoCodeDataFrame = true;
    dataConnectionId = filterCell.dataframe.connectionId;
  } else {
    isNoCodeDataFrame = false;
    dataConnectionId = null;
  }

  return {
    referencedParams: referencedParams.filter(notEmpty),
    newParams: [resultParam],
    parseError: null,
    type: ComputedReferencesTypeV3.FILTER,
    preview: filterCell.useQueryMode,
    sourceParam,
    isNoCodeDataFrame,
    dataConnectionId,
  };
}

export const ComputedNoCodeCellReferencesV3 =
  TypedComputedCellReferencesV3.extend({
    type: ComputedNoCodeReferencesTypeV3,
    dataConnectionId: Union(DataConnectionId, Null, Undefined),
    isNoCodeDataFrame: Boolean,
  });
export type ComputedNoCodeCellReferencesV3 = Static<
  typeof ComputedNoCodeCellReferencesV3
>;

export function getComputedNoCodeCellReferencesV3({
  dataframe,
  references,
}: {
  dataframe: NoCodeCellDataframe | null;
  references: StoredCellReferencesV3;
}): ComputedNoCodeCellReferencesV3 {
  let isNoCodeDataFrame: boolean;
  let dataConnectionId: DataConnectionId | null | undefined;

  if (DataSourceTableConfig.guard(dataframe)) {
    isNoCodeDataFrame = true;
    dataConnectionId = dataframe.connectionId;
  } else {
    isNoCodeDataFrame = false;
    dataConnectionId = null;
  }

  return {
    ...references,
    dataConnectionId,
    isNoCodeDataFrame,
    type: ComputedReferencesTypeV3.NO_CODE,
  };
}

export const ComputedCellReferencesV3 = Union(
  ComputedCodeCellReferencesV3,
  ComputedGenericCellReferencesV3,
  ComputedSqlCellReferencesV3,
  ComputedFilterCellReferencesV3,
  ComputedNoCodeCellReferencesV3,
);
export type ComputedCellReferencesV3 = Static<typeof ComputedCellReferencesV3>;
