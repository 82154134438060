export interface TextReturn {
  dataBrowserText: string;
  dataBrowserActionText: string;
  logicText: string;
  graphText: string;
  appBuilderText: string;
  appPreviewText: string;
  referringToPublishedAppText: string;
  lastUpdatedByUserText: string;
  appViewText: string;
  appLastViewedByAnyoneText: string;
  lastViewedByYou: string;
  appLastPublishedText: string;
  yourAccessText: string;
  createdByYou: string;
  projectNameColumnHeaderText: string;
  projectNameText: string;
  findText: string;
  categoryText: string;
  statusText: string;
  filterText: string;
  explorerScheduleLimitEnableText: string;
  explorerScheduleLimitCreateText: string;
}

// For some reason, having these constants defined in the same file as the `useTerminology` hook
// caused extra files to get imported into e2e tests and a bunch of type errors to be triggered.
// Avoid that all by having these constants in their own file.

export const TERMINOLOGY: TextReturn = {
  dataBrowserText: "Data browser",
  dataBrowserActionText: "Browse data",
  logicText: "Notebook",
  graphText: "Graph",
  appBuilderText: "App builder",
  // In publish diff and approval(review) modals... a preview of the app that will be published!
  appPreviewText: "App preview",
  // Should generally only use singular "App" if referring to the live, published app
  referringToPublishedAppText: "App",
  lastUpdatedByUserText: "Last edited",
  appViewText: "App views",
  appLastViewedByAnyoneText: "App last viewed",
  appLastPublishedText: "App last published",
  yourAccessText: "Your access",
  createdByYou: "Created by you",
  lastViewedByYou: "Last viewed by you",
  projectNameColumnHeaderText: "Title",
  projectNameText: "Project title",
  findText: "Find",
  categoryText: "Category",
  statusText: "Status",
  filterText: "Filter",
  explorerScheduleLimitEnableText:
    "Explorers can have one enabled schedule per project. Disable your other schedule to enable this one.",
  explorerScheduleLimitCreateText:
    "Explorers can create one schedule per project. Use an existing schedule or disable your current one to create a new schedule.",
};
