import {
  HexId,
  MAX_PROJECT_IMPORT_FILE_SIZE,
  SpecialVersionType,
  checkForWarnings,
} from "@hex/common";
import filesize from "filesize";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { HexDialog } from "../../hex-components/HexDialog.js";
import {
  useImportIpynbMutation,
  useImportNewProjectVersionMutation,
  useImportProjectMutation,
} from "../../mutations/import.generated.js";
import { useDispatch, useSelector } from "../../redux/hooks";
import {
  clearImportWarnings,
  selectImportWarningsState,
  setImportWarnings,
} from "../../redux/slices/logicViewSlice.js";
import { Routes } from "../../route/routes.js";
import { useDialog } from "../../util/dialogs.js";

import { ImportProjectWarnings } from "./ImportProjectWarnings.js";
import { Uploader } from "./Uploader.js";

interface BaseImportDialogProps {
  dialog: "project-import" | "component-import" | "version-import";
  hexId?: HexId;
}

export const ImportHexDialogContents: React.FunctionComponent<
  BaseImportDialogProps
> = ({ dialog, hexId }) => {
  const history = useHistory();
  const { closeDialog, isOpen } = useDialog(dialog);
  const dispatch = useDispatch();

  const [uploadError, setUploadError] = useState(false);

  const closeUploadDialogCallback = useCallback(
    (args?: { navigate: boolean }) => {
      closeDialog(args);
      dispatch(clearImportWarnings());
      setUploadError(false);
    },
    [closeDialog, dispatch],
  );

  const closeUploadDialogNoArgs = useCallback(() => {
    closeUploadDialogCallback();
  }, [closeUploadDialogCallback]);

  const [
    importVersion,
    { error: importVersionError, loading: importingVersion },
  ] = useImportNewProjectVersionMutation();
  const [importIpynb, { error: importIpynbError, loading: importingIpynb }] =
    useImportIpynbMutation();
  const [
    importProject,
    { error: importProjectError, loading: importingProject },
  ] = useImportProjectMutation();

  const importing = importingVersion || importingIpynb || importingProject;
  const importError =
    importVersionError || importIpynbError || importProjectError;

  const isComponent = dialog === "component-import";
  const isVersionImport = dialog === "version-import";

  const importCommand = useCallback(
    (reader: FileReader, fileName: string) => {
      if (
        fileName.toLowerCase().endsWith(".yaml") ||
        fileName.toLowerCase().endsWith(".yml")
      ) {
        if (hexId && isVersionImport) {
          return importVersion({
            variables: {
              contents: reader.result as string,
              hexId: hexId,
            },
          }).then(({ data }) => {
            const hasWarning = checkForWarnings(
              data?.importNewVersion.warnings,
            );
            if (data) {
              if (hasWarning) {
                dispatch(setImportWarnings(data.importNewVersion.warnings));
              } else {
                closeUploadDialogCallback({ navigate: false });
              }
              Routes.push(history, Routes.LOGIC, {
                hexId,
                version: data.importNewVersion.hexVersion.version,
                urlParams: hasWarning ? { dialog } : undefined,
              });
            }
          });
        } else {
          return importProject({
            variables: {
              contents: reader.result as string,
              importAsComponent: isComponent,
            },
          }).then(({ data }) => {
            if (data) {
              const hasWarning = checkForWarnings(
                data?.importNewProject.warnings,
              );
              if (hasWarning) {
                dispatch(setImportWarnings(data.importNewProject.warnings));
              } else {
                closeUploadDialogCallback({ navigate: false });
              }
              Routes.push(history, Routes.LOGIC, {
                hexId: data.importNewProject.hex.id,
                version: SpecialVersionType.DRAFT,
                urlParams: hasWarning ? { dialog } : undefined,
              });
            }
          });
        }
      } else {
        return importIpynb({
          variables: {
            name: fileName,
            contents: reader.result as string,
            importAsComponent: isComponent,
          },
        }).then(({ data }) => {
          // we're about to navigate away anyway and the goBack interferes
          // with the navigation
          closeUploadDialogCallback({ navigate: false });
          if (data?.importIpynb?.id) {
            Routes.push(history, Routes.LOGIC, {
              hexId: data.importIpynb.id,
              version: SpecialVersionType.DRAFT,
            });
          }
        });
      }
    },
    [
      closeUploadDialogCallback,
      dialog,
      dispatch,
      hexId,
      history,
      importIpynb,
      importProject,
      importVersion,
      isComponent,
      isVersionImport,
    ],
  );

  const onUpload = useCallback(
    (file) => {
      const reader = new FileReader();
      setUploadError(false);

      reader.onabort = (e) => {
        setUploadError(true);
        console.warn("file reading was aborted", e);
      };
      reader.onerror = (e) => {
        setUploadError(true);
        console.error("file reading has failed", e);
      };
      reader.onload = () => {
        importCommand(reader, file.name).catch(() => {
          setUploadError(true);
        });
      };
      reader.readAsText(file);
    },
    [importCommand],
  );

  const warnings = useSelector(selectImportWarningsState);
  const dialogTitle = warnings
    ? `${isComponent ? "Component" : "Project"} imported with warnings`
    : isVersionImport
      ? "Upload Hex project (.yaml) file as new version"
      : `Upload Hex project (.yaml) or Jupyter (.ipynb) file as new ${isComponent ? "component" : "project"}`;

  return (
    <StyledDialog
      isCloseButtonShown={true}
      isOpen={isOpen}
      title={dialogTitle}
      usePortal={true}
      onClose={closeUploadDialogNoArgs}
    >
      {warnings ? (
        <ImportProjectWarnings
          closeDialog={closeUploadDialogNoArgs}
          dialog={dialog}
          warnings={warnings}
        />
      ) : (
        <Uploader
          accept={{
            human: `Only Hex .yaml${
              isVersionImport ? "" : " and Jupyter .ipynb"
            } files are supported`,
            regex: isVersionImport
              ? /^.*\.(yaml|yml)$/i
              : /^.*\.(yaml|yml|ipynb)$/i,
          }}
          clearFileSideEffect={() => setUploadError(false)}
          error={uploadError}
          errorMessage={importError?.message}
          instructionText={`Drag and drop .yaml${
            isVersionImport ? "" : " or .ipynb"
          }...`}
          maxFileSize={{
            human: filesize(MAX_PROJECT_IMPORT_FILE_SIZE, { round: 0 }),
            value: MAX_PROJECT_IMPORT_FILE_SIZE,
          }}
          uploading={importing}
          onUpload={onUpload}
        />
      )}
    </StyledDialog>
  );
};
const StyledDialog = styled(HexDialog)`
  && {
    width: 600px;

    padding: 0;
  }
`;
