import { Literal, Static, Union } from "runtypes";

import { DEFAULT_CHART_COLOR_PALETTE } from "./chart/colors";
import { ProjectLanguage } from "./enums";
import { PythonIdentifier } from "./identifiers/python";
import { RIdentifier } from "./identifiers/rlang";
import { ParameterOutputType } from "./parameterType";
import { ParameterName } from "./typeBrands";

export interface AutomaticParameter<T extends AutomaticParameterNameLiteral> {
  name: T & AutomaticParameterName;
  outputType: ParameterOutputType;
  description?: string;
  exampleValue: Record<ProjectLanguage, string>;
}

export const AutomaticParameterNameLiteral = Union(
  Literal("hex_scheduled"),
  Literal("hex_user_email"),
  Literal("hex_user_attributes"),
  Literal("hex_run_context"),
  Literal("hex_timezone"),
  Literal("hex_project_id"),
  Literal("hex_project_name"),
  Literal("hex_status"),
  Literal("hex_categories"),
  Literal("hex_color_palette"),
);
export type AutomaticParameterNameLiteral = Static<
  typeof AutomaticParameterNameLiteral
>;

export const AutomaticParameterName = AutomaticParameterNameLiteral.And(
  ParameterName,
)
  .And(PythonIdentifier)
  .And(RIdentifier);
export type AutomaticParameterName = AutomaticParameterNameLiteral &
  ParameterName;

export const HEX_SCHEDULED_PARAMETER: AutomaticParameter<"hex_scheduled"> = {
  name: AutomaticParameterName.check("hex_scheduled"),
  outputType: ParameterOutputType.BOOLEAN,
  description: "Whether or not this run was a scheduled run or not.",
  exampleValue: {
    [ProjectLanguage.PYTHON]: "False",
    [ProjectLanguage.R]: "FALSE",
  },
};

export const HEX_USER_EMAIL_PARAMETER: AutomaticParameter<"hex_user_email"> = {
  name: AutomaticParameterName.check("hex_user_email"),
  outputType: ParameterOutputType.STRING,
  description:
    "The email of the user for this run. For scheduled and logic view runs it will be a dummy email address.",
  exampleValue: {
    [ProjectLanguage.PYTHON]: "example-user@example.com",
    [ProjectLanguage.R]: "example-user@example.com",
  },
};

export const HEX_USER_ATTRIBUTES_PARAMETER: AutomaticParameter<"hex_user_attributes"> =
  {
    name: AutomaticParameterName.check("hex_user_attributes"),
    outputType: ParameterOutputType.DYNAMIC,
    description: "The custom user attributes.",
    exampleValue: {
      [ProjectLanguage.PYTHON]: "{ customerId: 'acme-corp', title: 'Manager' }",
      [ProjectLanguage.R]: "{ customerId: 'acme-corp', title: 'Manager' }",
    },
  };

export const HEX_RUN_CONTEXT_PARAMETER: AutomaticParameter<"hex_run_context"> =
  {
    name: AutomaticParameterName.check("hex_run_context"),
    outputType: ParameterOutputType.STRING,
    description:
      'The context of where the application is being run. Will be one of "logic", "publish-preview", "app", "scheduled", or "api-triggered"',
    exampleValue: {
      [ProjectLanguage.PYTHON]: "logic",
      [ProjectLanguage.R]: "logic",
    },
  };

export const HEX_TIMEZONE_PARAMETER: AutomaticParameter<"hex_timezone"> = {
  name: AutomaticParameterName.check("hex_timezone"),
  outputType: ParameterOutputType.STRING,
  description:
    "An IANA timezone name that can be used for timezone-dependent calculations.",
  exampleValue: {
    [ProjectLanguage.PYTHON]: "Region/Timezone_Name",
    [ProjectLanguage.R]: "Region/Timezone_Name",
  },
};

export const HEX_PROJECT_ID_PARAMETER: AutomaticParameter<"hex_project_id"> = {
  name: AutomaticParameterName.check("hex_project_id"),
  outputType: ParameterOutputType.STRING,
  description:
    "Unique identifier for this Hex project. This is the same as the project ID in the URL.",
  exampleValue: {
    [ProjectLanguage.PYTHON]: "Project ID",
    [ProjectLanguage.R]: "Project ID",
  },
};

export const HEX_PROJECT_NAME_PARAMETER: AutomaticParameter<"hex_project_name"> =
  {
    name: AutomaticParameterName.check("hex_project_name"),
    outputType: ParameterOutputType.STRING,
    description: "Name of the Hex project.",
    exampleValue: {
      [ProjectLanguage.PYTHON]: "Project Name",
      [ProjectLanguage.R]: "Project Name",
    },
  };

export const HEX_STATUS_PARAMETER: AutomaticParameter<"hex_status"> = {
  name: AutomaticParameterName.check("hex_status"),
  outputType: ParameterOutputType.STRING,
  description: "Status of the Hex project.",
  exampleValue: {
    [ProjectLanguage.PYTHON]: "Approved",
    [ProjectLanguage.R]: "Approved",
  },
};

export const HEX_CATEGORIES_PARAMETER: AutomaticParameter<"hex_categories"> = {
  name: AutomaticParameterName.check("hex_categories"),
  outputType: ParameterOutputType.LIST_STRING,
  description: "Categories of the Hex project.",
  exampleValue: {
    [ProjectLanguage.PYTHON]: `["Internal", "Template"]`,
    [ProjectLanguage.R]: `["Internal", "Template"]`,
  },
};

export const HEX_COLOR_PALETTE_PARAMETER: AutomaticParameter<"hex_color_palette"> =
  {
    name: AutomaticParameterName.check("hex_color_palette"),
    outputType: ParameterOutputType.LIST_STRING,
    description:
      "The active color palette for the workspace, represented as a list of hex color codes strings.",
    exampleValue: {
      [ProjectLanguage.PYTHON]: `[${DEFAULT_CHART_COLOR_PALETTE.map(
        (color) => `'${color.hex}'`,
        // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
      ).join(", ")}]`,
      [ProjectLanguage.R]: DEFAULT_CHART_COLOR_PALETTE.map(
        (color) => `"${color.hex}"`,
        // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
      ).join(" "),
    },
  };

export const AUTOMATIC_PARAMETERS = [
  HEX_SCHEDULED_PARAMETER,
  HEX_USER_EMAIL_PARAMETER,
  HEX_USER_ATTRIBUTES_PARAMETER,
  HEX_RUN_CONTEXT_PARAMETER,
  HEX_TIMEZONE_PARAMETER,
  HEX_PROJECT_ID_PARAMETER,
  HEX_PROJECT_NAME_PARAMETER,
  HEX_STATUS_PARAMETER,
  HEX_CATEGORIES_PARAMETER,
  HEX_COLOR_PALETTE_PARAMETER,
];

/**
 * AutomaticEnvVars are like automatic parameters,
 * except they're not intended for direct use by users,
 * and are set as an env var instead of being injected directly as a global variable.
 */
export interface AutomaticEnvVar<T extends string> {
  name: T;
}

/** The number of CPUs allocated to a given kernel, can be a floating point value like 0.5 */
export const HEX_CPU_COUNT_ENV_VAR: AutomaticEnvVar<"HEX_CPU_COUNT"> = {
  name: "HEX_CPU_COUNT",
};

/** The number of GPUs allocated to a given kernel, will be a whole number */
export const HEX_GPU_COUNT_ENV_VAR: AutomaticEnvVar<"HEX_GPU_COUNT"> = {
  name: "HEX_GPU_COUNT",
};

/** The amount of memory for a given kernel, in bytes */
export const HEX_MEMORY_LIMIT_ENV_VAR: AutomaticEnvVar<"HEX_MEMORY_LIMIT"> = {
  name: "HEX_MEMORY_LIMIT",
};

/** Whether to export things as Parquet instead of CSV  */
export const HEX_EXPORT_PARQUET_ENV_VAR: AutomaticEnvVar<"HEX_EXPORT_PARQUET"> =
  {
    name: "HEX_EXPORT_PARQUET",
  };

export const AUTOMATIC_ENV_VARS = [
  HEX_CPU_COUNT_ENV_VAR,
  HEX_GPU_COUNT_ENV_VAR,
  HEX_MEMORY_LIMIT_ENV_VAR,
  HEX_EXPORT_PARQUET_ENV_VAR,
];

export type AutomaticEnvVarName = (typeof AUTOMATIC_ENV_VARS)[number]["name"];
