import {
  Array,
  Boolean,
  Dictionary,
  InstanceOf,
  Literal,
  Null,
  Number,
  Optional,
  Record,
  Static,
  String,
  Union,
} from "runtypes";

import { EnumValues, ProjectLanguage } from "./enums";
import { assertNever } from "./errors";
import {
  DataSourceDatabaseId,
  DataSourceSchemaId,
  DataSourceTableId,
} from "./idTypeBrands";
import { getNormalEnum } from "./runtypeEnums";

export const SqlCompletionTypeLiteral = Union(
  Literal("table"),
  Literal("column"),
  Literal("schema"),
  Literal("database"),
  Literal("keyword"),
  Literal("identifier"),
);
export type SqlCompletionType = Static<typeof SqlCompletionTypeLiteral>;
export const DataConnectionTypeLiteral = Union(
  Literal("athena"),
  Literal("postgres"),
  Literal("prestodb"),
  Literal("snowflake"),
  Literal("redshift"),
  Literal("bigquery"),
  Literal("sqlserver"),
  Literal("mysql"),
  Literal("mariadb"),
  Literal("spark"),
  Literal("transform"),
  Literal("trino"),
  Literal("dremio"),
  Literal("databricks"),
  Literal("clickhouse"),
  Literal("alloydb"),
  Literal("starburst"),
  Literal("materialize"),
  Literal("cloudsql"),
  Literal("cloudsql__mysql"),
  Literal("cloudsql__postgres"),
  Literal("cloudsql__sqlserver"),
  Literal("motherduck"),
  Literal("db2"),
);
export type DataConnectionType = Static<typeof DataConnectionTypeLiteral>;
export const DataConnectionType = getNormalEnum(DataConnectionTypeLiteral);

export const humanReadableDataConnectionType = (
  type: DataConnectionType,
): string => {
  switch (type) {
    case "prestodb":
      return "PrestoDB";
    case "bigquery":
      return "BigQuery";
    case "sqlserver":
      return "SQL Server";
    case "mysql":
      return "MySQL";
    case "mariadb":
      return "MariaDB";
    case "clickhouse":
      return "ClickHouse";
    case "alloydb":
      return "AlloyDB";
    case "cloudsql":
    case "cloudsql__mysql":
    case "cloudsql__postgres":
    case "cloudsql__sqlserver":
      return "Cloud SQL";
    case "motherduck":
      return "Motherduck";
    default:
      return type.charAt(0).toUpperCase() + type.slice(1);
  }
};

export const preferredConnectionTypes: DataConnectionType[] = [
  DataConnectionType.athena,
  DataConnectionType.bigquery,
  DataConnectionType.databricks,
  DataConnectionType.postgres,
  DataConnectionType.redshift,
  DataConnectionType.snowflake,
  DataConnectionType.mysql,
  DataConnectionType.mariadb,
];

export const OAuthConnectionTypeLiteral = Union(
  Literal("bigquery"),
  Literal("databricks"),
  Literal("snowflake"),
);
export type OAuthConnectionType = Static<typeof OAuthConnectionTypeLiteral>;
export const OAuthConnectionType = getNormalEnum(OAuthConnectionTypeLiteral);

export const oAuthConnectionTypes: DataConnectionType[] = [
  DataConnectionType.bigquery,
  DataConnectionType.databricks,
  DataConnectionType.snowflake,
];

export const oAuthSchemaSupportedTypes: DataConnectionType[] = [
  DataConnectionType.snowflake,
];

//  Any db connection that by default has TLS/SSL enabled, without customer configuration / enablement.
//  This does not include data connections, such as sqlserver, that must "enable encryption" for SLS/TLS.
export const hasTLSEnabled = (connectionType: DataConnectionType): boolean => {
  switch (connectionType) {
    case "athena":
    case "bigquery":
    case "databricks":
    case "motherduck":
    case "redshift":
    case "snowflake":
      return true;
    case "clickhouse":
    case "db2":
    case "dremio":
    case "mariadb":
    case "mysql":
    case "postgres":
    case "prestodb":
    case "spark":
    case "sqlserver":
    case "transform":
    case "trino":
    case "alloydb":
    case "starburst":
    case "materialize":
    case "cloudsql":
    case "cloudsql__postgres":
    case "cloudsql__mysql":
    case "cloudsql__sqlserver":
      return false;
    default:
      assertNever(connectionType, connectionType);
  }
};

const DataConnectionStatusLiteral = Union(
  Literal("refreshing"),
  Literal("refresh-enqueued"),
  Literal("errored"),
  Literal("fresh"),
);

export type DataConnectionStatus = Static<typeof DataConnectionStatusLiteral>;
export const DataConnectionStatus = getNormalEnum(DataConnectionStatusLiteral);

export const MONACO_SQL_LANGUAGE_TYPES = [
  "pgsql",
  "mysql",
  "redshift",
  "sql",
  "snowflake",
] as const;
// keep in sync with MonacoWebpackPlugin config in our webpack config
export type MonacoLanguageType =
  | (typeof MONACO_SQL_LANGUAGE_TYPES)[number]
  | "python"
  | "r"
  | "markdown"
  | "yaml"
  | "json"
  | "plaintext";

export const ProjectLanguageMonacoLanguageMap = {
  [ProjectLanguage.R]: "r" as const,
  [ProjectLanguage.PYTHON]: "python" as const,
};

export const DbtTestStatus = Union(
  Literal("pass"),
  Literal("fail"),
  Literal("warn"),
  Literal("error"),
  Literal("skipped"),
);
export type DbtTestStatus = Static<typeof DbtTestStatus>;

export const DbtTestMetadata = Record({
  name: String,
  state: DbtTestStatus,
  // column name is null when the test is across multiple columns
  columnName: String.Or(Null),
});
export type DbtTestMetadata = Static<typeof DbtTestMetadata>;

export const DbtTestMetadataList = Array(DbtTestMetadata);
export type DbtTestMetadataList = Static<typeof DbtTestMetadataList>;

export const DbtColumnMetadata = Record({
  name: String,
  description: String.Or(Null),
});

export const ProcessedDbtColumnMetadata = Record({
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  dbtTests: DbtTestMetadataList.Or(Null),
  dbtDescription: String.Or(Null),
  dbtDescriptionMdStripped: String.Or(Null),
});

export type ProcessedDbtColumnMetadata = Static<
  typeof ProcessedDbtColumnMetadata
>;

export type DbtColumnMetadata = Static<typeof DbtColumnMetadata>;

export const DbtColumnMetadataList = Array(DbtColumnMetadata);
export type DbtColumnMetadataList = Static<typeof DbtColumnMetadataList>;

const DbtJobMetadata = Record({
  id: Number,
});
type DbtJobMetadata = Static<typeof DbtJobMetadata>;

export const DbtJobMetadataList = Array(DbtJobMetadata);
export type DbtJobMetadataList = Static<typeof DbtJobMetadataList>;

export const DbtMetadata = Record({
  dbtAccessUrl: String,
  dbtRunId: String.Or(Null),
  dbtAccountId: String,
  dbtJobId: String.Or(Null),
  dbtProjectId: String,
  dbtEnvironmentId: Optional(String.Or(Null)),
  database: String,
  schema: String,
  table: String,
  dbtUniqueId: String,
  dbtLastRefreshed: Union(Null, InstanceOf(Date)),
  dbtMetadataLastChecked: InstanceOf(Date),
  dbtMetadataError: Boolean,
  dbtDescription: String.Or(Null),
  dbtDescriptionMdStripped: String.Or(Null),
  dbtColumns: DbtColumnMetadataList,
});
export type DbtMetadata = Static<typeof DbtMetadata>;

/* eslint-disable tree-shaking/no-side-effects-in-initialization */
export const DbtModelMetadata = DbtMetadata.extend({
  dbtTests: DbtTestMetadataList,
});
export type DbtModelMetadata = Static<typeof DbtModelMetadata>;

export const DbtSourceMetadata = DbtMetadata.extend({
  dbtFreshness: String.Or(Null),
  dbtWarnAfterCount: Number.Or(Null),
  dbtWarnAfterPeriod: String.Or(Null),
  dbtErrorAfterCount: Number.Or(Null),
  dbtErrorAfterPeriod: String.Or(Null),
});
export type DbtSourceMetadata = Static<typeof DbtSourceMetadata>;

export const MinimalDbtModelMetadata = DbtModelMetadata.pick(
  "dbtLastRefreshed",
  "dbtTests",
  "dbtMetadataLastChecked",
  "dbtRunId",
  "dbtDescription",
  "dbtDescriptionMdStripped",
  "dbtColumns",
);
export type MinimalDbtModelMetadata = Static<typeof MinimalDbtModelMetadata>;

export const MinimalDbtSourceMetadata = DbtSourceMetadata.pick(
  "dbtLastRefreshed",
  "dbtFreshness",
  "dbtMetadataLastChecked",
  "dbtWarnAfterCount",
  "dbtWarnAfterPeriod",
  "dbtErrorAfterCount",
  "dbtErrorAfterPeriod",
  "dbtRunId",
  "dbtDescription",
  "dbtDescriptionMdStripped",
  "dbtColumns",
);
export type MinimalDbtSourceMetadata = Static<typeof MinimalDbtSourceMetadata>;

export const DbtErrorResponse = Record({
  dbtMetadataError: Boolean,
});
export type DbtErrorResponse = Static<typeof DbtErrorResponse>;

export const DbtMetricTimeGrains = Array(String);
export type DbtMetricTimeGrains = Static<typeof DbtMetricTimeGrains>;

export const DbtMetricDimensions = Array(String);
export type DbtMetricDimensions = Static<typeof DbtMetricDimensions>;

export const DbtMetricDimensionV2TypeLiteral = Union(
  Literal("CATEGORICAL"),
  Literal("TIME"),
);
export type DbtMetricDimensionV2Type = Static<
  typeof DbtMetricDimensionV2TypeLiteral
>;
export const DbtMetricDimensionV2Type = getNormalEnum(
  DbtMetricDimensionV2TypeLiteral,
);

export const DbtMetricDimensionV2 = Record({
  name: String,
  description: String.Or(Null),
  label: String.Or(Null),
}).And(
  Union(
    Record({
      type: Literal("CATEGORICAL"),
      queryableGranularities: Null,
    }),
    Record({
      type: Literal("TIME"),
      queryableGranularities: Array(String),
    }),
  ),
);
export type DbtMetricDimensionV2 = Static<typeof DbtMetricDimensionV2>;

export const DbtMetric = Record({
  dbtUniqueId: String,
  name: String,
  displayName: String,
  packageName: String.Or(Null),
  dbtRunId: String,
  description: String.Or(Null),
  type: String.Or(Null),
  timeGrains: DbtMetricTimeGrains,
  dimensions: Array(DbtMetricDimensionV2),
  dbtModelName: String.Or(Null),
  dbtModelUniqueId: String,
  lastExecution: InstanceOf(Date),
  lastFetched: InstanceOf(Date),
});
export type DbtMetric = Static<typeof DbtMetric>;

export const OAuthCombinedSettingLiteral = Union(
  Literal("DISABLE_TOKEN_SHARING"),
  Literal("APP_ONLY"),
  Literal("LOGIC_VIEW_ONLY"),
  Literal("APP_AND_LOGIC_VIEW"),
);
export type OAuthCombinedSetting = Static<typeof OAuthCombinedSettingLiteral>;
export const OAuthCombinedSetting = getNormalEnum(OAuthCombinedSettingLiteral);

export const OAuthLogicViewSettingLiteral = Union(
  Literal("DISABLE_TOKEN_SHARING"),
  Literal("ENABLE_TOKEN_SHARING"),
);
export type OAuthLogicViewSetting = Static<typeof OAuthLogicViewSettingLiteral>;
export const OAuthLogicViewSetting = getNormalEnum(
  OAuthLogicViewSettingLiteral,
);

export const OAuthPublishedAppSettingLiteral = Union(
  Literal("DISABLE_TOKEN_SHARING"),
  Literal("ENABLE_TOKEN_SHARING"),
);
export type OAuthPublishedAppSetting = Static<
  typeof OAuthPublishedAppSettingLiteral
>;
export const OAuthPublishedAppSetting = getNormalEnum(
  OAuthPublishedAppSettingLiteral,
);

export const DataConnectionAuthType = {
  USERNAME_AND_PASSWORD: "usernameAndPassword",
  OAUTH: "oauth",
  KEY_PAIR: "keyPair",
  CERTIFICATE: "certificate",
  SERVICE_ACCOUNT: "serviceAccount",
  ACCESS_TOKEN: "accessToken",
};
export type DataConnectionAuthType = EnumValues<typeof DataConnectionAuthType>;

export const OAuthCredentialSettingLiteral = Union(
  Literal("WORKSPACE"),
  Literal("USER_WITH_WORKSPACE_FALLBACK"),
  Literal("USER"),
  Literal("SERVICE_ACCOUNT"),
);
export type OAuthCredentialSetting = Static<
  typeof OAuthCredentialSettingLiteral
>;
export const OAuthCredentialSetting = getNormalEnum(
  OAuthCredentialSettingLiteral,
);

/**
 * Used to determine status of a data source after a refresh and let the user
 * know if it potentially may not exist anymore. This does not determine the * status of a refresh.
 */
export const DataSourceStatusLiteral = Union(
  Literal("ORPHANED"),
  Literal("DEPRECATED"),
);
export type DataSourceStatus = Static<typeof DataSourceStatusLiteral>;
export const DataSourceStatus = getNormalEnum(DataSourceStatusLiteral);

export const DbtSemanticLayerVersionLiteral = Union(
  Literal("DBT_SEMANTIC_LAYER_V1"),
  Literal("DBT_SEMANTIC_LAYER_V2"),
);
export type DbtSemanticLayerVersion = Static<
  typeof DbtSemanticLayerVersionLiteral
>;
export const DbtSemanticLayerVersion = getNormalEnum(
  DbtSemanticLayerVersionLiteral,
);

export const DataConnectionRateLimitTypeLiteral = Union(
  DataConnectionTypeLiteral,
  Literal("default"),
  Literal("snowflake+dbt"),
);
export const DataConnectionRateLimits = Dictionary(
  Optional(Number),
  DataConnectionRateLimitTypeLiteral,
);
export type DataConnectionRateLimits = Static<typeof DataConnectionRateLimits>;

export const FilterMatch = Union(
  Literal("EXACT"),
  Literal("PREFIX"),
  Literal("REGEX"),
  Literal("SUFFIX"),
);
export type FilterMatch = Static<typeof FilterMatch>;

export const FilterMode = Union(Literal("EXCLUDE"), Literal("INCLUDE"));
export type FilterMode = Static<typeof FilterMode>;

export const StringOrRegexFilter = Record({
  match: FilterMatch,
  mode: FilterMode,
  options: Array(String),
});
export type StringOrRegexFilter = Static<typeof StringOrRegexFilter>;

/*
 * Replacing the original string[] filters with StringOrRegexFilter which encodes more information like
 * filter mode (include or exclude) and filter match (exact, prefix, suffix, regex). This information is needed
 * in order to support these mode and match options in the UI since it is not possible to differentiate between
 * a "starts with x" filter and a plain regex filter that happens to match.
 *
 * The string[] filters are now considered deprecated, but are retained to support a few existing customers
 * which had schema filtering configured prior to this change.
 */
export const SchemaFilter = Record({
  catalogFilters: Optional(Array(String)), // deprecated
  schemaFilters: Optional(Array(String)), // deprecated
  tableFilters: Optional(Array(String)), // deprecated
  columnFilters: Optional(Array(String)), // deprecated
  databaseFilter: Optional(StringOrRegexFilter),
  schemaFilter: Optional(StringOrRegexFilter),
  tableFilter: Optional(StringOrRegexFilter),
});
export type SchemaFilter = Static<typeof SchemaFilter>;

export const DataSourceGrantEntityTypeLiteral = Union(
  Literal("DATABASE"),
  Literal("SCHEMA"),
  Literal("TABLE"),
  Literal("COLUMN"),
);

export type DataSourceGrantEntityType = Static<
  typeof DataSourceGrantEntityTypeLiteral
>;
export const DataSourceGrantEntityType = getNormalEnum(
  DataSourceGrantEntityTypeLiteral,
);

export type GrantPrivileges = {
  literalPrivileges?: string[];
  privilegeGrantingPrincipals?: string[];
};
export type DataSourceGrantEntityId =
  | DataSourceTableId
  | DataSourceSchemaId
  | DataSourceDatabaseId;
