import { Boolean, Null, Number, String } from "runtypes";

import {
  DateAndTime,
  DateOnly,
  DatetimeValue,
  RelativeDatetimeOffsetValue,
} from "../datetimeType.js";
import {
  BinaryColumnPredicateOpLiteral,
  ColumnPredicate,
  CompoundColumnPredicateOp,
  ListBinaryColumnPredicateOpLiteral,
  UnaryColumnPredicate,
  UnaryColumnPredicateOp,
  UnaryColumnPredicateOpLiteral,
} from "../display-table/columnPredicateTypes.js";
import { ColumnFilter } from "../display-table/filterTypes.js";
import { FilledDynamicValueTableColumnType } from "../DynamicValue.js";
import { assertNever } from "../errors.js";
import { DisplayTableColumnId } from "../idTypeBrands.js";
import { notEmpty } from "../notEmpty.js";
import {
  AllowedDynamicListValueTypes,
  DatetimeValueTuple,
  DynamicList,
  FileUpload,
  NumberArray,
  ParameterValueContents,
  StringArray,
  UserAttributes,
} from "../parameterValueType.js";
import { SharedFilterPredicateOp } from "../sharedFilterTypes.js";
import { TableInputData } from "../tableInputTypes.js";

export function buildParameterFilter({
  column,
  columnType: actualColumnType,
  operator,
  value,
}: {
  column: DisplayTableColumnId;
  columnType?: FilledDynamicValueTableColumnType;
  operator: SharedFilterPredicateOp;
  value: ParameterValueContents;
}): ColumnFilter | null {
  let predicate: ColumnPredicate | null = null;
  let extraNullPredicate: UnaryColumnPredicate | null = null;

  if (AllowedDynamicListValueTypes.guard(value)) {
    predicate = predicateForSimpleValueType(operator, value);
  } else if (
    StringArray.guard(value) ||
    NumberArray.guard(value) ||
    DynamicList.guard(value)
  ) {
    if (BinaryColumnPredicateOpLiteral.guard(operator)) {
      const args = value
        .map((v) => predicateForSimpleValueType(operator, v))
        .filter(notEmpty);

      if (args.length > 0) {
        predicate = { op: CompoundColumnPredicateOp.AND, args };
      }
    } else if (ListBinaryColumnPredicateOpLiteral.guard(operator)) {
      const stringifiedValues = value
        .map((v) => v?.toString())
        .filter(notEmpty);
      if (stringifiedValues.length > 0) {
        predicate = { op: operator, arg: stringifiedValues };
      } else {
        return null;
      }
    } else if (UnaryColumnPredicateOpLiteral.guard(operator)) {
      return null;
    } else {
      assertNever(operator, operator);
    }
  } else if (DatetimeValueTuple.guard(value)) {
    const [arg1, arg2] = [
      predicateValueForDatetime(value[0]),
      predicateValueForDatetime(value[1]),
    ];
    if (BinaryColumnPredicateOpLiteral.guard(operator)) {
      if (arg1 != null) {
        predicate = { op: operator, arg: arg1 };
      }
    } else if (ListBinaryColumnPredicateOpLiteral.guard(operator)) {
      if (operator === "DATE_BETWEEN") {
        if (arg1 != null && arg2 != null) {
          predicate = {
            op: operator,
            arg: [arg1, arg2],
          };
        }
      } else if (arg1 != null || arg2 != null) {
        predicate = {
          op: operator,
          arg: [arg1, arg2].filter(notEmpty),
        };
      }
    } else if (UnaryColumnPredicateOpLiteral.guard(operator)) {
      return null;
    } else {
      assertNever(operator, operator);
    }
  } else if (DatetimeValue.guard(value)) {
    const predicateArg = predicateValueForDatetime(value);
    if (BinaryColumnPredicateOpLiteral.guard(operator)) {
      if (predicateArg != null) {
        predicate = {
          op: operator,
          arg: predicateArg,
        };
      }
    } else if (ListBinaryColumnPredicateOpLiteral.guard(operator)) {
      if (predicateArg != null) {
        if (operator === "DATE_BETWEEN") {
          predicate = {
            op: operator,
            arg: [predicateArg, predicateArg],
          };
        } else {
          predicate = {
            op: operator,
            arg: [predicateArg],
          };
        }
      }
    } else if (UnaryColumnPredicateOpLiteral.guard(operator)) {
      return null;
    } else {
      assertNever(operator, operator);
    }
  } else if (
    TableInputData.guard(value) ||
    DatetimeValueTuple.guard(value) ||
    FileUpload.guard(value) ||
    UserAttributes.guard(value)
  ) {
    // not supported
    // TODO: add date range support
  } else {
    assertNever(value, value);
  }

  if (predicate == null) {
    return null;
  }

  // charts return all categorical values -- including null -- as string values,
  // so filtering by null needs to check for whether the value is "null",
  // which is already handled by the above `predicate`, and actual null value
  if (
    // only check for null if the predicate is not a null check
    predicate.op !== UnaryColumnPredicateOp.IS_NULL &&
    predicate.op !== UnaryColumnPredicateOp.NOT_NULL &&
    ((String.guard(value) && value.toLowerCase() === "null") ||
      (StringArray.guard(value) &&
        value.some((v) => v.toLowerCase() === "null")))
  ) {
    if (operator === "EQ" || operator === "IS_ONE_OF") {
      extraNullPredicate = { op: UnaryColumnPredicateOp.IS_NULL };
    } else if (operator === "NEQ" || operator === "NOT_ONE_OF") {
      extraNullPredicate = { op: UnaryColumnPredicateOp.NOT_NULL };
    }
  }

  let columnType: FilledDynamicValueTableColumnType;
  if (actualColumnType != null) {
    columnType = actualColumnType;
  } else {
    if (
      operator.includes("DATE") ||
      DatetimeValueTuple.guard(value) ||
      DateAndTime.guard(value) ||
      DateOnly.guard(value)
    ) {
      // TODO (EXP-70): properly evaluate column type information when datetime.
      // We always perform a date (not datetime) filter because we don't support datetime
      // filtering. Because of this we should always set the columnType = DATETIME so that
      // the generated filter SQL will include date truncation; it's a bit wasteful to
      // always truncate but in an environment where we cant know for sure whether the
      // column type is date or datetime, we should force a truncation to ensure filters work.
      columnType = "DATETIME";
    } else if (
      String.guard(value) ||
      StringArray.guard(value) ||
      DynamicList.guard(value)
    ) {
      columnType = "STRING";
    } else if (Number.guard(value) || NumberArray.guard(value)) {
      columnType = "NUMBER";
    } else if (Boolean.guard(value)) {
      columnType = "BOOLEAN";
    } else {
      columnType = "UNKNOWN";
    }
  }

  if (extraNullPredicate != null) {
    predicate = {
      op: CompoundColumnPredicateOp.OR,
      args: [predicate, extraNullPredicate],
    };
  }

  return {
    column,
    predicate,
    columnType,
  };
}

function predicateForSimpleValueType(
  operator: SharedFilterPredicateOp,
  value: AllowedDynamicListValueTypes,
): ColumnPredicate | null {
  if (String.guard(value) || Number.guard(value)) {
    const stringArg = value.toString();
    if (stringArg.length > 0) {
      if (BinaryColumnPredicateOpLiteral.guard(operator)) {
        return {
          op: operator,
          arg: stringArg,
        };
      } else if (ListBinaryColumnPredicateOpLiteral.guard(operator)) {
        return {
          op: operator,
          arg: [stringArg],
        };
      } else if (UnaryColumnPredicateOpLiteral.guard(operator)) {
        return null;
      } else {
        assertNever(operator, operator);
      }
    }
  } else if (Boolean.guard(value)) {
    if (UnaryColumnPredicateOpLiteral.guard(operator)) {
      if (!value) {
        // if value is false, it means that the user has unchecked the checkbox
        // and disabled the filter
        return null;
      } else if (operator === "ALWAYS") {
        // wtf is an ALWAYS predicate and why do we have it?
        return null;
      } else if (operator === "IS_TRUE") {
        return { op: UnaryColumnPredicateOp.IS_TRUE };
      } else if (operator === "IS_FALSE") {
        return { op: UnaryColumnPredicateOp.IS_FALSE };
      } else if (operator === "IS_NULL") {
        return { op: UnaryColumnPredicateOp.IS_NULL };
      } else if (operator === "NOT_NULL") {
        return { op: UnaryColumnPredicateOp.NOT_NULL };
      }
    } else if (
      BinaryColumnPredicateOpLiteral.guard(operator) ||
      ListBinaryColumnPredicateOpLiteral.guard(operator)
    ) {
      return {
        op: value
          ? UnaryColumnPredicateOp.IS_TRUE
          : UnaryColumnPredicateOp.IS_FALSE,
      };
    } else {
      assertNever(operator, operator);
    }
  } else if (Null.guard(value)) {
    return null; // noop for null
  } else {
    assertNever(value, value);
  }
  return null;
}

function predicateValueForDatetime(value: DatetimeValue): string | null {
  if (RelativeDatetimeOffsetValue.guard(value)) {
    return JSON.stringify(value);
  } else if (DateOnly.guard(value)) {
    if (value.dateString.length > 0) {
      return value.dateString;
    }
  } else if (DateAndTime.guard(value)) {
    if (value.isoDateString.length > 0) {
      return value.isoDateString;
    }
  } else {
    assertNever(value, value);
  }
  return null;
}
