import { ClientAnalyticsEventType } from "@hex/common";
import MouseTrap from "mousetrap";
import { useEffect } from "react";

import { logErrorMsg } from "../util/logging.js";
import { trackEvent } from "../util/trackEvent.js";
import { useGetHexFlag } from "../util/useHexFlags.js";

/**
 * These are strictly one-to-one with `MouseTrap.bind` arguments
 */
export interface UseGlobalHotKeyOptions {
  keys: string | string[];
  action?: string;
  // don't register the hot key
  skip?: boolean;
  name: string;
}

/**
 * Registers a hotkey handler globally for a specific sequence/combo of keys.
 *
 * Handles memoization of the passed callback, so it should be a inline lambda.
 * The strange argument ordering here is so that we can use the react hooks eslint plugin.
 */
export function useGlobalHotKey(
  /**
   * The callback should return false if the event was skipped, and true otherwise.
   */
  callback: (e: KeyboardEvent, combo: string) => boolean | Promise<boolean>,
  deps: unknown[],
  { action, keys, name, skip }: UseGlobalHotKeyOptions,
): void {
  const getFlag = useGetHexFlag();
  useEffect(() => {
    if (skip) {
      return;
    }

    MouseTrap.bind(
      keys,
      async (e, combo) => {
        let response;
        try {
          response = callback(e, combo);
        } catch (err) {
          logErrorMsg(err, "Error in hotkey callback");
          return;
        }
        if (!getFlag("keyboard-shortcut-tracking")) {
          return;
        }

        if (typeof response === "boolean") {
          if (response) {
            trackHotKeyUsage(e, name);
          }
        } else {
          await response
            .then((shouldTrack) => {
              if (shouldTrack) {
                trackHotKeyUsage(e, name);
              }
            })
            .catch((err) => {
              logErrorMsg(err, "Error in hotkey callback");
            });
        }
      },
      action,
    );
    return () => {
      MouseTrap.unbind(keys, action);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, keys, skip, ...deps]);
}

export function trackHotKeyUsage(e: KeyboardEvent, action: string): void {
  trackEvent(ClientAnalyticsEventType.KEYBOARD_SHORTCUT_USED, {
    key: keyEventString(e),
    action,
  });
}
// turns a keyboard event into a readable string of its shortcut, like "mod+shift+k"
function keyEventString(e: KeyboardEvent): string {
  return `${e.metaKey || e.ctrlKey ? "mod+" : ""}${e.shiftKey ? "shift+" : ""}${
    e.altKey ? "alt+" : ""
  }${e.key}`;
}
