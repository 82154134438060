import objectHash from "object-hash";

import { ColumnFilter } from "../display-table/filterTypes.js";
import { COUNT_STAR_ARG } from "../explore/exploreFieldUtils.js";
import { CellId } from "../idTypeBrands";
import { ChartArgs } from "../shared-types/kernelInterfaceTypes";
import { pyReference } from "../shared-types/pyObjects";

import {
  getChartReferencedDataFrameNames,
  getChartSeriesValuesDataFrameColumns,
} from "./chartDataFrameUtils";
import { ChartColorMappings, ChartSelection, ChartSpec } from "./types";

export function sanitizeChartSpec(chartSpec: ChartSpec): ChartSpec {
  // magic has in the past populated these fields in histograms
  // we should support them but we don't yet
  // we need to rub them out way up here so we don't do HQL etc on them
  if (
    chartSpec.type !== "layered" ||
    chartSpec.layers.length === 0 ||
    chartSpec.layers[0]?.series.length === 0 ||
    chartSpec.layers[0]?.series[0]?.type !== "histogram"
  ) {
    return chartSpec;
  }
  const layer = chartSpec.layers[0];
  const series = chartSpec.layers[0].series[0];
  return {
    ...chartSpec,
    layers: [
      {
        ...layer,
        series: [
          {
            ...series,
            colorDataFrameColumn: undefined,
            dataFrameColumns:
              series.dataFrameColumns.length === 0
                ? [COUNT_STAR_ARG] // equivalent to former default
                : series.dataFrameColumns,
            axis: {
              ...series.axis,
              aggregate:
                series.dataFrameColumns.length === 0
                  ? "count" // equivalent to former default
                  : series.axis.aggregate,
            },
            color:
              series.color.type !== "static"
                ? { type: "static" }
                : series.color,
          },
        ],
      },
    ],
  };
}

export function getChartPythonKernelCodeArgs({
  calcColumns,
  cellId,
  chartSelection,
  chartSpec,
  colorMappings,
  colorPalette,
  filters,
  hardRowLimit,
  hexSidecarLocalServerPort,
  hqlBundle,
  maybeSkipSql,
  miniHexChart,
  patchCharts,
  seriesDataframeNames,
  skipFilledDynamicValueOutput,
  snowparkConnectionId,
  useSql,
}: {
  snowparkConnectionId: string | null;
  chartSpec: ChartSpec;
  chartSelection: ChartSelection;
  cellId: CellId;
  maybeSkipSql: boolean;
  patchCharts: boolean;
  miniHexChart: boolean;
  colorPalette: readonly string[];
  colorMappings: ChartColorMappings;
  filters: readonly ColumnFilter[];
  useSql: boolean;
  hardRowLimit: boolean;
  hexSidecarLocalServerPort: string;
  seriesDataframeNames: readonly string[] | null;
  hqlBundle: ChartArgs["hqlBundle"];
  calcColumns: ChartArgs["calcColumns"];
  skipFilledDynamicValueOutput: boolean;
}): ChartArgs {
  if (chartSpec.type === "unsupported") {
    throw new Error("chart unsupported");
  }

  const dataframeNames =
    hqlBundle?.isDataframeInput === false
      ? seriesDataframeNames ?? []
      : getChartReferencedDataFrameNames(chartSpec).concat(
          seriesDataframeNames ?? [],
        );
  const filtersHash = filters.length > 0 ? objectHash(filters) : null;
  return {
    snowparkConnectionId,
    dataframeNames,
    dataframeValues: dataframeNames.map((df) => pyReference(df)),
    // Only run things through `HexChart` and VegaFusion if our spec has something meaningful specified
    // This prevents us from getting back VegaFusion errors and works well with the UI since we don't
    // even try to render a chart in the client unless fields are properly populated
    chartSpec: dataframeNames.length > 0 ? sanitizeChartSpec(chartSpec) : null,
    cacheKey:
      filtersHash != null ? `${cellId}-${filtersHash.slice(0, 8)}` : cellId,
    maybeSkipSql,
    patchCharts,
    miniHexChart,
    seriesValuesDataFrameColumns:
      getChartSeriesValuesDataFrameColumns(chartSpec),
    defaultInputTz: chartSpec.settings.timezone ?? null,
    selectionDataset: chartSpec.settings.selectionEnabled ? "dataset" : null,
    chartSelection,
    colorPalette,
    colorMappings,
    useSql,
    hardRowLimit,
    hexSidecarLocalServerPort,
    hqlBundle,
    calcColumns,
    skipFilledDynamicValueOutput,
  };
}
