import React, { ReactNode } from "react";
import styled, { css } from "styled-components";

import { HexTooltip } from "../../hex-components";
import { GroupsIcon } from "../icons/CustomIcons";

const Wrapper = styled.div<{ $large: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  flex: none;

  ${({ $large, theme }) =>
    $large &&
    css`
      width: 28px;
      height: 28px;
      box-shadow: inset 0 0 0 1px ${theme.borderColor.DEFAULT};
      border-radius: 50%;
    `}
`;

const TooltipContentDiv = styled.div`
  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
`;

const TooltipNameDiv = styled.div`
  color: ${({ theme }) => theme.fontColor.DEFAULT};
`;

const TooltipMetaDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
`;

export interface GroupAvatarProps {
  name: string;
  userCount?: number;
  large?: boolean;
  icon?: ReactNode;
}

export const GroupAvatar: React.ComponentType<GroupAvatarProps> = React.memo(
  function GroupAvatar({
    icon,
    large = false,
    name,
    userCount,
  }: GroupAvatarProps) {
    const tooltipContent = (
      <TooltipContentDiv>
        <TooltipNameDiv>{name}</TooltipNameDiv>
        <TooltipMetaDiv>
          {userCount != null &&
            `${userCount} user${userCount !== 1 ? "s" : ""}`}
        </TooltipMetaDiv>
      </TooltipContentDiv>
    );

    return (
      <HexTooltip content={tooltipContent}>
        <Wrapper $large={large}>{icon || <GroupsIcon />}</Wrapper>
      </HexTooltip>
    );
  },
);
