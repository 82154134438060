import { diff } from "deep-object-diff";
import { uniq } from "lodash";

import { toSqlAggregation, toSqlTruncUnit } from "../chart/hql/chartHql.js";
import {
  ChartAreaSeries,
  ChartAxis,
  ChartAxisStyle,
  ChartAxisWithData,
  ChartBarSeries,
  ChartDataType,
  ChartDatetimeAxis,
  ChartFacet,
  ChartFacetDimension,
  ChartHistogramSeries,
  ChartLineSeries,
  ChartPieSeries,
  ChartScatterSeries,
  ChartSeries,
  ChartSortOrCustomSort,
  ChartSpec,
} from "../chart/types.js";
import {
  CalciteType,
  HqlAggregationFunction,
  calciteTypeToColumnType,
  columnTypeToCalciteType,
} from "../hql/types.js";
import { SemanticDatasetName } from "../idTypeBrands.js";

import {
  getColumnFromColumnNameWithQueryPath,
  getColumnNameWithQueryPath,
} from "./exploreChartSemanticUtils.js";
import { defaultExploreField } from "./exploreDefaults.js";
import {
  BASE_COUNT_STAR_FIELD,
  COUNT_STAR_ARG,
  getCountStarField,
  getFieldActiveScaleType,
} from "./exploreFieldUtils.js";
import {
  SemanticAwareColumn,
  SemanticAwareFieldGroup,
} from "./semanticTypes.js";
import {
  ChartExploreChannelTopLevel,
  ExploreAxis,
  ExploreChannel,
  ExploreChartSeries,
  ExploreColorSource,
  ExploreField,
  ExploreFieldType,
  ExploreOpacitySource,
  ExploreSeriesId,
  ExploreSort,
  ExploreSpec,
} from "./types.js";

//eslint-disable-next-line @typescript-eslint/no-explicit-any -- generic
function flatten(obj: Record<string, any>, prefix = ""): object {
  const keys = Object.keys(obj);
  if (keys.length === 0) {
    return { [prefix]: obj };
  }
  //eslint-disable-next-line @typescript-eslint/no-explicit-any -- generic
  return keys.reduce((acc: Record<string, any>, k) => {
    const pre = prefix.length ? prefix + "." : "";
    if (typeof obj[k] === "object" && obj[k] != null) {
      Object.assign(acc, flatten(obj[k], pre + k));
    } else {
      acc[pre + k] = obj[k];
    }
    return acc;
  }, {});
}

function peelOff(
  input: string[],
  match: string,
  callback: (path: string[]) => void,
): string[] {
  callback(
    input
      // using x.split instead of x.startsWith since we want an exact match
      // to avoid matching prefixes like "series" with "seriesGroup"
      .filter((x) => x.split(".")[0] === match)
      .map((x) => x.slice(match.length)),
  );
  return input.filter((x) => x.split(".")[0] !== match);
}

interface Pattern {
  [key: string]: Pattern | ((paths: string[]) => void) | (() => void);
}

function processPattern(input: string[], pat: Pattern): void {
  Object.keys(pat).forEach((key) => {
    input = peelOff(input, key, (paths) => {
      paths = paths.map((p) => p.replace(/^\./, ""));
      if (typeof pat[key] === "function") {
        if (paths.length > 0) {
          pat[key](paths);
        }
      } else if (pat[key] != null) {
        processPattern(paths, pat[key]);
      }
    });
  });
}

export function updateExploreSpecFromChartDiffs({
  curSpec,
  exploreSpec,
  fieldGroups,
  newSpec,
}: {
  curSpec: ChartSpec;
  newSpec: ChartSpec;
  exploreSpec: ExploreSpec;
  fieldGroups: SemanticAwareFieldGroup[] | undefined;
}): ExploreSpec {
  if (
    curSpec.type !== "layered" ||
    newSpec.type !== "layered" ||
    newSpec.layers.length === 0 ||
    newSpec.layers[0] == null
  ) {
    return exploreSpec;
  }
  const clone = structuredClone(exploreSpec);

  // minimize diff by reordering previous spec series to match new spec order
  // e.g. if curSpec has series [A,B] and you delete A, newSpec will have just B
  // we reorder curSpec to have [B,A] so that the diff is just a delete of A
  // this helps for drag-reordering series in the chart as well
  const newChartSeriesIds = newSpec.layers[0]!.series.map((s) => s.id);

  const newSpecSorter = (a: string, b: string): number => {
    const aPos = newChartSeriesIds.indexOf(a);
    if (aPos === -1) {
      return 1; // missing series goes to the end for cleanup
    }
    const bPos = newChartSeriesIds.indexOf(b);
    if (bPos === -1) {
      return -1; // missing series goes to the end for cleanup
    }
    return aPos - bPos;
  };
  curSpec.layers[0]!.series.sort((a, b) => newSpecSorter(a.id, b.id));

  // reorder the explore series to match the new spec order as well
  // just so the re-generated ChartSpec has the same series order
  clone.chartConfig.series.sort((a, b) => newSpecSorter(a.id, b.id));

  const flatPatch = flatten(diff(curSpec, newSpec));

  const numFieldsIn = exploreSpec.fields.length;
  const xField = findFieldForChannel(clone, "base-axis");

  const chartSeriesIndices = uniq(
    Object.keys(flatPatch)
      // coalesce to undefined as Number(null) is 0 but Number(undefined) is NaN,
      // and we use NaN to indicate that the path is not a series path
      .map((p) => Number(p.match(/layers\.0\.series\.(\d+)/)?.[1] ?? undefined))
      .filter((p) => !isNaN(p)),
  );

  chartSeriesIndices.forEach((chartSeriesIdx) => {
    const chartSeries = newSpec.layers[0]!.series[chartSeriesIdx];
    const prevChartSeries = curSpec.layers[0]!.series[chartSeriesIdx];
    const yAxis = chartSeries?.axis;

    // create chart series if it doesn't exist or delete if it no longer exists
    if (!isNaN(chartSeriesIdx)) {
      if (
        prevChartSeries == null &&
        chartSeries != null &&
        clone.chartConfig.series[chartSeriesIdx] == null
      ) {
        // new series, add it
        clone.chartConfig.series.push(chartSeriesToExploreSeries(chartSeries));
      } else if (
        chartSeries == null &&
        prevChartSeries != null &&
        clone.chartConfig.series[chartSeriesIdx] != null
      ) {
        // deleted series, remove it
        clone.chartConfig.series = clone.chartConfig.series.filter(
          (s) => s.id !== prevChartSeries.id,
        );
        return;
      }
    }

    const colorField = findFieldForChannel(clone, "color", chartSeries?.id);
    const yField = findFieldForChannel(clone, "cross-axis", chartSeries?.id);

    const seriesPaths = Object.keys(flatPatch)
      .filter((p) => p.startsWith(`layers.0.series.${chartSeriesIdx}`))
      .map((p) => p.replaceAll(/\.\d+/g, ""));

    processPattern(seriesPaths, {
      layers: {
        series: {
          axis: createAxisProcessor(yField, yAxis, fieldGroups),
          ...createSeriesProcessor({
            specClone: clone,
            xField,
            yField,
            colorField,
            chartSeries,
            fieldGroups,
          }),
        },
      },
    });
  });

  clone.fields
    .filter((f) => ChartExploreChannelTopLevel.guard(f.channel))
    .forEach((f) => {
      // top-level fields should always belong to the first series
      f.seriesId = clone.chartConfig.series[0]!.id;
    });

  // leave no orphaned fields
  clone.fields = clone.fields.filter((f) =>
    clone.chartConfig.series.some((s) => s.id === f.seriesId),
  );

  const xAxis = newSpec.layers[0].xAxis;
  const hFacetField = findFieldForChannel(clone, "h-facet");
  const vFacetField = findFieldForChannel(clone, "v-facet");

  const nonSeriesPaths = Object.keys(flatPatch)
    .filter((p) => !p.startsWith(`layers.0.series.`))
    .map((p) => p.replaceAll(/\.\d+/g, ""));
  processPattern(nonSeriesPaths, {
    layers: {
      xAxis: createAxisWithDataProcessor({
        field: xField,
        specClone: clone,
        axis: xAxis,
        fieldGroups,
      }),
      seriesGroups: () => {
        const seriesGroups = newSpec.layers[0]?.seriesGroups;
        clone.chartConfig.seriesGroups = seriesGroups as ExploreSeriesId[][];
      },
    },
    facet: createFacetProcessor({
      specClone: clone,
      facet: newSpec.facet,
      hFacetField,
      vFacetField,
      fieldGroups,
    }),
    settings: () => {
      clone.chartConfig.settings = newSpec.settings;
    },
  });

  // explore specs should always have series groups defined so if it doesn't exist fix things
  if (
    clone.chartConfig.series.length > 0 &&
    (clone.chartConfig.seriesGroups == null ||
      clone.chartConfig.seriesGroups.length === 0)
  ) {
    clone.chartConfig.seriesGroups = [
      clone.chartConfig.series.map((s) => s.id),
    ];
  }

  if (numFieldsIn !== clone.fields.length) {
    // if the number of fields has changed, we need to rerun to make sure
    // nothing was skipped because of a missing field
    // in principle this converges after one step in!
    return updateExploreSpecFromChartDiffs({
      curSpec,
      newSpec,
      exploreSpec: clone,
      fieldGroups,
    });
  }

  // patch up any count(*) fields - we should set most of these already when we
  // construct the field, but adding this check here just to make sure that any
  // COUNT_STAR fields are properly updated.
  clone.fields = clone.fields.map((f) =>
    f.value === COUNT_STAR_ARG
      ? {
          ...f,
          scaleType: "number",
          title: BASE_COUNT_STAR_FIELD.columnName,
          dataType: columnTypeToCalciteType(BASE_COUNT_STAR_FIELD.columnType),
          aggregation: undefined,
          fieldType: ExploreFieldType.MEASURE,
        }
      : f,
  );

  // patch up pie charts to not have base-axis since it's duplicative of color
  if (
    clone.chartConfig.series.length === 1 &&
    clone.chartConfig.series[0]?.type === "pie"
  ) {
    clone.fields = clone.fields.filter((f) => f.channel !== "base-axis");
  }

  return clone;
}

function getColorSource(chartSeries: ChartSeries): ExploreColorSource {
  return chartSeries.color.type !== "series" ||
    chartSeries.colorDataFrameColumn != null
    ? "color"
    : "base-axis";
}

function getOpacitySource(chartSeries: ChartSeries): ExploreOpacitySource {
  return chartSeries.color.type === "series"
    ? "opacity"
    : chartSeries.colorDataFrameColumn != null
      ? "color"
      : "base-axis";
}

// exported for testing.
export function chartSeriesToExploreSeries(
  chartSeries: ChartSeries,
): ExploreChartSeries {
  return {
    id: ExploreSeriesId.check(chartSeries.id),
    type: chartSeries.type,
    tooltip: {
      includeAuto:
        chartSeries.tooltip != null &&
        (chartSeries.tooltip.type === "auto" ||
          chartSeries.tooltip.includeAuto === true),
    },
    color: {
      source: getColorSource(chartSeries),
      staticValue:
        chartSeries.color.type === "static"
          ? chartSeries.color.color
          : undefined,
    },
    opacity: {
      source: getOpacitySource(chartSeries),
      staticValue:
        chartSeries.opacity?.type === "static"
          ? chartSeries.opacity.value
          : undefined,
      staticMode: chartSeries.opacity?.type === "static" ? true : undefined,
    },
    normalize:
      (ChartBarSeries.guard(chartSeries) &&
        chartSeries.layout === "stacked100") ||
      (ChartAreaSeries.guard(chartSeries) && chartSeries.normalize)
        ? "base-axis"
        : (ChartPieSeries.guard(chartSeries) && chartSeries.showAsPct) ||
            (ChartHistogramSeries.guard(chartSeries) &&
              chartSeries.format === "percentage")
          ? "facet"
          : undefined,
    barGrouped: ChartBarSeries.guard(chartSeries)
      ? chartSeries.layout === "grouped"
      : undefined,
    lineWidth: ChartLineSeries.guard(chartSeries)
      ? { staticValue: chartSeries.width }
      : undefined,
    lineStroke: ChartLineSeries.guard(chartSeries)
      ? { staticValue: chartSeries.stroke }
      : undefined,
    lineShape:
      ChartLineSeries.guard(chartSeries) || ChartAreaSeries.guard(chartSeries)
        ? chartSeries.interpolate
        : undefined,
    linePoint:
      ChartLineSeries.guard(chartSeries) || ChartAreaSeries.guard(chartSeries)
        ? chartSeries.point
        : undefined,
    areaLine: ChartAreaSeries.guard(chartSeries) ? chartSeries.line : undefined,
    radius: ChartPieSeries.guard(chartSeries)
      ? { staticValue: chartSeries.radius }
      : undefined,
    pointFilled: ChartScatterSeries.guard(chartSeries)
      ? chartSeries.filled
      : undefined,
    pointShape: ChartScatterSeries.guard(chartSeries)
      ? { staticValue: chartSeries.shape }
      : undefined,
    pointSize: ChartScatterSeries.guard(chartSeries)
      ? { staticValue: chartSeries.size }
      : undefined,
  };
}

// need to use mapped fields to find the field id to use that to get the field in the cloned spec
// since non-mapped fields may not have the proper channel set
// eslint-disable-next-line max-params -- lazy
function findFieldForChannel(
  specClone: ExploreSpec,
  channel: string,
  seriesId?: ExploreSeriesId | string,
): ExploreField | undefined {
  return specClone.fields.find(
    (f) =>
      f.channel === channel && (seriesId == null || f.seriesId === seriesId),
  );
}

function exploreSortFromSort(
  chartSort: ChartSortOrCustomSort | undefined,
): ExploreSort | undefined {
  switch (chartSort) {
    case undefined:
      return undefined;
    case "ascending":
      return { mode: "value-ascending" };
    case "descending":
      return { mode: "value-descending" };
    case "x":
    case "y":
      return { mode: "cross-axis-ascending" };
    case "-x":
    case "-y":
      return { mode: "cross-axis-descending" };
    default:
      return { mode: "custom-order", customOrder: chartSort };
  }
}

function createAxisWithDataProcessor({
  axis,
  field,
  fieldGroups,
  specClone,
}: {
  field: ExploreField | undefined;
  specClone: ExploreSpec;
  axis: ChartAxisWithData | undefined;
  fieldGroups: SemanticAwareFieldGroup[] | undefined;
}): Record<keyof ChartAxisWithData, () => void> {
  return {
    ...createAxisProcessor(field, axis, fieldGroups),
    dataFrameColumn: () => {
      if (axis?.dataFrameColumn != null) {
        let column = getColumnFromColumnNameWithQueryPath(
          axis.dataFrameColumn,
          fieldGroups ?? [],
        );

        if (axis?.aggregate === "count") {
          column = getCountStarField(column?.queryPath ?? []);
        }

        if (field != null) {
          field.value = column?.columnId ?? axis.dataFrameColumn;
          field.queryPath = column?.queryPath ?? [];
          field.fieldType = column?.fieldType ?? "COLUMN";
        } else {
          specClone.fields.push({
            ...defaultExploreFieldForChartField({
              channel: "base-axis",
              seriesId: specClone.chartConfig.series[0]!.id,
              dataType: axis.type,
              value: column?.columnId ?? axis.dataFrameColumn,
              fieldType: column?.fieldType,
              queryPath: column?.queryPath,
              aggregation: toSqlAggregation(axis.aggregate) ?? undefined,
              displayFormat: getDisplayFormat(axis),
            }),
            title: axis.title,
            axis: newAxisFromStyle(undefined, axis.style),
          });
        }
      } else if (field != null) {
        specClone.fields = specClone.fields.filter((f) => f.id !== field.id);
      }
    },
  };
}

// using keyof ChartDatetimeAxis because it has ALL the fields of ChartAxis
function createAxisProcessor(
  field: ExploreField | undefined,
  axis: ChartAxis | ChartAxisWithData | undefined,
  fieldGroups: SemanticAwareFieldGroup[] | undefined,
): Record<keyof ChartDatetimeAxis, () => void> {
  return {
    title: () => {
      if (field != null) {
        field.title = axis?.title;
      }
    },
    type: () => {
      if (field != null) {
        field.scaleType = axis?.type;
      }
    },
    aggregate: () => {
      if (field != null) {
        let column = getColumnFromColumnNameWithQueryPath(
          field.value,
          fieldGroups ?? [],
        );

        if (axis?.aggregate === "count") {
          column = getCountStarField(column?.queryPath ?? []);
        }

        if (field != null) {
          field.value = column?.columnId ?? field.value;
          field.queryPath = column?.queryPath ?? [];
          field.fieldType = column?.fieldType ?? "COLUMN";
          field.aggregation = toSqlAggregation(axis?.aggregate) ?? undefined;
          field.dataType = column?.columnType
            ? columnTypeToCalciteType(column?.columnType)
            : field.dataType;
        }
      }
    },
    scale: () => {
      if (field != null) {
        field.axis = {
          ...field.axis,
          scale: axis?.scale,
        };
      }
    },
    sort: () => {
      if (field != null) {
        field.sort = exploreSortFromSort(axis?.sort);
      }
    },
    numberFormat: () => {
      if (field != null) {
        field.displayFormat = axis?.numberFormat;
      }
    },
    datetimeFormat: () => {
      if (ChartDatetimeAxis.guard(axis) && field != null) {
        field.displayFormat = axis.datetimeFormat;
      }
    },
    timeUnit: () => {
      if (field != null) {
        field.truncUnit = toSqlTruncUnit(axis?.timeUnit) ?? undefined;
      }
    },
    style: () => {
      if (field != null && axis != null) {
        field.axis = newAxisFromStyle(field.axis, axis.style);
      }
    },
    referenceLines: () => {
      if (field != null) {
        field.axis = {
          ...field.axis,
          referenceLines: axis?.referenceLines,
        };
      }
    },
  };
}

type SeriesFields = keyof Omit<
  Omit<ChartLineSeries, "type"> &
    Omit<ChartBarSeries, "type"> &
    Omit<ChartScatterSeries, "type"> &
    Omit<ChartAreaSeries, "type"> &
    Omit<ChartHistogramSeries, "type"> &
    Omit<ChartPieSeries, "type">,
  "id" | "dataFrame" | "axis"
>;

function createSeriesProcessor({
  chartSeries,
  colorField,
  fieldGroups,
  specClone,
  xField,
  yField,
}: {
  specClone: ExploreSpec;
  xField: ExploreField | undefined;
  yField: ExploreField | undefined;
  colorField: ExploreField | undefined;
  chartSeries: ChartSeries | undefined;
  fieldGroups: SemanticAwareFieldGroup[] | undefined;
}): Record<SeriesFields | "type", () => void> {
  const exploreSeries: ExploreChartSeries | undefined =
    specClone.chartConfig.series.find((s) => s.id === chartSeries?.id);

  return {
    type: () => {
      if (exploreSeries != null && chartSeries != null) {
        exploreSeries.type = chartSeries.type;
      }
    },
    dataFrameColumns: () => {
      if (exploreSeries == null || chartSeries == null) {
        return;
      }

      if (chartSeries.dataFrameColumns.length === 0 && yField != null) {
        specClone.fields = specClone.fields.filter((f) => f.id !== yField.id);
      } else if (chartSeries.dataFrameColumns.length > 0) {
        let column = getColumnFromColumnNameWithQueryPath(
          chartSeries.dataFrameColumns[0]!,
          fieldGroups ?? [],
        );

        if (chartSeries.axis.aggregate === "count") {
          column = getCountStarField(column?.queryPath ?? []);
        }

        const value = column?.columnId ?? chartSeries.dataFrameColumns[0]!;

        if (yField != null) {
          yField.value = value;
          yField.dataType =
            column?.columnType != null
              ? columnTypeToCalciteType(column.columnType)
              : chartDataTypeToCalciteType(chartSeries.axis.type);
          yField.queryPath = column?.queryPath ?? [];
          yField.fieldType = column?.fieldType ?? "COLUMN";
        } else {
          specClone.fields.push(
            defaultExploreFieldForChartField({
              channel: "cross-axis",
              seriesId: ExploreSeriesId.check(chartSeries.id),
              dataType: chartSeries.axis.type,
              value: value,
              fieldType: column?.fieldType,
              queryPath: column?.queryPath,
              aggregation:
                toSqlAggregation(chartSeries.axis.aggregate) ?? undefined,
              displayFormat: getDisplayFormat(chartSeries.axis),
            }),
          );
        }
      }
    },
    colorDataFrameColumn: () => {
      if (chartSeries?.colorDataFrameColumn != null) {
        const column = getColumnFromColumnNameWithQueryPath(
          chartSeries.colorDataFrameColumn,
          fieldGroups ?? [],
        );
        const value = column?.columnId ?? chartSeries.colorDataFrameColumn;

        if (chartSeries.color.type === "series") {
          if (colorField != null) {
            colorField.value = value;
            colorField.queryPath = column?.queryPath ?? [];
            colorField.fieldType = column?.fieldType ?? "COLUMN";
          } else {
            specClone.fields.push(
              defaultExploreFieldForChartField({
                channel: "color",
                seriesId: ExploreSeriesId.check(chartSeries.id),
                dataType: "string",
                value: value,
                fieldType: column?.fieldType,
                queryPath: column?.queryPath,
                displayFormat: getDisplayFormat(chartSeries.axis),
              }),
            );
          }
        }
      } else if (
        colorField != null &&
        chartSeries?.color.dataFrameColumn == null &&
        chartSeries?.color.type === "static"
      ) {
        specClone.fields = specClone.fields.filter(
          (f) => f.id !== colorField.id,
        );
      }
    },
    colorOrder: () => {
      if (colorField != null) {
        colorField.sort = exploreSortFromSort(chartSeries?.colorOrder);
      }
    },
    color: () => {
      if (exploreSeries == null || chartSeries?.color == null) {
        return;
      }

      if (chartSeries.color.type === "static") {
        exploreSeries.color = {
          ...exploreSeries.color,
          staticValue: chartSeries.color.color,
          source: "color",
        };
      } else if (chartSeries.color.type === "dataframe") {
        let column: SemanticAwareColumn | undefined;
        if (chartSeries.color.dataFrameColumn != null) {
          column = getColumnFromColumnNameWithQueryPath(
            chartSeries.color.dataFrameColumn,
            fieldGroups ?? [],
          );

          if (chartSeries.color.aggregate === "count") {
            column = getCountStarField(column?.queryPath ?? []);
          }
        }

        if (colorField != null && chartSeries.color.dataFrameColumn != null) {
          colorField.value =
            column?.columnId ?? chartSeries.color.dataFrameColumn;
          colorField.fieldType = column?.fieldType ?? "COLUMN";
          colorField.queryPath = column?.queryPath ?? [];
          colorField.aggregation =
            toSqlAggregation(chartSeries.color.aggregate) ?? undefined;
          colorField.scaleType = chartSeries.color.dataType;
          if (chartSeries.color.style != null) {
            colorField.axis = {
              ...colorField.axis,
              ...newAxisFromStyle(undefined, chartSeries.color.style),
            };
          }
          colorField.axis = {
            ...colorField.axis,
            scale: chartSeries.color.scale,
            colorScheme: chartSeries.color.scheme,
            reverseColorScheme: chartSeries.color.reverse,
          };

          const activeScaleType = getFieldActiveScaleType(colorField);
          if (activeScaleType === "number") {
            colorField.displayFormat = chartSeries.color.numberFormat;
          } else {
            colorField.displayFormat = chartSeries.color.datetimeFormat;
          }
        } else if (
          colorField != null &&
          chartSeries.color.dataFrameColumn == null
        ) {
          specClone.fields = specClone.fields.filter(
            (f) => f.id !== colorField.id,
          );
        } else if (
          colorField == null &&
          chartSeries.color.dataFrameColumn != null
        ) {
          specClone.fields.push(
            defaultExploreFieldForChartField({
              channel: "color",
              seriesId: ExploreSeriesId.check(chartSeries.id),
              dataType: chartSeries.color.dataType ?? "string",
              value: column?.columnId ?? chartSeries.color.dataFrameColumn,
              fieldType: column?.fieldType,
              queryPath: column?.queryPath,
              aggregation:
                toSqlAggregation(chartSeries.color.aggregate) ?? undefined,
              displayFormat: getDisplayFormat(chartSeries.axis),
            }),
          );
        }
      } else if (chartSeries.color.type === "series") {
        if (colorField != null) {
          colorField.colorsBySeriesValues =
            chartSeries.color.colorsBySeriesValues;
        } else if (xField != null && exploreSeries.type === "bar") {
          xField.colorsBySeriesValues = chartSeries.color.colorsBySeriesValues;
        }
      }

      exploreSeries.color = {
        ...exploreSeries.color,
        source: getColorSource(chartSeries),
      };
    },
    opacity: () => {
      if (exploreSeries != null) {
        specClone.fields = specClone.fields.filter(
          (f) => f.seriesId !== exploreSeries.id || f.channel !== "opacity",
        );

        if (chartSeries?.opacity?.type === "static") {
          exploreSeries.opacity = {
            ...exploreSeries.opacity,
            source: "opacity",
            staticValue: chartSeries.opacity.value,
            staticMode: true,
          };
        } else if (chartSeries?.opacity?.type === "series") {
          if (colorField != null) {
            colorField.opacitiesBySeriesValues =
              chartSeries.opacity.opacitiesBySeriesValues;
            exploreSeries.opacity = {
              ...exploreSeries.opacity,
              source: "color",
              staticMode: false,
            };
          } else if (xField != null && exploreSeries.type === "bar") {
            xField.opacitiesBySeriesValues =
              chartSeries.opacity.opacitiesBySeriesValues;
            exploreSeries.opacity = {
              ...exploreSeries.opacity,
              source: "base-axis",
              staticMode: false,
            };
          }
        } else if (chartSeries?.opacity?.type === "dataframe") {
          exploreSeries.opacity = {
            ...exploreSeries.opacity,
            source: "opacity",
            staticMode: false,
          };
          if (chartSeries.opacity.dataFrameColumn != null) {
            const column = getColumnFromColumnNameWithQueryPath(
              chartSeries.opacity.dataFrameColumn,
              fieldGroups ?? [],
            );

            specClone.fields.push({
              ...defaultExploreFieldForChartField({
                channel: "opacity",
                seriesId: ExploreSeriesId.check(chartSeries.id),
                dataType: chartSeries.opacity.dataType ?? "string",
                value: column?.columnId ?? chartSeries.opacity.dataFrameColumn,
                fieldType: column?.fieldType,
                queryPath: column?.queryPath,
              }),
            });
          }
        }
      }
    },
    tooltip: () => {
      if (exploreSeries != null) {
        exploreSeries.tooltip = {
          includeAuto: chartSeries?.tooltip?.type === "auto",
        };
        // remove all explore tooltip fields that aren't in the chartspec
        // we'll add'em back in as needed
        specClone.fields = specClone.fields.filter(
          (f) => f.channel !== "tooltip",
        );
        if (
          chartSeries?.tooltip?.type === "manual" ||
          chartSeries?.tooltip?.type === "custom"
        ) {
          exploreSeries.tooltip.includeAuto =
            chartSeries.tooltip.includeAuto === true;

          // add in all chartspec tooltip fields that aren't in the explore spec
          chartSeries.tooltip.fields.forEach((tt) => {
            if (
              !specClone.fields.some(
                (f) =>
                  f.channel === "tooltip" &&
                  getColumnNameWithQueryPath({
                    columnId: f.value,
                    columnType: calciteTypeToColumnType(f.dataType),
                    fieldType: f.fieldType,
                    queryPath: f.queryPath,
                    columnName: f.value,
                    description: undefined,
                  }) === tt.dataFrameColumn,
              )
            ) {
              let column = getColumnFromColumnNameWithQueryPath(
                tt.dataFrameColumn,
                fieldGroups ?? [],
              );

              if (tt.aggregate === "count") {
                column = getCountStarField(column?.queryPath ?? []);
              }

              let aggregate = toSqlAggregation(tt.aggregate) ?? undefined;
              if (
                chartSeries?.tooltip?.type === "manual" &&
                aggregate == null &&
                tt.type === "number"
              ) {
                aggregate =
                  chartSeries.axis.aggregate === "count"
                    ? "Sum"
                    : toSqlAggregation(chartSeries.axis.aggregate) ?? undefined;
              }

              specClone.fields.push({
                ...defaultExploreFieldForChartField({
                  channel: "tooltip",
                  seriesId: specClone.chartConfig.series[0]!.id,
                  dataType: tt.type ?? "string",
                  value: column?.columnId ?? tt.dataFrameColumn,
                  fieldType: column?.fieldType,
                  queryPath: column?.queryPath,
                  aggregation: aggregate,
                }),
              });
            }
          });
        }
      }
    },
    dataLabels: () => {
      if (exploreSeries != null) {
        exploreSeries.text = {
          source: "cross-axis",
          dataLabels: chartSeries?.dataLabels,
        };
      }
    },
    totalDataLabels: () => {
      if (exploreSeries != null) {
        exploreSeries.text = {
          source: "cross-axis",
          totalDataLabels: chartSeries?.totalDataLabels,
        };
      }
    },
    legendTitle: () => {
      if (colorField != null) {
        colorField.title = chartSeries?.legendTitle;
      } else if (yField != null) {
        yField.title = chartSeries?.legendTitle;
      }
    },
    name: () => {
      if (exploreSeries != null && chartSeries != null) {
        exploreSeries.name = chartSeries.name;
      }
    },
    filled: () => {
      if (exploreSeries != null && ChartScatterSeries.guard(chartSeries)) {
        exploreSeries.pointFilled = chartSeries.filled;
      }
    },
    shape: () => {
      if (exploreSeries != null && ChartScatterSeries.guard(chartSeries)) {
        exploreSeries.pointShape = { staticValue: chartSeries.shape };
      }
    },
    size: () => {
      if (exploreSeries != null && ChartScatterSeries.guard(chartSeries)) {
        exploreSeries.pointSize = { staticValue: chartSeries.size };
      }
    },
    barWidth: () => {
      /* unused, noop */
    },
    layout: () => {
      if (exploreSeries != null && ChartBarSeries.guard(chartSeries)) {
        exploreSeries.barGrouped = chartSeries.layout === "grouped";
      }
    },
    orientation: () => {
      if (exploreSeries != null && ChartBarSeries.guard(chartSeries)) {
        specClone.chartConfig.orientation = chartSeries.orientation;
      }
    },
    line: () => {
      if (exploreSeries != null && ChartAreaSeries.guard(chartSeries)) {
        exploreSeries.areaLine = chartSeries.line;
      }
    },
    point: () => {
      if (
        exploreSeries != null &&
        (ChartAreaSeries.guard(chartSeries) ||
          ChartLineSeries.guard(chartSeries))
      ) {
        exploreSeries.linePoint = chartSeries.point;
      }
    },
    interpolate: () => {
      if (
        exploreSeries != null &&
        (ChartAreaSeries.guard(chartSeries) ||
          ChartLineSeries.guard(chartSeries))
      ) {
        exploreSeries.lineShape = chartSeries.interpolate;
      }
    },
    normalize: () => {
      if (exploreSeries != null && ChartAreaSeries.guard(chartSeries)) {
        exploreSeries.normalize = chartSeries.normalize
          ? "base-axis"
          : undefined;
      }
    },
    width: () => {
      if (exploreSeries != null && ChartLineSeries.guard(chartSeries)) {
        exploreSeries.lineWidth = { staticValue: chartSeries.width };
      }
    },
    stroke: () => {
      if (exploreSeries != null && ChartLineSeries.guard(chartSeries)) {
        exploreSeries.lineStroke = { staticValue: chartSeries.stroke };
      }
    },
    bin: () => {
      if (
        exploreSeries != null &&
        xField != null &&
        ChartHistogramSeries.guard(chartSeries)
      ) {
        if (chartSeries.bin.type === "dataFrameColumn") {
          xField.bin = {
            useColumn: true,
          };
        } else if (chartSeries.bin.type === "count") {
          xField.bin = {
            count: chartSeries.bin.value,
          };
        } else if (chartSeries.bin.type === "size") {
          xField.bin = {
            size: chartSeries.bin.value,
          };
        }
      }
    },
    format: () => {
      if (exploreSeries != null && ChartHistogramSeries.guard(chartSeries)) {
        exploreSeries.normalize =
          chartSeries.format === "percentage" ? "facet" : undefined;
      }
    },
    radius: () => {
      if (exploreSeries != null && ChartPieSeries.guard(chartSeries)) {
        exploreSeries.radius = { staticValue: chartSeries.radius };
      }
    },
    showAsPct: () => {
      if (exploreSeries != null && ChartPieSeries.guard(chartSeries)) {
        exploreSeries.normalize = chartSeries.showAsPct ? "facet" : undefined;
      }
    },
  };
}

function createFacetProcessor({
  facet,
  fieldGroups,
  hFacetField,
  specClone,
  vFacetField,
}: {
  hFacetField: ExploreField | undefined;
  vFacetField: ExploreField | undefined;
  specClone: ExploreSpec;
  facet: ChartFacet | undefined;
  fieldGroups: SemanticAwareFieldGroup[] | undefined;
}): Record<
  keyof ChartFacet,
  (() => void) | Record<keyof ChartFacetDimension, () => void>
> {
  return {
    columns: () => {
      if (hFacetField != null && facet != null) {
        specClone.chartConfig.facet = {
          ...specClone.chartConfig.facet,
          columns: facet.columns,
        };
      }
    },
    sharedY: () => {
      if (vFacetField != null && facet != null) {
        specClone.chartConfig.facet = {
          ...specClone.chartConfig.facet,
          sharedY: facet.sharedY,
        };
      }
    },
    facetHorizontal:
      // when facet column is unset, it removes the whole facet dimension which means that
      // the handler for facet dimension needs to be a function to handle the deletion instead
      // of an object with key -> function mappings
      facet != null && facet.facetHorizontal == null && hFacetField != null
        ? createRemoveFacetDimensionProcessor(specClone, hFacetField)
        : createFacetDimensionProcessor({
            field: hFacetField,
            specClone,
            facetDimension: facet?.facetHorizontal,
            facetType: "h-facet",
            fieldGroups,
          }),
    facetVertical:
      facet != null && facet.facetVertical == null && vFacetField != null
        ? createRemoveFacetDimensionProcessor(specClone, vFacetField)
        : createFacetDimensionProcessor({
            field: vFacetField,
            specClone,
            facetDimension: facet?.facetVertical,
            facetType: "v-facet",
            fieldGroups,
          }),
  };
}

function createRemoveFacetDimensionProcessor(
  specClone: ExploreSpec,
  field: ExploreField,
): () => void {
  return () => {
    specClone.fields = specClone.fields.filter((f) => f.id !== field.id);
  };
}

function createFacetDimensionProcessor({
  facetDimension,
  facetType,
  field,
  fieldGroups,
  specClone,
}: {
  field: ExploreField | undefined;
  specClone: ExploreSpec;
  facetType: "h-facet" | "v-facet";
  facetDimension: ChartFacetDimension | undefined;
  fieldGroups: SemanticAwareFieldGroup[] | undefined;
}): Record<keyof ChartFacetDimension, () => void> {
  return {
    type: () => {
      if (field != null) {
        field.scaleType = facetDimension?.type;
      }
    },
    dataFrameColumn: () => {
      if (facetDimension?.dataFrameColumn != null) {
        const column = getColumnFromColumnNameWithQueryPath(
          facetDimension.dataFrameColumn,
          fieldGroups ?? [],
        );
        const value = column?.columnId ?? facetDimension.dataFrameColumn;

        if (field != null) {
          field.value = value;
          field.queryPath = column?.queryPath ?? [];
          field.fieldType = column?.fieldType ?? "COLUMN";
        } else {
          specClone.fields.push({
            ...defaultExploreFieldForChartField({
              channel: facetType,
              seriesId: specClone.chartConfig.series[0]!.id,
              dataType: facetDimension.type,
              value: value,
              fieldType: column?.fieldType,
              queryPath: column?.queryPath,
            }),
            sort: exploreSortFromSort(facetDimension.sort),
            bin: {
              count: facetDimension.maxBins,
              size: facetDimension.binStep,
            },
            truncUnit: toSqlTruncUnit(facetDimension.timeUnit) ?? undefined,
          });
        }
      } else if (field != null) {
        specClone.fields = specClone.fields.filter((f) => f.id !== field.id);
      }
    },
    sort: () => {
      if (field != null) {
        field.sort = exploreSortFromSort(facetDimension?.sort);
      }
    },
    maxBins: () => {
      if (field != null) {
        field.bin = {
          count: facetDimension?.maxBins,
        };
      }
    },
    binStep: () => {
      if (field != null) {
        field.bin = {
          size: facetDimension?.binStep,
        };
      }
    },
    timeUnit: () => {
      if (field != null) {
        field.truncUnit = toSqlTruncUnit(facetDimension?.timeUnit) ?? undefined;
      }
    },
  };
}

function getDisplayFormat(
  axis: ChartAxis | ChartAxisWithData,
): ExploreField["displayFormat"] | undefined {
  if (ChartDatetimeAxis.guard(axis) && axis.type === "datetime") {
    return axis.datetimeFormat;
  }
  return axis.numberFormat;
}

function newAxisFromStyle(
  axis: ExploreAxis | undefined,
  style: ChartAxisStyle,
): ExploreAxis {
  return {
    ...axis,
    min: style.min,
    max: style.max,
    grid: style.grid ?? { style: "none" },
    ticks: style.ticks,
    zero: style.zero,
    position: style.position,
    labelAngle: style.labels?.angle,
  };
}

function chartDataTypeToCalciteType(dataType: ChartDataType): CalciteType {
  switch (dataType) {
    case "string":
      return "VARCHAR";
    case "number":
      return "FLOAT";
    case "datetime":
      return "TIMESTAMP";
    default:
      return "VARCHAR";
  }
}

function defaultExploreFieldForChartField({
  aggregation,
  channel,
  dataType,
  displayFormat,
  fieldType,
  queryPath,
  seriesId,
  value,
}: {
  channel: ExploreChannel;
  value: string;
  seriesId: ExploreSeriesId;
  dataType: ChartDataType;
  displayFormat?: ExploreField["displayFormat"];
  aggregation?: HqlAggregationFunction;
  queryPath?: SemanticDatasetName[];
  fieldType?: ExploreFieldType;
}): ExploreField {
  return defaultExploreField({
    aggregation,
    channel,
    dataType: chartDataTypeToCalciteType(dataType),
    scaleType: dataType,
    displayFormat,
    seriesId,
    value,
    queryPath,
    fieldType,
  });
}
