import { CellRendererSelectorFunc } from "@ag-grid-community/core";
import { ProjectRole } from "@hex/common";
import { identity } from "lodash";

import { MinimalCategory } from "../../../hooks/useProjectLabelsForHex.js";
import { HexPermissionSummaryContainer } from "../../hex/HexPermissionSummaryContainer.js";
import { shouldShowViewPublishedAppStats } from "../../hex-list-2/hex-row/hooks/useShouldShowViewPublishedAppStats.js";
import { PermissionSummary } from "../../hex-list-2/hex-row/utils.js";
import { ProjectsTableSafeOrUnknownHexFragment } from "../ProjectsTable.generated.js";

import { LinkedNameWithActionsMenuRenderer } from "./table-cell-renderers/LinkedNameWithActionsMenuRenderer.js";
import { ReadonlyNameCellRenderer } from "./table-cell-renderers/ReadonlyNameCellRenderer.js";
import {
  AppViewsTableCellRenderer,
  CategoriesTableCellRenderer,
  CollapsedCategoriesTableCellRenderer,
  CollectionProjectRoleTableCellRenderer,
  NullishCellValue,
} from "./TableCellRenderers.js";

type HexCellRendererSelectorFunc<VALUE> = CellRendererSelectorFunc<
  ProjectsTableSafeOrUnknownHexFragment,
  VALUE
>;

export const NameCellRendererSelector: CellRendererSelectorFunc<
  ProjectsTableSafeOrUnknownHexFragment
> = ({ data }) => {
  if (data == null) {
    return {
      component: NullishCellValue,
    };
  }

  if (data.__typename === "UnknownHex") {
    return {
      component: ReadonlyNameCellRenderer,
      params: {
        mapDataToHex: identity,
      },
    };
  }

  return {
    component: LinkedNameWithActionsMenuRenderer,
    params: {
      mapDataToHex: identity,
    },
  };
};

export const AppViewsCellRendererSelector: HexCellRendererSelectorFunc<
  number
> = ({ data, value }) => {
  if (data == null || value == null || data.__typename === "UnknownHex") {
    return {
      component: NullishCellValue,
    };
  }

  const showViewPublishedappStats = shouldShowViewPublishedAppStats(data);
  // Do not show to app-only viewers
  if (showViewPublishedappStats && data.canViewLogic) {
    return {
      component: AppViewsTableCellRenderer,
      params: {
        id: data.id,
      },
    };
  }

  return {
    component: NullishCellValue,
  };
};

export const CategoriesCellRendererSelector: HexCellRendererSelectorFunc<
  MinimalCategory[]
> = ({ data, value: categories }) => {
  if (
    data == null ||
    categories == null ||
    !Array.isArray(categories) ||
    categories.length === 0
  ) {
    return {
      component: NullishCellValue,
    };
  }

  if (categories.length > 2) {
    return {
      component: CollapsedCategoriesTableCellRenderer,
    };
  }

  return {
    component: CategoriesTableCellRenderer,
  };
};

export const CollectionAccessCellRendererSelector: HexCellRendererSelectorFunc<
  ProjectRole
> = ({ context: { canEditCollection }, data }) => {
  if (
    data == null ||
    data.__typename === "UnknownHex" ||
    data.collectionHexLink == null
  ) {
    return {
      component: NullishCellValue,
    };
  }
  const {
    collectionHexLink: {
      collection: { id: collectionId },
      id: collectionHexLinkId,
    },
  } = data;

  return {
    component: CollectionProjectRoleTableCellRenderer,
    params: {
      canEditCollection,
      collectionId,
      hex: data,
      collectionHexLinkId,
    },
  };
};

export const PermissionSummaryCellRendererSelector: HexCellRendererSelectorFunc<
  PermissionSummary
> = ({ data, value }) => {
  if (data == null || value == null || data.__typename === "UnknownHex") {
    return {
      component: NullishCellValue,
    };
  }

  return {
    component: HexPermissionSummaryContainer,
    params: {
      icon: value.icon,
      minimal: undefined,
      text: value.text,
      tooltipText: value.tooltipText,
    },
  };
};
