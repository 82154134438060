import { HexOrder, OrderByDirection, assertNever } from "@hex/common";
import React, { useCallback, useContext } from "react";

import { TERMINOLOGY } from "../../../hooks/useTerminologyText.js";
import { ViewCountDuration } from "../../hex-list/ViewCountDuration.js";
import {
  COLUMN_CONFIGURATION,
  PROJECTS_TABLE_SORT_ORDER,
} from "../../projects-table/columnDefinitions.js";
import { ProjectsTableContext } from "../../projects-table/context/ProjectsTableContext.js";
import { ProjectsTableColumnId } from "../../projects-table/types.js";

import { GenericSortComponent } from "./GenericSortOrder.js";

export enum HexListSortType {
  LAST_UPDATED = "LAST_UPDATED",
  PUBLISHED_VIEWS = "PUBLISHED_VIEW",
  RECENTLY_VIEWED = "RECENTLY_VIEWED",
  RECENTLY_PUBLISHED = "RECENTLY_PUBLISHED",
  PROJECT_NAME = "PROJECT_NAME",
  DATE_CREATED = "DATE_CREATED",
  OWNER = "OWNER",
  CREATOR = "CREATOR",
  STATUS = "STATUS",
  CATEGORY = "CATEGORY",
  MY_ACCESS = "MY_ACCESS",
  APP_ONLY_RECENTLY_VIEWED = "APP_ONLY_RECENTLY_VIEWED",

  // collection only sort types; note that these are included in the 'HexListSortType' so that the Collections tab
  // and the Projects table tabs can share AG grid project context and URL param state.
  PROJECT_ACCESS = "PROJECT_ACCESS",
  COLLECTION_LINK_PROJECT_ROLE = "COLLECTION_LINK_PROJECT_ROLE",

  // only available on the archive page
  ARCHIVED_AT = "ARCHIVED_AT",
}

export const SortTypeToColumnMap: Record<
  HexListSortType,
  ProjectsTableColumnId
> = {
  [HexListSortType.LAST_UPDATED]: ProjectsTableColumnId.DATE_LAST_EDITED,
  [HexListSortType.PUBLISHED_VIEWS]: ProjectsTableColumnId.APP_VIEWS,
  [HexListSortType.RECENTLY_VIEWED]: ProjectsTableColumnId.RECENTLY_VIEWED,
  [HexListSortType.RECENTLY_PUBLISHED]:
    ProjectsTableColumnId.DATE_LAST_PUBLISHED,
  [HexListSortType.PROJECT_NAME]: ProjectsTableColumnId.NAME,
  [HexListSortType.DATE_CREATED]: ProjectsTableColumnId.DATE_CREATED,
  [HexListSortType.OWNER]: ProjectsTableColumnId.OWNER,
  [HexListSortType.CREATOR]: ProjectsTableColumnId.CREATOR,
  [HexListSortType.PROJECT_ACCESS]: ProjectsTableColumnId.MY_ACCESS,
  [HexListSortType.COLLECTION_LINK_PROJECT_ROLE]:
    ProjectsTableColumnId.COLLECTION_ACCESS,
  [HexListSortType.CATEGORY]: ProjectsTableColumnId.CATEGORIES,
  [HexListSortType.STATUS]: ProjectsTableColumnId.STATUS,
  [HexListSortType.ARCHIVED_AT]: ProjectsTableColumnId.DATE_ARCHIVED,
  [HexListSortType.MY_ACCESS]: ProjectsTableColumnId.MY_ACCESS,
  [HexListSortType.APP_ONLY_RECENTLY_VIEWED]:
    ProjectsTableColumnId.DATE_PUBLISHED_APP_LAST_VIEWED,
};
/**
 * Any date column or counts column should be DESC.
 * Semantically searching by a name (like PROJECT_NAME) should be ASC.
 */
export const getDefaultHexOrderByDirection = (
  hexOrder?: PROJECTS_TABLE_SORT_ORDER,
): OrderByDirection => {
  if (hexOrder === undefined) {
    return "DESC";
  }
  switch (hexOrder) {
    case HexOrder.PROJECT_NAME:
    case HexOrder.OWNER:
    case HexOrder.CREATOR:
    case HexOrder.STATUS:
    case HexOrder.CATEGORY:
      return "ASC";
    case HexOrder.LAST_EDIT:
    case HexOrder.LAST_PUBLISH:
    case HexOrder.RECENTLY_VIEWED:
    case HexOrder.TOTAL_VIEW_COUNT:
    case HexOrder.LAST_SEVEN_DAYS_VIEW_COUNT:
    case HexOrder.LAST_FOURTEEN_DAY_VIEW_COUNT:
    case HexOrder.LAST_THIRTY_DAY_VIEW_COUNT:
    case HexOrder.CREATED_AT:
    case HexOrder.ARCHIVED_AT:
    case HexOrder.MY_ACCESS:
    case HexOrder.APP_ONLY_RECENTLY_VIEWED:
    case "COLLECTION_LINK_PROJECT_ROLE":
      return "DESC";
    default:
      assertNever(hexOrder, hexOrder);
  }
};

/**
 * Any date column or counts column should be DESC.
 * Semantically searching by a name (like PROJECT_NAME) should be ASC.
 */
export const getDefaultHexOrderFromHexListSortType = (
  order: HexListSortType,
  isCollection?: boolean,
): OrderByDirection => {
  const hexOrderOrCollectionsOrder =
    isCollection && order === HexListSortType.COLLECTION_LINK_PROJECT_ROLE
      ? "COLLECTION_LINK_PROJECT_ROLE"
      : getHexOrderFromFilterValues(order, ViewCountDuration.ALL_TIME);
  return getDefaultHexOrderByDirection(hexOrderOrCollectionsOrder);
};

export const getHexOrderFromFilterValues = (
  sortByKey: HexListSortType,
  viewByKey: ViewCountDuration,
): HexOrder => {
  switch (sortByKey) {
    case HexListSortType.LAST_UPDATED:
      return HexOrder.LAST_EDIT;
    case HexListSortType.RECENTLY_VIEWED:
      return HexOrder.RECENTLY_VIEWED;
    case HexListSortType.RECENTLY_PUBLISHED:
      return HexOrder.LAST_PUBLISH;
    case HexListSortType.PROJECT_NAME:
      return HexOrder.PROJECT_NAME;
    case HexListSortType.DATE_CREATED:
      return HexOrder.CREATED_AT;
    case HexListSortType.OWNER:
      return HexOrder.OWNER;
    case HexListSortType.CREATOR:
      return HexOrder.CREATOR;
    case HexListSortType.CATEGORY:
      return HexOrder.CATEGORY;
    case HexListSortType.STATUS:
      return HexOrder.STATUS;
    case HexListSortType.ARCHIVED_AT:
      return HexOrder.ARCHIVED_AT;
    case HexListSortType.MY_ACCESS:
      return HexOrder.MY_ACCESS;
    case HexListSortType.APP_ONLY_RECENTLY_VIEWED:
      return HexOrder.APP_ONLY_RECENTLY_VIEWED;
    case HexListSortType.PUBLISHED_VIEWS: {
      switch (viewByKey) {
        case ViewCountDuration.ONE_WEEK:
          return HexOrder.LAST_SEVEN_DAYS_VIEW_COUNT;

        case ViewCountDuration.TWO_WEEKS:
          return HexOrder.LAST_FOURTEEN_DAY_VIEW_COUNT;

        case ViewCountDuration.ALL_TIME:
          return HexOrder.TOTAL_VIEW_COUNT;

        case ViewCountDuration.ONE_MONTH:
          return HexOrder.LAST_THIRTY_DAY_VIEW_COUNT;

        default:
          assertNever(viewByKey, viewByKey);
      }
      break;
    }
    // These two HexListSortTypes should never be called from the Projects page,
    // and if they are, log a console error and fail-safe to LAST_EDIT.
    case HexListSortType.PROJECT_ACCESS:
      console.error(
        "Collections only: can not use this filter type",
        sortByKey,
      );
      return HexOrder.LAST_EDIT;
    case HexListSortType.COLLECTION_LINK_PROJECT_ROLE:
      console.error(
        "Collections only: can not use this filter type",
        sortByKey,
      );
      return HexOrder.LAST_EDIT;
    default:
      assertNever(sortByKey, sortByKey);
  }
};

export const SortTypeToHumanReadableTitle: { [K in HexListSortType]: string } =
  {
    [HexListSortType.LAST_UPDATED]: TERMINOLOGY.lastUpdatedByUserText,
    [HexListSortType.PUBLISHED_VIEWS]: TERMINOLOGY.appViewText,
    [HexListSortType.RECENTLY_PUBLISHED]: TERMINOLOGY.appLastPublishedText,
    [HexListSortType.RECENTLY_VIEWED]: TERMINOLOGY.lastViewedByYou,
    [HexListSortType.PROJECT_NAME]: TERMINOLOGY.projectNameText,
    [HexListSortType.DATE_CREATED]: "Created",
    [HexListSortType.CREATOR]: "Creator",
    [HexListSortType.OWNER]: "Owner",
    [HexListSortType.COLLECTION_LINK_PROJECT_ROLE]: "Collection access",
    [HexListSortType.PROJECT_ACCESS]: "Project access",
    [HexListSortType.CATEGORY]: "Categories",
    [HexListSortType.STATUS]: "Status",
    [HexListSortType.ARCHIVED_AT]: "Archived",
    [HexListSortType.MY_ACCESS]: TERMINOLOGY.yourAccessText,
    [HexListSortType.APP_ONLY_RECENTLY_VIEWED]:
      TERMINOLOGY.appLastViewedByAnyoneText,
  };

export const SORT_OPTIONS = [
  HexListSortType.RECENTLY_VIEWED,
  HexListSortType.PROJECT_NAME,
  HexListSortType.CATEGORY,
  HexListSortType.STATUS,
  HexListSortType.CREATOR,
  HexListSortType.OWNER,
  HexListSortType.DATE_CREATED,
  HexListSortType.LAST_UPDATED,
  HexListSortType.PUBLISHED_VIEWS,
  HexListSortType.RECENTLY_PUBLISHED,
  HexListSortType.APP_ONLY_RECENTLY_VIEWED,
  HexListSortType.MY_ACCESS,
];

/**
 * Transform the current HexOrder sort attribute into the HexListSortType, which is used
 * to change the current state of the sort dropdown button.
 */
export const getFilterValueFromHexOrder = (
  hexOrder: HexOrder,
): HexListSortType => {
  switch (hexOrder) {
    case HexOrder.LAST_EDIT:
      return HexListSortType.LAST_UPDATED;
    case HexOrder.LAST_PUBLISH:
      return HexListSortType.RECENTLY_PUBLISHED;
    case HexOrder.RECENTLY_VIEWED:
      return HexListSortType.RECENTLY_VIEWED;
    case HexOrder.TOTAL_VIEW_COUNT:
      return HexListSortType.PUBLISHED_VIEWS;
    case HexOrder.LAST_SEVEN_DAYS_VIEW_COUNT:
    case HexOrder.LAST_FOURTEEN_DAY_VIEW_COUNT:
    case HexOrder.LAST_THIRTY_DAY_VIEW_COUNT:
      return HexListSortType.PUBLISHED_VIEWS;
    case HexOrder.PROJECT_NAME:
      return HexListSortType.PROJECT_NAME;
    case HexOrder.CREATED_AT:
      return HexListSortType.DATE_CREATED;
    case HexOrder.CREATOR:
      return HexListSortType.CREATOR;
    case HexOrder.OWNER:
      return HexListSortType.OWNER;
    case HexOrder.CATEGORY:
      return HexListSortType.CATEGORY;
    case HexOrder.STATUS:
      return HexListSortType.STATUS;
    case HexOrder.ARCHIVED_AT:
      return HexListSortType.ARCHIVED_AT;
    case HexOrder.MY_ACCESS:
      return HexListSortType.MY_ACCESS;
    case HexOrder.APP_ONLY_RECENTLY_VIEWED:
      return HexListSortType.APP_ONLY_RECENTLY_VIEWED;
    default:
      assertNever(hexOrder, hexOrder);
  }
};

export const oppositeSortDirection: {
  [K in OrderByDirection]: OrderByDirection;
} = {
  ASC: "DESC",
  DESC: "ASC",
};

interface SortByHexOrderProps {
  hexOrder: HexOrder;
  onChange: (
    value: HexListSortType,
    cb: (sortOrder: HexOrder, sortDirection: OrderByDirection) => void,
  ) => void;
  updateSortDirection: (direction: OrderByDirection) => void;
  sortDirection: OrderByDirection;
  sortOptionsOverride?: HexListSortType[];
}

export const SortByHexOrder: React.ComponentType<SortByHexOrderProps> = ({
  hexOrder,
  onChange,
  sortDirection,
  sortOptionsOverride,
  updateSortDirection,
}) => {
  const { handleColumnVisibilityChange, isGridReady, syncSortModel } =
    useContext(ProjectsTableContext);

  const sortType = getFilterValueFromHexOrder(hexOrder);

  const handleToggleDirection = useCallback(() => {
    const nextSortDirection = oppositeSortDirection[sortDirection];
    updateSortDirection(oppositeSortDirection[sortDirection]);
    isGridReady && syncSortModel(hexOrder, nextSortDirection);
  }, [
    sortDirection,
    updateSortDirection,
    syncSortModel,
    isGridReady,
    hexOrder,
  ]);

  const handleChangeOrder = useCallback(
    (value: HexListSortType) => {
      // If the column behind the sort is not currently visible, show it
      const correspondingColumn = SortTypeToColumnMap[value];
      handleColumnVisibilityChange({
        checked: true,
        id: COLUMN_CONFIGURATION[correspondingColumn].colId,
      });

      onChange(value, syncSortModel);
    },
    [handleColumnVisibilityChange, onChange, syncSortModel],
  );

  return (
    <GenericSortComponent
      handleChangeOrder={handleChangeOrder}
      handleToggleDirection={handleToggleDirection}
      sortDirection={sortDirection}
      sortLabels={SortTypeToHumanReadableTitle}
      sortOptions={sortOptionsOverride ?? SORT_OPTIONS}
      sortType={sortType}
    />
  );
};
