import {
  DEFAULT_METRIC_COMPARISON_RESULT_VARIABLE,
  DEFAULT_METRIC_VALUE_RESULT_VARIABLE,
  notEmpty,
} from "@hex/common";
import { escapeRegExp } from "lodash";
import { useCallback } from "react";

import { useScopeGetter } from "../../appsession-multiplayer/state-hooks/scopeStateHooks";
import { useCellsContentsGetter } from "../../hex-version-multiplayer/state-hooks/cellsContentsStateHooks";

export function useGetUniqueVariableNameForMetricCell(): (
  type: "value" | "comparison",
) => string {
  const getCellContents = useCellsContentsGetter();
  const getScope = useScopeGetter();
  return useCallback(
    (type: "value" | "comparison") => {
      const cells = Object.values(getCellContents())
        .filter(notEmpty)
        .flatMap((c) =>
          c.deletedDate != null && c.__typename === "MetricCell" ? c : [],
        );

      const scopeItemNames = Object.values(getScope())
        .map((s) => s?.name)
        .filter(notEmpty);

      const existingNames = [
        ...cells.flatMap((c) => {
          const variable =
            type === "value"
              ? c.valueResultVariable
              : c.comparisonResultVariable;
          return variable ? [variable] : [];
        }),
        ...scopeItemNames,
      ];

      return generateNameForDuplicate(
        type === "value"
          ? DEFAULT_METRIC_VALUE_RESULT_VARIABLE
          : DEFAULT_METRIC_COMPARISON_RESULT_VARIABLE,
        existingNames,
      );
    },
    [getCellContents, getScope],
  );
}
export function useGetUniqueVariableName(
  cellType:
    | "SqlCell"
    | "DbtMetricCell"
    | "PivotCell"
    | "FilterCell"
    | "ChartCell"
    | "DisplayTableCell"
    | "ExploreCell",
): (originalName: string, extraExistingNames?: string[]) => string {
  const getCellContents = useCellsContentsGetter();
  const getScope = useScopeGetter();
  return useCallback(
    (originalName: string, extraExistingNames = []) => {
      const cells =
        Object.values(getCellContents())
          .filter(notEmpty)
          .filter((c) => c.deletedDate == null)
          .flatMap((c) => {
            // Keep cells with the requested cellType.
            // Since FilterCell and ChartCell share the same default variable
            // name, include cells of both types when either is requested.
            return c.__typename === cellType ||
              (c.__typename === "FilterCell" && cellType === "ChartCell") ||
              (c.__typename === "ChartCell" && cellType === "FilterCell")
              ? c
              : [];
          }) ?? [];

      const scopeItemNames = Object.values(getScope())
        .map((s) => s?.name)
        .filter(notEmpty);
      const existingNames = [
        ...cells
          .map((c) =>
            c.__typename === "ExploreCell"
              ? c.resultVariables
              : c.resultVariable,
          )
          .flat(),
        ...scopeItemNames,
        ...extraExistingNames,
      ];

      return generateNameForDuplicate(originalName, existingNames);
    },
    [cellType, getCellContents, getScope],
  );
}

const ENDING_DIGITS_REGEX = /_?\d+$/;

// exported for testing
export function generateNameForDuplicate(
  baseName: string,
  existingNames: string[],
): string {
  // remove the trailing suffix from baseName if present (e.g. "_234")
  let realBaseName = baseName;
  const baseNameMatch = baseName.match(ENDING_DIGITS_REGEX);
  if (baseNameMatch?.index != null) {
    realBaseName = baseName.substring(0, baseNameMatch.index);
  }

  // find all names starting with the base name and with an optional numeric suffix
  const matchingNameRegex = new RegExp(
    `${escapeRegExp(realBaseName)}((_)?(\\d+))?$`,
  );
  const nameMatches = existingNames
    .map((name) => name.match(matchingNameRegex))
    .filter(notEmpty);

  // if no matches, just use the base name for our variable
  if (nameMatches.length === 0) {
    return realBaseName;
  }

  const numbersFromMatches = nameMatches
    .map((match) => match[3])
    .filter(notEmpty)
    .map((numStr) => Number(numStr));

  // if we had a match, but none of the matches have a numeric suffix, then add the `_2` suffix
  if (numbersFromMatches.length === 0) {
    return `${realBaseName}_2`;
  }

  // Finally, if there are matches with a numeric suffix, make our variable one more than the max.
  const seperator = nameMatches.find((match) => match[2] != null) ? "_" : "";
  return `${realBaseName}${seperator}${Math.max(...numbersFromMatches) + 1}`;
}
