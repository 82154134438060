import {
  DataConnectionId,
  DataSourceTableId,
  DataframeMention,
  MentionedDataframeName,
  RichTextDocument,
  TableMention,
  convertRichTextToPlainText,
  getMentionElements,
  getNormalEnum,
  isRichTextFromEmptyString,
} from "@hex/common";
import {
  PlateController,
  PlateEditor,
  TDescendant,
  TElement,
  TText,
  Value,
  blurEditor,
  createPluginFactory,
  focusEditor,
  isCollapsed,
  toDOMNode,
  useEditorRef,
  usePlateActions,
  useReplaceEditor,
} from "@udecode/plate-common";
import React, {
  FocusEventHandler,
  KeyboardEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { Literal, Static, Union } from "runtypes";

import { useEditMode } from "../../hooks/useEditMode.js";
import { useStableRef } from "../../hooks/useStableRef.js";
import { useUniqueId } from "../../hooks/useUniqueId.js";
import { MarkdownSize } from "../../theme/common/theme.js";
import { MagicHotKeys } from "../../util/hotkeys.js";
import { KeyBoardEventCode, Keys, isAltKeyPress } from "../../util/Keys.js";
import { PlateRichTextEditor } from "../rich-text/PlateRichTextEditor.js";
import { RichTextMentionContext } from "../rich-text/plugins/RichTextMentionContext";

import { isCursorOnFirstLine, isCursorOnLastLine } from "./slateHelpers";
export function getSubmitArgsFromRichText(richText: RichTextDocument): {
  prompt: string;
  mentionedTableIds: DataSourceTableId[];
  mentionedDataframes: MentionedDataframeName[];
  mentionedDataConnectionId: DataConnectionId | null;
  dataframeSql: boolean;
} | null {
  const prompt = convertRichTextToPlainText(richText).trim();
  if (prompt.length === 0) {
    return null;
  }
  const mentionedTableIds: DataSourceTableId[] = [];
  const mentionedDataframes: MentionedDataframeName[] = [];
  let mentionedDataConnectionId = null;
  for (const mention of getMentionElements(richText)) {
    if (
      TableMention.guard(mention) &&
      !mentionedTableIds.includes(mention.tableId)
    ) {
      mentionedTableIds.push(mention.tableId);
      mentionedDataConnectionId = mention.connectionId;
    } else if (
      DataframeMention.guard(mention) &&
      !mentionedDataframes.includes(mention.name)
    ) {
      mentionedDataframes.push(mention.name);
    }
  }

  const dataframeSql =
    mentionedDataframes.length > 0 && mentionedTableIds.length === 0;

  return {
    prompt,
    mentionedTableIds,
    mentionedDataframes,
    mentionedDataConnectionId,
    dataframeSql,
  };
}

interface MagicInputProps {
  autoFocus?: boolean;
  prompt?: RichTextDocument;
  placeholder?: string;
  disabled?: boolean;
  onEditorKeyDown?: KeyboardEventHandler;
  onSubmit?: (e: React.KeyboardEvent<Element>) => void;
  onFocus?: FocusEventHandler;
  mentionContext?: RichTextMentionContext;
  onChange?: (prompt: RichTextDocument) => void;
  size?: MarkdownSize;
}

interface MagicInputInternalProps extends MagicInputProps {
  plateId: string;
}

export const MagicInput = React.memo(
  forwardRef(function MagicInput(props: MagicInputProps, ref) {
    const plateId = useUniqueId();

    return (
      <PlateController activeId={plateId}>
        <MagicInputInternal {...props} ref={ref} plateId={plateId} />
      </PlateController>
    );
  }),
);

export const MagicInputInternal = React.memo(
  forwardRef(function MagicInputInternal(
    {
      autoFocus,
      disabled,
      mentionContext,
      onChange,
      onEditorKeyDown: onEditorKeyDown_,
      onFocus: onFocus_,
      onSubmit,
      placeholder,
      plateId,
      prompt,
      size,
    }: MagicInputInternalProps,
    ref,
  ) {
    const editor = useEditorRef<RichTextDocument>(plateId);
    const editorRef = useRef<HTMLElement | null>(null);

    const isEmpty = !prompt || isRichTextFromEmptyString(prompt);

    useEffect(() => {
      if (!editor.isFallback) {
        editorRef.current = toDOMNode(editor, editor) ?? null;
      }
    }, [editor]);

    const getPromptBarValue = useCallback(() => {
      return editor.isFallback ? [] : editor.children;
    }, [editor]);

    const setValue = usePlateActions(plateId).value();
    const replaceEditor = useReplaceEditor();

    // On load, set the editor value to the prompt value that's passed in
    useEffect(() => {
      if (
        prompt !== undefined &&
        !editor.isFallback &&
        prompt !== getPromptBarValue()
      ) {
        // This somewhat awkward syntax is undocumented but seems to be the only way
        // to programmatically update the value of the editor.
        replaceEditor();
        setValue(prompt);
      }
    }, [editor, getPromptBarValue, prompt, replaceEditor, setValue]);

    // Plate editor plugins are registered **once**, which means that
    // the references of objects created when initializing the plugin can
    // get out of date. Instead of accessing the objects directly, we use a
    // ref to make sure that the most current references are used in callbacks
    const pluginProps = useStableRef({
      onEditorKeyDown_,
      onFocus_,
      onSubmit,
    });

    useImperativeHandle(ref, () => ({
      focus: () => {
        !editor.isFallback && focusEditor(editor);
      },
      dispatchEvent: (event: KeyboardEvent) => {
        if (mentionContext != null && event.key === "@" && !editor.isFallback) {
          const cursor_val = cursorAtMention(editor);
          handleAtEvent(editor, cursor_val);
        }
      },
    }));

    // When the input is mounted or becomes enabled, take focus.
    // We want MagicInput to handle focus because we repopulate the
    // editor value, so we don't delegate this to PlateRichTextEditor.
    useEffect(() => {
      if (autoFocus && !disabled && !editor.isFallback) {
        focusEditor(editor);
      }
    }, [autoFocus, disabled, editor]);

    /**
     * Parent components pass an onKeyDown handler to the MagicInput component.
     * but we may want to only allow certain actions based on things
     * specific to the editor, for example cursor or line position and that
     * info is only available at the editor level.
     */
    const platePlugins = useMemo(() => {
      const magicInputKeyDownPlugin = createPluginFactory({
        key: "hex_magic_input",
        handlers: {
          onFocus: () => (evt) => {
            pluginProps.current.onFocus_?.(evt);
          },
          onKeyDown: (handlerEditor) => (evt) => {
            if (handlerEditor.isFallback) return;

            // Because the prompt bar has an placeholder we need to check
            // the actual prompt value to correctly determine if its empty
            const isLastLine = isCursorOnLastLine(editorRef.current);
            const isFirstLine = isCursorOnFirstLine(editorRef.current);

            // hacky, but if the keyboard shortcut for the add cell menu is pressed,
            // then we don't want to type the letter å, and instead propogate the
            // event to the shortcut handler instead. Link to handler:
            // https://github.com/hex-inc/hex/blob/6e05e88e471571e111f4ae1efe0d91a55c215cbc/packages/client/components/logic/LogicViewHotKeys.tsx#L283
            if (isAltKeyPress(evt, KeyBoardEventCode.A)) {
              blurEditor(handlerEditor);
              return;
            }

            if (evt.key === Keys.ENTER) {
              if (evt.shiftKey) {
                evt.stopPropagation();
              } else if (pluginProps.current.onSubmit) {
                // if onSubmit provided, do not propagate the event
                // prevent default to avoid adding a newline to the input
                evt.preventDefault();
                evt.stopPropagation();
                pluginProps.current.onSubmit(evt);
              } else {
                // prevent default to avoid adding a newline to the input
                evt.preventDefault();
              }
            } else if (evt.key === Keys.ARROW_DOWN) {
              // We only want to allow special keyboard shortcuts for down
              // if the user is at the last line on the text editor
              if (isLastLine || isEmpty) {
                pluginProps.current.onEditorKeyDown_?.(evt);
              } else {
                evt.stopPropagation();
              }
            } else if (evt.key === Keys.ARROW_UP) {
              // We only want to allow special keyboard shortcuts for down
              // if the user is at the last line on the text editor
              if (isFirstLine || isEmpty) {
                pluginProps.current.onEditorKeyDown_?.(evt);
              } else {
                evt.stopPropagation();
              }
            } else {
              pluginProps.current.onEditorKeyDown_?.(evt);
            }
          },
        },
      });
      return {
        plugins: [magicInputKeyDownPlugin()],
      };
    }, [pluginProps, isEmpty]);

    const commandModeSelected = !useEditMode();
    // Auto-select the entire prompt when the editor is opened
    useEffect(() => {
      // Do not take focus if some other element has focus (example: user is editing the title)
      if (document.activeElement && document.activeElement.tagName !== "BODY") {
        return;
      }

      // If we're in command mode, don't auto-select the prompt bar input
      if (commandModeSelected) {
        return;
      }
      const value = getPromptBarValue();
      if (
        value != null &&
        isRichTextFromEmptyString(value) &&
        !editor.isFallback
      ) {
        focusEditor(editor);
      }
    }, [commandModeSelected, editor, getPromptBarValue]);
    const plateEditor = (
      <PlateRichTextEditor
        allowScroll={false}
        allowedHotkeys={MagicHotKeys.OPEN_DATA_CONNECTION_PICKER}
        autoFocus={false}
        className="MagicInput disable-mousetrap"
        id={plateId}
        placeholder={placeholder}
        placeholderPadding="3px 0px"
        plugins={platePlugins}
        readOnly={disabled}
        richTextFormatting={false}
        shouldCreateMentionPlugin={mentionContext != null}
        size={size}
        onChange={onChange}
      />
    );
    return mentionContext ? (
      <RichTextMentionContext.Provider value={mentionContext}>
        {plateEditor}
      </RichTextMentionContext.Provider>
    ) : (
      plateEditor
    );
  }),
);

export const CharacterTypeLiteral = Union(
  Literal("MENTION_INPUT"),
  Literal("MENTION_WITH_CHILD"),
  Literal("EMPTY_MENTION"),
  Literal("TEXT_AT"),
  Literal("TEXT"),
  Literal("EMPTY"),
);
export type CharacterType = Static<typeof CharacterTypeLiteral>;
export const CharacterType = getNormalEnum(CharacterTypeLiteral);

/**
 * When a user clicks the "@" button we want to open the popover, but
 * we want to do this where their current cursor position is.
 *
 * We also want to take into account if there's alreday a mention in the prompt
 * bar.
 */
const cursorAtMention: <V extends Value = Value>(
  editor: PlateEditor<V>,
) => CharacterType = (editor) => {
  // Check if there is a current selection
  if (!editor.selection || !isCollapsed(editor.selection)) {
    return CharacterType.EMPTY;
  }

  const { anchor } = editor.selection;

  const child_index = anchor.path[0];
  const child = editor.children[child_index];
  const node_index = anchor.path[1];
  const node = child.children.length > 0 ? child.children[node_index] : null;
  const cursor_position = anchor.offset;

  if (!node) {
    return CharacterType.EMPTY;
  }

  if (node.type === "mention_input") {
    // This means someone has typed @{char} where char is >0
    if (node.children && (node.children as TElement[])[0].text) {
      return CharacterType.MENTION_WITH_CHILD;
    } else {
      // This means someone has typed @
      return CharacterType.EMPTY_MENTION;
    }
  }

  if ("text" in node && node.text) {
    const val = (node as TText).text.charAt(cursor_position - 1);
    if (val === " ") {
      return CharacterType.EMPTY;
    } // This means someone has typed @ but backspaced so its not a mention
    else if (val === "@") {
      return CharacterType.TEXT_AT;
    } else {
      return CharacterType.TEXT;
    }
  }
  return CharacterType.EMPTY;
};

const handleAtEvent = (
  editorRef: PlateEditor<RichTextDocument>,
  cursor_val: CharacterType,
) => {
  if (cursor_val === CharacterType.EMPTY_MENTION) {
    // for empty mentions, we should delete the mention
    editorRef.deleteBackward("line");
  } else if (cursor_val === CharacterType.TEXT_AT) {
    // if there's an "@" just delete it
    editorRef.deleteBackward("character");
    editorRef.insertNode({
      type: "mention_input",
      trigger: "@",
      children: [{ text: "" }],
    } as TDescendant);
    focusEditor(editorRef);
  } else if (cursor_val === CharacterType.MENTION_WITH_CHILD) {
    return;
  } else if (cursor_val === CharacterType.EMPTY) {
    // We need to force case because the Node type doesn't have the
    // trigger field but we need that for the mentions to work
    editorRef.insertNode({
      type: "mention_input",
      trigger: "@",
      children: [{ text: "" }],
    } as TDescendant);
    focusEditor(editorRef);
  } else if (cursor_val === CharacterType.TEXT) {
    editorRef.insertText(" ");
    // We need to force case because the Node type doesn't have the
    // trigger field but we need that for the mentions to work
    editorRef.insertNode({
      type: "mention_input",
      trigger: "@",
      children: [{ text: "" }],
    } as TDescendant);
    focusEditor(editorRef);
  } else {
    // We need to force case because the Node type doesn't have the
    // trigger field but we need that for the mentions to work
    editorRef.insertNode({
      type: "mention_input",
      trigger: "@",
      children: [{ text: "" }],
    } as TDescendant);
    focusEditor(editorRef);
  }
};
