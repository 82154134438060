import { ExtractAtomicOperationFromDefinition } from "../../../atomic-operations";
import { ChartColorMappings } from "../../../chart/types.js";
import { ExploreSpec } from "../../../explore/types.js";
import {
  CellId,
  ExploreCellId,
  SemanticProjectId,
} from "../../../idTypeBrands";
import { SemanticCap } from "../../../semanticCaps.js";
import { ExploreCellDataframe } from "../../../sql/dataSourceTableConfig.js";
import { createHexVersionAtomicOperationDefinition } from "../../HexVersionAtomicOperationDefinition.js";
import { CREATE_CELL } from "../createCellOperation.js";

export interface ExploreCellUpdatableFields {
  exploreDataframe: ExploreCellDataframe;
  spec: ExploreSpec;
  colorMappings: ChartColorMappings;
  semanticProjectId: SemanticProjectId | null;
  resultVariables: readonly string[];
  resultIncludeDetailColumns: boolean;
}

const _UpdateExploreCellSafeFields: {
  [key in keyof ExploreCellUpdatableFields]: "";
} = {
  exploreDataframe: "",
  spec: "",
  colorMappings: "",
  semanticProjectId: "",
  resultVariables: "",
  resultIncludeDetailColumns: "",
};

export const UpdateExploreCellSafeFields = Object.keys(
  _UpdateExploreCellSafeFields,
);

const UPDATE_EXPLORE_CELL_TYPE = "UPDATE_EXPLORE_CELL" as const;

export const UPDATE_EXPLORE_CELL = createHexVersionAtomicOperationDefinition({
  type: UPDATE_EXPLORE_CELL_TYPE,
  readAuth: {
    kind: "hasSemanticCap",
    cap: SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
  },
  writeAuth: {
    kind: "and",
    and: [
      {
        kind: "hasSemanticCapOnIdArg",
        cap: SemanticCap.EDIT_PROJECT_CONTENTS,
        idArg: "cellId",
        idType: "Cell",
      },
      {
        kind: "hasSemanticCapOnIdArg",
        idArg: "exploreCellId",
        idType: "ExploreCell",
        cap: SemanticCap.EDIT_PROJECT_CONTENTS,
      },
    ],
  },
  logSafe: ["cellId", "key", "exploreCellId"],
  conflictId: (op) =>
    `${UPDATE_EXPLORE_CELL_TYPE}-${op.payload.exploreCellId}-${op.payload.key}`,
  creationId: (op) => `${CREATE_CELL.type}-${op.payload.cellId}`,
  create: <K extends keyof ExploreCellUpdatableFields>(args: {
    cellId: CellId;
    exploreCellId: ExploreCellId;
    key: K;
    value: ExploreCellUpdatableFields[K];
    suppressAutoRun?: boolean;
  }) => ({
    type: UPDATE_EXPLORE_CELL_TYPE,
    payload: {
      ...args,
    },
  }),
});

export type UPDATE_EXPLORE_CELL = ExtractAtomicOperationFromDefinition<
  typeof UPDATE_EXPLORE_CELL
>;
