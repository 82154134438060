import { CellId, StaticCellId } from "@hex/common";
import { noop } from "lodash";
import {
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  createContext,
  useContext,
  useContextSelector,
} from "use-context-selector";

import { RenderContext } from "../components/renderer/RenderContext.js";

/**
 * Zero indexed, so basically 3
 */
export const MAX_CELL_DEPTH = 2;

export interface CellContext {
  /**
   * Zero-indexed counter for cell depth.
   */
  depth: number;
  cellId: CellId;
  staticCellId: StaticCellId;
  /**
   * Whether we're in an imported component within a project
   * Used to determine what is readonly within an imported component.
   */
  inImportedComponent: boolean;
  /**
   * Render with extra styles for cells the are a direct child
   * of a 'collapsing' cell (Component import or collapsible cell)
   */
  collapseChildStyles: boolean;
  inCreateComponentSidebar: boolean;
  inContextualSidebar: boolean;
  inCellModal: boolean;
  inGraphDetail: boolean;
  renderContext: RenderContext;
  cellHovered: boolean;
  setIsCellHovered: (hovered: boolean) => void;
}

const initialContext: CellContext = {
  depth: -1,
  cellId: "" as CellId,
  staticCellId: "" as StaticCellId,
  inImportedComponent: false,
  inCreateComponentSidebar: false,
  inContextualSidebar: false,
  inCellModal: false,
  inGraphDetail: false,
  renderContext: RenderContext.LOGIC_VIEW,
  cellHovered: false,
  setIsCellHovered: noop,
  collapseChildStyles: false,
};

const Context = createContext<CellContext>(initialContext);

export const CellContextProvider = Context.Provider;

export function createCellContext(context: Partial<CellContext>): CellContext {
  return {
    ...initialContext,
    ...context,
  };
}

// only allow context selector to return a keyed value of the context
// as the selector will re-render when the selected value referentially
// changes, meaning that returning things like custom objects created from
// the context could result in re-rendering issues.
// some interesting discussion here: https://github.com/dai-shi/use-context-selector/issues/19
export function useCellContext<
  T extends
    | number
    | string
    | boolean
    | ((...args: any[]) => any)
    | RenderContext,
>(selector: (context: CellContext) => T): T {
  return useContextSelector(Context, (c) => {
    return selector(c);
  });
}

/*
 * useCellContextValue should almost never be used directly. Instead user useCellContext
 * to select a specific value from the context to minimize unintended re-renders.
 **/
export function useCellContextValue(): CellContext {
  return useContext(Context);
}
