import { OrderByDirection } from "@hex/common";
import React, { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";

import {
  HexButton,
  HexButtonGroup,
} from "../../../hex-components/HexButton.js";
import { HexTooltip } from "../../../hex-components/HexTooltip.js";
import { ArrowDownIcon, ArrowUpIcon } from "../../icons/CustomIcons.js";

import QuickFilterSelect, {
  QuickFilterSelectOption,
} from "./QuickFilterSelect.js";

const createSortOptions = <T extends string>(
  sortOptions: T[],
  sortLabels: Record<T, string>,
): QuickFilterSelectOption<T>[] =>
  sortOptions.map((value) => ({
    title: sortLabels[value],
    value,
  }));

interface GenericSortProps<T extends string> {
  sortType: T;
  sortLabels: Record<T, string>;
  sortDirection: OrderByDirection;
  sortOptions: T[];
  handleChangeOrder: (value: T) => void;
  handleToggleDirection: () => void;
}

export const GenericSortComponent = <T extends string>({
  handleChangeOrder,
  handleToggleDirection,
  sortDirection,
  sortLabels,
  sortOptions,
  sortType,
}: GenericSortProps<T>): JSX.Element => {
  const sortOptionObjects = useMemo(
    () => createSortOptions(sortOptions, sortLabels),
    [sortLabels, sortOptions],
  );

  const getBtnText = useCallback(
    ({ value }: QuickFilterSelectOption<T>): string => sortLabels[value],
    [sortLabels],
  );

  return (
    <StyledHexButtonGroup>
      <QuickFilterSelect<T>
        css={css`
          padding-right: 4px !important;
          padding-left: 4px !important;
        `}
        getButtonText={getBtnText}
        label="Sort by"
        options={sortOptionObjects}
        small={true}
        value={sortType}
        onChange={handleChangeOrder}
      />
      <HexTooltip content="Change sort direction" position="top">
        <HexButton
          css={css`
            padding-left: 4px !important;
            padding-right: 4px !important;
          `}
          minimal={true}
          small={true}
          onClick={handleToggleDirection}
        >
          {sortDirection === "ASC" ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </HexButton>
      </HexTooltip>
    </StyledHexButtonGroup>
  );
};

const StyledHexButtonGroup = styled(HexButtonGroup)`
  align-items: center;
  white-space: nowrap;
`;
