import {
  ChartSeriesType,
  DEFAULT_EXPLORE_OUTPUT_NAME,
  ExploreSpec,
} from "@hex/common";

export function getNewExploreCellResultVariables({
  nextSpec,
  prevResultVariables,
  prevSpec,
}: {
  prevSpec: ExploreSpec;
  prevResultVariables: readonly string[];
  nextSpec: ExploreSpec;
}): readonly string[] {
  // was not outputting variables, so continue outputting nothing
  if (prevResultVariables.length === 0) {
    return [];
  }

  // pivot always outputs one variable so if there are more than one
  // being output, reduce to one
  if (nextSpec.visualizationType === "pivot-table") {
    return [prevResultVariables[0]];
  }

  // if number of series equals the number of variables being output,
  // then continue outputting the same variables
  if (nextSpec.chartConfig.series.length === prevResultVariables.length) {
    return prevResultVariables;
  }

  return nextSpec.chartConfig.series.map((newSeries, i) => {
    const prevSeriesIdx = prevSpec.chartConfig.series.findIndex(
      (prevSeries) => prevSeries.id === newSeries.id,
    );
    if (prevSeriesIdx !== -1) {
      return prevResultVariables[prevSeriesIdx];
    } else {
      return getExploreChartSeriesResultVariableName(
        DEFAULT_EXPLORE_OUTPUT_NAME,
        newSeries.type,
        i,
      );
    }
  });
}

export function getExploreChartSeriesResultVariableName(
  defaultOutputName: string = DEFAULT_EXPLORE_OUTPUT_NAME,
  chartType: ChartSeriesType,
  index: number,
): string {
  return `${defaultOutputName}_${chartType}_${index + 1}`;
}
