import microMemoize, { MicroMemoize } from "micro-memoize";
import { useRef } from "react";

/**
 * Returns a memoized version of `func` for the lifetime of the component.
 * Only the `func` and `opts` provided on first render will have an effect.
 */
export function useMicroMemoize<T extends Function>( // eslint-disable-line @typescript-eslint/no-unsafe-function-type
  func: T,
  opts: MicroMemoize.Options = {},
): T {
  const ref = useRef(null as unknown as T);
  if (ref.current == null) {
    ref.current = microMemoize(func, opts);
  }
  return ref.current;
}
