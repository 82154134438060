export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AddDomainResponse: ["ErrorResponse", "Org"],
    AuthConfiguration: ["OpenIdAuthConfiguration"],
    CellContents: [
      "BlockCell",
      "CellGroupCell",
      "ChartCell",
      "CodeCell",
      "CollapsibleCell",
      "ComponentImportCell",
      "DbtMetricCell",
      "DisplayTableCell",
      "ExploreCell",
      "FilterCell",
      "MapCell",
      "MarkdownCell",
      "MetricCell",
      "Parameter",
      "PivotCell",
      "SqlCell",
      "TextCell",
      "VegaChartCell",
      "WritebackCell",
    ],
    CollectionGrantHolder: ["Group", "IntrinsicGroup", "User"],
    CreateOrgResponse: ["ErrorResponse", "Org"],
    DataConnectionSafeResult: ["DataConnection", "UnknownDataConnection"],
    GetSqlFromHqlResponse: ["ErrorResponse", "HqlToSqlResponse"],
    GridElementEntity: ["Cell", "SharedFilter"],
    HexVersionAppUserReason: ["MissingOrgRole", "MissingProjectRole"],
    HexVersionDenialReason: ["AssetAccess", "MissingProjectRole"],
    HexVersionHistoryEventUnion: ["HexAOLog", "HexVersion", "HexVersionAOLog"],
    HexVersionSafeResult: [
      "AssetAccess",
      "HexNotFound",
      "HexVersion",
      "MissingProjectRole",
    ],
    HexVersionViewerReason: [
      "Archived",
      "AssetAccess",
      "MissingOrgRole",
      "MissingProjectRole",
      "NonDraftVersion",
      "OAuthCollaborationNonSessionOwner",
      "OAuthCredsNotShared",
      "Trashed",
    ],
    ParsedPythonCellReferences: [
      "CellReferencesParseError",
      "PythonCellReferences",
    ],
    ProjectGrantHolder: ["ApiClient", "Group", "User"],
    RecentlyUsedDataSource: ["DataSourceTable", "SemanticDataset"],
    Recipient: ["Group", "SlackChannel", "User"],
    ReviewRequestLinkTarget: [
      "ReviewRequestLinkGroupTarget",
      "ReviewRequestLinkUserTarget",
    ],
    SafeOrUnknownComponentReference: [
      "ComponentReference",
      "UnknownComponentReference",
    ],
    SafeOrUnknownHexResult: ["Hex", "UnknownHex"],
    SafeOrUnknownHexVersionResult: ["HexVersion", "UnknownHexVersion"],
    SafeOrUnknownHexesResult: ["Hex", "UnknownHex"],
    SharedAssetGrantHolder: ["Group", "IntrinsicGroup"],
    SharedAssetGrantTarget: [
      "DataConnection",
      "ExternalFileIntegration",
      "File",
      "Secret",
      "SyncRepository",
      "VcsPackage",
    ],
  },
};
export default result;
