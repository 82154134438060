import {
  SortDirection as AgGridSortDirection,
  ColDef,
} from "@ag-grid-community/core";
import { HexOrder, ProjectRole, humanReadableProjectRole } from "@hex/common";
import { identity, sortBy } from "lodash";

import { MinimalCategory } from "../../hooks/useProjectLabelsForHex.js";
import { TERMINOLOGY } from "../../hooks/useTerminologyText.js";
import {
  getCreatorName,
  getDateArchived,
  getDateCreated,
  getDateLastEdited,
  getDateLastPublished,
  getDateLastViewedByMe,
  getDatePublishedAppLastViewed,
  getDateTrashed,
  getHexMetadata,
  getOwnerName,
  getPermissionSummary,
  getViewCount,
  isHexComponent,
} from "../hex-list-2/hex-row/utils.js";

import "../../css/ag-grid-overrides/ag_grid_overrides.scss";

import { SCREEN_READER_ONLY_CLASSNAME } from "./constants.js";
import { ProjectsTableSafeOrUnknownHexFragment } from "./ProjectsTable.generated.js";
import {
  CollectionsCellRenderer,
  CreatorCellRenderer,
  GenericCheckboxCellRenderer,
  GenericTableCellRenderer,
  HumanDateCellRenderer,
  RequiresReviewTableCellRenderer,
  SelectedCellRenderer,
  StatusTableCellRenderer,
  TrashDateCellRenderer,
} from "./table-cells/TableCellRenderers.js";
import {
  AppViewsCellRendererSelector,
  CategoriesCellRendererSelector,
  CollectionAccessCellRendererSelector,
  NameCellRendererSelector,
  PermissionSummaryCellRendererSelector,
} from "./table-cells/TableCellRendererSelectors.js";
import { ProjectsTableColumnId, arrayOfRequiredColIds } from "./types.js";

export type PROJECTS_TABLE_SORT_ORDER =
  | HexOrder
  | "COLLECTION_LINK_PROJECT_ROLE";

// Note: for now we are using a static column order
export const DEFAULT_COLUMN_ORDER: (ProjectsTableColumnId & string)[] =
  arrayOfRequiredColIds([
    ProjectsTableColumnId.NAME,
    ProjectsTableColumnId.STATUS,
    ProjectsTableColumnId.CATEGORIES,
    ProjectsTableColumnId.CREATOR,
    ProjectsTableColumnId.OWNER,
    ProjectsTableColumnId.RECENTLY_VIEWED,
    ProjectsTableColumnId.DATE_CREATED,
    ProjectsTableColumnId.DATE_LAST_EDITED,
    ProjectsTableColumnId.APP_VIEWS,
    ProjectsTableColumnId.DATE_LAST_PUBLISHED,
    ProjectsTableColumnId.DATE_PUBLISHED_APP_LAST_VIEWED,
    ProjectsTableColumnId.MY_ACCESS,
    ProjectsTableColumnId.SHARED_WITH,
    ProjectsTableColumnId.REQUIRES_REVIEW,
    ProjectsTableColumnId.SCHEDULED,
    ProjectsTableColumnId.COLLECTIONS,
  ]);
// Note: these are default hidden column ids used in the initial grid state;
export const DEFAULT_HIDDEN_COLUMNS = [
  ProjectsTableColumnId.CREATOR,
  ProjectsTableColumnId.DATE_CREATED,
  ProjectsTableColumnId.DATE_LAST_EDITED,
  ProjectsTableColumnId.DATE_PUBLISHED_APP_LAST_VIEWED,
  ProjectsTableColumnId.REQUIRES_REVIEW,
  ProjectsTableColumnId.SCHEDULED,
  ProjectsTableColumnId.DATE_ARCHIVED,
];

export const DEFAULT_SORTABLE_COLUMNS = [
  ProjectsTableColumnId.DATE_CREATED,
  ProjectsTableColumnId.DATE_LAST_EDITED,
  ProjectsTableColumnId.DATE_LAST_PUBLISHED,
  ProjectsTableColumnId.APP_VIEWS,
  ProjectsTableColumnId.RECENTLY_VIEWED,
  ProjectsTableColumnId.NAME,
  ProjectsTableColumnId.OWNER,
  ProjectsTableColumnId.CREATOR,
  ProjectsTableColumnId.CATEGORIES,
  ProjectsTableColumnId.STATUS,
  ProjectsTableColumnId.MY_ACCESS,
  ProjectsTableColumnId.COLLECTION_ACCESS,
  ProjectsTableColumnId.DATE_PUBLISHED_APP_LAST_VIEWED,
];

export const SortableProjectColumnUseDefault: {
  [K in typeof DEFAULT_SORTABLE_COLUMNS as string]: boolean;
} = {
  [ProjectsTableColumnId.DATE_CREATED]: true,
  [ProjectsTableColumnId.DATE_LAST_EDITED]: true,
  [ProjectsTableColumnId.DATE_LAST_PUBLISHED]: true,
  [ProjectsTableColumnId.APP_VIEWS]: true,
  [ProjectsTableColumnId.RECENTLY_VIEWED]: true,
  [ProjectsTableColumnId.DATE_ARCHIVED]: true,
  [ProjectsTableColumnId.COLLECTION_ACCESS]: true,
  [ProjectsTableColumnId.DATE_PUBLISHED_APP_LAST_VIEWED]: true,

  // Alphabetically sorted columns. Use reversed default ordering.
  [ProjectsTableColumnId.NAME]: false,
  [ProjectsTableColumnId.OWNER]: false,
  [ProjectsTableColumnId.CREATOR]: false,
  [ProjectsTableColumnId.CATEGORIES]: false,
  [ProjectsTableColumnId.STATUS]: false,
};

type ColumnDefinition<VALUE> = ColDef<
  ProjectsTableSafeOrUnknownHexFragment,
  VALUE
>;

type ColumnConfiguration<VALUE> = Exclude<
  ColumnDefinition<VALUE>,
  "colId" | "headerName"
> & {
  colId: ProjectsTableColumnId & string;
  headerName: string;
};

type ColumnConfigurationMap = Record<
  ProjectsTableColumnId,
  ColumnConfiguration<any>
>;

export const COLUMN_CONFIGURATION: ColumnConfigurationMap = {
  [ProjectsTableColumnId.NAME]: {
    colId: ProjectsTableColumnId.NAME,
    headerName: "Title",
    // Note: Potential ag grid bug here that requries "pinned" to be defined on the column definition explicitly. Will file an issue.
    pinned: "left",
    suppressSizeToFit: true,
    headerClass: ({ context }) =>
      context.canSelect ? undefined : "push-right",
    cellClass: ({ context }) => (context.canSelect ? undefined : "push-right"),
    width: 360,
    cellRendererSelector: NameCellRendererSelector,
  },
  [ProjectsTableColumnId.STATUS]: {
    colId: ProjectsTableColumnId.STATUS,
    headerName: "Status",
    valueGetter({ data }) {
      if (data == null || data.__typename === "UnknownHex") {
        return;
      }
      return getHexMetadata(data, "LIGHT").status;
    },
    cellRenderer: StatusTableCellRenderer,
    minWidth: 136,
  },
  [ProjectsTableColumnId.CATEGORIES]: {
    colId: ProjectsTableColumnId.CATEGORIES,
    headerName: "Categories",
    valueGetter({ data }) {
      if (data == null || data.__typename === "UnknownHex") {
        return;
      }
      return sortBy(getHexMetadata(data, "LIGHT").categories, "name");
    },
    cellStyle: {
      display: "inline-flex",
      alignItems: "center",
    },
    valueFormatter({ value }) {
      if (value != null) {
        return (value as readonly MinimalCategory[])
          .map(({ name }) => name)
          .join(", ");
      }
      return "";
    },
    minWidth: 68,
    cellRendererSelector: CategoriesCellRendererSelector,
  },
  [ProjectsTableColumnId.OWNER]: {
    colId: ProjectsTableColumnId.OWNER,
    headerName: "Owner",
    valueGetter({ data }) {
      if (data == null) {
        return;
      }

      return {
        name: getOwnerName(data, "Workspace User"),
        imageUrl:
          data.owner != null && "imageUrl" in data.owner
            ? data.owner.imageUrl ?? undefined
            : undefined,
      };
    },
    minWidth: 30,
    cellRenderer: CreatorCellRenderer,
  },
  [ProjectsTableColumnId.CREATOR]: {
    colId: ProjectsTableColumnId.CREATOR,
    headerName: "Creator",
    valueGetter({ data }) {
      if (data == null) {
        return;
      }

      return {
        name: getCreatorName(data, "Workspace User"),
        imageUrl:
          "imageUrl" in data.creator
            ? data.creator.imageUrl ?? undefined
            : undefined,
      };
    },
    minWidth: 30,
    cellRenderer: CreatorCellRenderer,
  },
  [ProjectsTableColumnId.DATE_CREATED]: {
    colId: ProjectsTableColumnId.DATE_CREATED,
    headerName: "Created",
    valueGetter({ data }) {
      if (data == null || data.__typename === "UnknownHex") {
        return;
      }
      return getDateCreated(data, false);
    },
    cellRenderer: HumanDateCellRenderer,
  },
  [ProjectsTableColumnId.DATE_LAST_EDITED]: {
    colId: ProjectsTableColumnId.DATE_LAST_EDITED,
    headerName: TERMINOLOGY.lastUpdatedByUserText,
    valueGetter({ data }) {
      if (data == null) {
        return;
      }

      return getDateLastEdited(data, false);
    },
    cellRenderer: HumanDateCellRenderer,
  },
  [ProjectsTableColumnId.RECENTLY_VIEWED]: {
    colId: ProjectsTableColumnId.RECENTLY_VIEWED,
    headerName: TERMINOLOGY.lastViewedByYou,
    valueGetter({ data }) {
      if (data == null || data.__typename === "UnknownHex") {
        return;
      }
      return getDateLastViewedByMe(data, false);
    },
    cellRenderer: HumanDateCellRenderer,
  },
  [ProjectsTableColumnId.DATE_TRASHED]: {
    colId: ProjectsTableColumnId.DATE_TRASHED,
    headerName: "Permanent deletion",
    valueGetter({ data }) {
      if (data == null || data.__typename === "UnknownHex") {
        return;
      }

      return getDateTrashed(data, false);
    },
    cellRenderer: TrashDateCellRenderer,
  },
  [ProjectsTableColumnId.APP_VIEWS]: {
    colId: ProjectsTableColumnId.APP_VIEWS,
    headerName: TERMINOLOGY.appViewText,
    valueGetter({ context, data }) {
      if (data == null || data.__typename === "UnknownHex") {
        return;
      }

      const { viewCountDuration } = context;

      return getViewCount(viewCountDuration, data);
    },
    cellRendererSelector: AppViewsCellRendererSelector,
  },
  [ProjectsTableColumnId.DATE_LAST_PUBLISHED]: {
    colId: ProjectsTableColumnId.DATE_LAST_PUBLISHED,
    headerName: TERMINOLOGY.appLastPublishedText,
    valueGetter({ data }) {
      if (data == null || data.__typename === "UnknownHex") {
        return;
      }
      return getDateLastPublished(data, false);
    },
    cellRenderer: HumanDateCellRenderer,
  },
  [ProjectsTableColumnId.DATE_PUBLISHED_APP_LAST_VIEWED]: {
    colId: ProjectsTableColumnId.DATE_PUBLISHED_APP_LAST_VIEWED,
    headerName: TERMINOLOGY.appLastViewedByAnyoneText,
    valueGetter({ data }) {
      if (data == null || data.__typename === "UnknownHex") {
        return;
      }
      return getDatePublishedAppLastViewed(data, false);
    },
    cellRenderer: HumanDateCellRenderer,
  },
  [ProjectsTableColumnId.MY_ACCESS]: {
    colId: ProjectsTableColumnId.MY_ACCESS,
    headerName: TERMINOLOGY.yourAccessText,
    valueGetter({ data }) {
      if (data == null || data.__typename === "UnknownHex") {
        return;
      }
      return data.effectiveRole;
    },
    valueFormatter({ context, data, value }) {
      if (data == null) {
        return "-";
      }
      const isComponent = isHexComponent(data);

      return value != null
        ? humanReadableProjectRole(
            value as ProjectRole,
            isComponent,
            context.explorerRoleCanViewChange,
          )
        : "_";
    },
    cellRenderer: GenericTableCellRenderer,
  },
  [ProjectsTableColumnId.COLLECTION_ACCESS]: {
    colId: ProjectsTableColumnId.COLLECTION_ACCESS,
    headerName: "Collection access",
    valueGetter({ data }) {
      if (
        data == null ||
        data.__typename === "UnknownHex" ||
        data.collectionHexLink == null
      ) {
        return;
      }

      return { projectRole: data.collectionHexLink.projectRole };
    },
    cellRendererSelector: CollectionAccessCellRendererSelector,
  },
  [ProjectsTableColumnId.SHARED_WITH]: {
    colId: ProjectsTableColumnId.SHARED_WITH,
    headerName: "Shared with",
    valueGetter({ data }) {
      if (data == null || data.__typename === "UnknownHex") {
        return;
      }

      return getPermissionSummary({
        grantSummary: data.grantSummary,
        onlyGrantedToCurrentUser: data.onlyGrantedToCurrentUser,
        org: data.org,
        organizationRoleV2: data.organizationRoleV2,
        publicRoleV2: data.publicRoleV2,
        id: data.id,
        __typename: "Hex",
        includeCollections: true,
        summaryRole: ProjectRole.APP_USER,
        hexType: data.hexType,
      });
    },
    cellStyle: {
      display: "flex",
    },
    cellRendererSelector: PermissionSummaryCellRendererSelector,
  },
  [ProjectsTableColumnId.REQUIRES_REVIEW]: {
    colId: ProjectsTableColumnId.REQUIRES_REVIEW,
    headerName: "Reviews",
    valueGetter({ data }) {
      if (data == null || data.__typename === "UnknownHex") {
        return;
      }
      return {
        requiresReview: data.requiresReview,
        resolvedRequiresReview: data.resolvedRequiresReview,
        status: data.status,
      };
    },
    cellRenderer: RequiresReviewTableCellRenderer,
  },
  [ProjectsTableColumnId.SCHEDULED]: {
    colId: ProjectsTableColumnId.SCHEDULED,
    headerName: "Scheduled",
    valueGetter({ data }) {
      if (data == null || data.__typename === "UnknownHex") {
        return;
      }
      return data.hasEnabledSchedules;
    },
    cellRenderer: GenericCheckboxCellRenderer,
  },
  [ProjectsTableColumnId.DATE_ARCHIVED]: {
    colId: ProjectsTableColumnId.DATE_ARCHIVED,
    headerName: "Archived",
    valueGetter({ data }) {
      if (data == null || data.__typename === "UnknownHex") {
        return;
      }
      return getDateArchived(data, false);
    },
    cellRenderer: HumanDateCellRenderer,
  },
  [ProjectsTableColumnId.COLLECTIONS]: {
    colId: ProjectsTableColumnId.COLLECTIONS,
    headerName: "Collections",
    valueGetter({ data }) {
      if (data == null || data.__typename === "UnknownHex") {
        return;
      }
      return data;
    },
    cellRenderer: CollectionsCellRenderer,
  },
};

export const BULK_ACTIONS_CHECKBOX_COLUMN = {
  suppressSizeToFit: true,
  colId: "selected",
  cellStyle: {
    whiteSpace: "normal",
    textOverflow: "unset",
    paddingLeft: "18px",
  },
  headerName: "Selected",
  headerClass: SCREEN_READER_ONLY_CLASSNAME,
  // Note: Potential ag grid bug here that requries "pinned" to be defined on the column definition explicitly. Will file an issue.
  pinned: "left",
  width: 36,
  maxWidth: 36,
  minWidth: 30,
  resizable: false,
  // Note: We use a selector here to allow consumers to override the mapping of data -> hex being passed in.
  // For example, in the case of component imports, the primary object is a ComponentReference, so in that
  // case we map `data => data.hex` rather than just `data => data`.
  cellRendererSelector: () => ({
    component: SelectedCellRenderer,
    params: {
      mapDataToHex: identity,
    },
  }),
  sortable: false,
} satisfies ColumnDefinition<undefined>;

const DEFAULT_SORT_DIRECTIONS = ["desc", "asc"] as AgGridSortDirection[];
/**
 * Should be kept inline with @getDefaultHexOrderByDirection.
 *
 * All columns that represent counts / dates / quantitative values should have a default of DESC.
 * Columns that represent semantic / alphabetical ordering should have a default of ASC, then DESC.
 */
export const getAgGridOrderForColumn = (
  columnId: ProjectsTableColumnId,
): AgGridSortDirection[] => {
  return SortableProjectColumnUseDefault[columnId]
    ? DEFAULT_SORT_DIRECTIONS
    : [...DEFAULT_SORT_DIRECTIONS].reverse();
};

export const initColumnDefs = ({
  columnOrder,
  selectable,
  sortableColumns,
}: {
  columnOrder: ProjectsTableColumnId[];
  selectable: boolean;
  sortableColumns: Set<ProjectsTableColumnId> | null;
}): ColDef[] => {
  const resolvedDefintions = columnOrder.map((columnId) => ({
    ...COLUMN_CONFIGURATION[columnId],
    sortable: sortableColumns != null && sortableColumns.has(columnId),
    sortingOrder: getAgGridOrderForColumn(columnId),
  }));
  return selectable
    ? [BULK_ACTIONS_CHECKBOX_COLUMN, ...resolvedDefintions]
    : resolvedDefintions;
};

export const PINNED_COLUMNS = new Set(
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  DEFAULT_COLUMN_ORDER.filter((id) => {
    return (
      COLUMN_CONFIGURATION[id].pinned != null &&
      COLUMN_CONFIGURATION[id].pinned !== false
    );
  }),
);

// Exclude the name column to support truncation for very long project names, as well as `HEX_TYPE` since it's a static icon column
// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
export const AUTOSIZE_COLUMNS = DEFAULT_COLUMN_ORDER.filter(
  (id) => id !== ProjectsTableColumnId.NAME,
);
