import { Alignment, Classes, DialogProps } from "@blueprintjs/core";
import React, { useCallback, useState } from "react";
import { css } from "styled-components";

import { ResetAnchor } from "../../hex-components/HexLink.js";
import {
  HexButton,
  HexDialog,
  HexDivider,
  HexSwitch,
} from "../../hex-components/index.js";

export interface NewComputePriceDialogProps {
  isOpen: DialogProps["isOpen"];
  onClose: DialogProps["onClose"];
  onConfirm: (doNotAskAgain: boolean) => void;
  hideDoNotAskAgain?: boolean;
}

export const NewComputePriceDialog: React.ComponentType<NewComputePriceDialogProps> =
  React.memo(function NewComputePriceDialog({
    hideDoNotAskAgain,
    isOpen,
    onClose,
    onConfirm,
  }) {
    const [doNotAskAgain, setDoNotAskAgain] = useState(false);
    const toggleDoNotAskAgain = useCallback(() => {
      setDoNotAskAgain(!doNotAskAgain);
    }, [setDoNotAskAgain, doNotAskAgain]);
    const confirm = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        onConfirm(doNotAskAgain);
        onClose?.(event);
      },
      [onConfirm, onClose, doNotAskAgain],
    );

    return (
      <HexDialog
        canEscapeKeyClose={false}
        isCloseButtonShown={true}
        isOpen={isOpen}
        style={{ width: "450px", paddingBottom: "15px" }}
        title="Continue with advanced compute?"
        onClose={onClose}
      >
        <div className={Classes.DIALOG_BODY}>
          Our free beta for Large and Extra Large compute ends soon. Starting
          May 1, 2025, Large and Extra Large compute profiles will be subject to
          the new{" "}
          <ResetAnchor
            css={css`
              &:hover {
                text-decoration: underline;
              }
            `}
            href="https://hex.tech/pricing/?modal=compute"
            target="_blank"
          >
            compute pricing.
          </ResetAnchor>
        </div>
        <HexDivider />
        <div
          className={Classes.DIALOG_FOOTER}
          css={`
            display: flex;
            justify-content: space-between;
            align-items: center;
            &&&&.${Classes.DIALOG_FOOTER} {
              padding: 10px 10px 0px 10px;
            }
          `}
        >
          {!hideDoNotAskAgain && (
            <div>
              <HexSwitch
                alignIndicator={Alignment.LEFT}
                checked={doNotAskAgain}
                label="Don't ask again"
                onChange={toggleDoNotAskAgain}
              />
            </div>
          )}
          <div
            css={`
              display: flex;
              gap: 10px;
            `}
          >
            <HexButton onClick={onClose}>Cancel</HexButton>
            <HexButton intent="primary" onClick={confirm}>
              Confirm
            </HexButton>
          </div>
        </div>
      </HexDialog>
    );
  });
