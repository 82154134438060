import { Classes } from "@blueprintjs/core";
import { OrgRole } from "@hex/common";
import React from "react";
import styled from "styled-components";

import {
  HexButton,
  HexMenu,
  HexMenuDivider,
  HexMenuItem,
  HexPopover,
} from "../../hex-components";
import { useOnClickProps } from "../../hooks/useOnClickProps";
import { CurrentUser } from "../../redux/slices/currentUserSlice.js";
import { useMeQuery } from "../../route/HexRoutes.generated";
import { Routes } from "../../route/routes";
import { CyData } from "../../util/cypress";
import { AdminMenuItems } from "../common/AdminMenuItems";
import { AuthMenuItems } from "../common/AuthMenuItems";
import {
  ConfigureIcon,
  PreferencesIcon,
  SingleChevronDownIcon,
} from "../icons/CustomIcons";

import { QuickSettings } from "./QuickSettings";

export const NavMenu = styled(HexMenu)`
  &&.${Classes.MENU} {
    min-width: 220px;
    max-width: 260px;
    max-height: 80vh;

    box-shadow: none;
  }
`;

const QuickNavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 5px 0;
  gap: 8px;

  .${Classes.POPOVER_TARGET} {
    display: flex;
    min-width: 0;
  }
`;

const UserNameDiv = styled.div`
  line-height: 16px;
  min-width: 0;
`;

const UserOrgDiv = styled.div`
  color: ${({ theme }) => theme.fontColor.DEFAULT};
  line-height: 16px;
  min-width: 0;
`;

interface NavUserMenuNewProps {
  currentUser: CurrentUser;
}

export const NavUserMenuNew: React.FunctionComponent<NavUserMenuNewProps> = ({
  currentUser,
}) => {
  const settingsMenuItemOnClickProps = useOnClickProps({
    to: Routes.SETTINGS.getUrl({}),
  });
  const preferencesMenuItemOnClickProps = useOnClickProps({
    to: Routes.SETTINGS.getUrl({ subView: "preferences" }),
  });

  const { data } = useMeQuery();
  const currentOrg = data?.me?.org;

  if (
    currentUser.orgRole === OrgRole.ANONYMOUS ||
    currentUser.orgRole === OrgRole.EMBEDDED_USER
  ) {
    return null;
  }

  return (
    <QuickNavWrapper>
      <HexPopover
        content={
          <NavMenu>
            <HexMenuItem
              icon={<ConfigureIcon />}
              text="Settings"
              {...settingsMenuItemOnClickProps}
            />
            <AdminMenuItems currentUser={currentUser} />
            <HexMenuDivider />
            <AuthMenuItems currentOrg={currentOrg} />
          </NavMenu>
        }
        css="flex: 1 1 auto;"
        minimal={true}
        placement="bottom-start"
        scrollContent={true}
      >
        <HexButton
          data-cy={CyData.NAV_USER_MENU_NEW}
          ellipsizeText={true}
          minimal={true}
          rightIcon={<SingleChevronDownIcon />}
          small={true}
        >
          <UserOrgDiv className={Classes.TEXT_OVERFLOW_ELLIPSIS}>
            {currentOrg?.displayName}
          </UserOrgDiv>
        </HexButton>
      </HexPopover>
      <HexPopover
        content={
          <NavMenu>
            <HexMenuItem
              data-cy={CyData.SETTINGS}
              icon={<PreferencesIcon />}
              text="Preferences"
              {...preferencesMenuItemOnClickProps}
            />
            <QuickSettings flatten={true} />
          </NavMenu>
        }
        css="flex: none;"
        minimal={true}
        placement="bottom-start"
        scrollContent={true}
      >
        <HexButton
          ellipsizeText={true}
          minimal={true}
          rightIcon={<SingleChevronDownIcon />}
          small={true}
        >
          <UserNameDiv className={Classes.TEXT_OVERFLOW_ELLIPSIS}>
            {currentUser.name}
          </UserNameDiv>
        </HexButton>
      </HexPopover>
    </QuickNavWrapper>
  );
};
