/**
 * Consider reviewing our documentation in Figma when modifying shortcuts:
 * https://www.figma.com/file/9DRbwOk5OMQWygQIRScPVo/Keyboard-shortcuts
 */
export const MagicHotKeys = {
  OPEN_MAGIC_EDIT_BAR: "mod+shift+e",
  OPEN_MAGIC_GENERATE_BAR: "mod+shift+m",
  OPEN_MAGIC_EXPLORE_LOW_POWER_MODE: "mod+shift+m",
  MAGIC_EXPLAIN: "mod+shift+.",
  MAGIC_FIX: "mod+shift+,",
  SPLIT_CTES: "mod+shift+y",
  KEEP_AND_RUN: ["mod+enter", "ctrl+enter"],
  KEEP_IN_NEW_CELL: "alt+enter",
  KEEP_AND_RUN_IN_NEW_CELL: "mod+alt+enter",
  KEEP: "enter",
  OPEN_DATA_CONNECTION_PICKER: "mod+d",
  EXIT_MAGIC_OR_ACTION_BAR: "escape",
};

export const HotKeys = {
  // Global Nav/View
  NEW_PROJECT: "alt+shift+n",
  NEW_COMPONENT: "alt+shift+c",
  NEW_COLLECTION: "alt+shift+l",
  TOGGLE_LEFT_SIDEBAR: "alt+shift+`",
  QUICK_SEARCH: "/",
  QUICK_SEARCH_ALT: "mod+k",
  NAVIGATE_TO_LOGIC: "alt+shift+1",
  NAVIGATE_TO_APP: "alt+shift+2",
  NAVIGATE_TO_SPLITSCREEN: "alt+shift+3",
  TOGGLE_GRAPH: "alt+shift+4",

  // Global Actions
  UNDO_PROJECT_EDIT: "mod+z",
  REDO_PROJECT_EDIT: "mod+shift+z",
  SAVE_VERSION: "mod+s",
  OPEN_COMMAND_PALETTE: "mod+p",
  // No replacement
  OPEN_PUBLISH_DIALOG: "mod+shift+p",
  IMPORT_PROJECT: "alt+shift+u",
  IMPORT_VERSION: "alt+shift+v",
  EXPORT_PROJECT: "alt+shift+e",
  OPEN_PROJECT_SEARCH_ORIGINAL: "mod+shift+f",
  OPEN_PROJECT_SEARCH: "mod+f",
  OPEN_PROJECT_REPLACE: "alt+mod+f",
  OPEN_DEVTOOLS: "mod+shift+i",
  SELECT_CELL: "mod+click",

  // Command Mode - Also see create cell shortcut keys in `cellRegistry.tsx`
  ENTER_COMMAND_MODE: "escape",
  ENTER_COMMAND_MODE_VIM: "shift+escape",
  EXIT_COMMAND_MODE: "enter",
  OPEN_COMMAND_PALETTE_FROM_COMMAND_MODE: "p",
  SAVE_VERSION_FROM_COMMAND_MODE: "s",
  SELECT_RANGE_UP: ["shift+up", "shift+k"],
  SELECT_RANGE_DOWN: ["shift+down", "shift+j"],
  SELECT_CELL_ABOVE: ["up", "k"],
  SELECT_CELL_BELOW: ["down", "j"],
  MOVE_CELL_UP: ["mod+up", "mod+k"],
  MOVE_CELL_DOWN: ["mod+down", "mod+j"],
  COPY_CELL: ["c", "mod+c"],
  CUT_CELL: ["x", "mod+x"],
  CONVERT_CODE_TO_MARKDOWN: "m",
  CONVERT_MARKDOWN_TO_CODE: "y",
  DELETE_CELL: "d d",
  ADD_CELL_ABOVE: "a",
  ADD_CELL_BELOW: "b",
  UNDO: "z",
  FORMAT_SELECTED_CELLS: "f s",
  FORMAT_ALL_CELLS: "f a",
  FULL_SCREEN_CELL: "f f",
  OPEN_MAGIC_BAR: "mod+g",

  // ADD CELLS - keep this in sync with other places where we check for this key
  // press - see KeyBoardEventCode.A
  OPEN_ADD_CELL_MENU: "alt+a",

  // EDIT MODE
  SPLIT_CELL: ["ctrl+shift+-", "ctrl+shift+s"],

  // Run Modes
  ALL_CELLS: "mod+shift+a", // "Auto" in Reactive mode
  CELL_AND_UPSTREAM: "mod+shift+s",
  CELL_ONLY: "mod+shift+d",

  // Run Options
  RESTART_AND_RUN_ALL: "alt+r",
  RUN_CELL: ["mod+enter", "ctrl+enter"],
  RUN_CELL_SELECT_BELOW: "shift+enter",
  RUN_CELL_INSERT_BELOW: "alt+enter",
  CLEAR_CACHE_AND_RUN_CELL: "mod+shift+enter",
  CLEAR_CACHE_AND_RUN_CELL_WITH_TRACE: "mod+shift+enter+s",

  // Contextual or non-global
  //// When within a comment modal
  ADD_COMMENT: "mod+enter",
  PUBLISH_VERSION: "mod+enter",
  CREATE_PROJECT: "mod+enter",

  //// When within the schema browser
  QUERY_TABLE: "mod+enter",

  //// When within the workspace search modal
  OPEN_SEARCH_RESULT: "enter",
  OPEN_SEARCH_RESULT_NEW_TAB: "mod+enter",

  PRESENT_FROM_CURRENT_TAB: "mod+.",
  PRESENT_FROM_START: "shift+mod+.",

  //// When within the graph view
  GRAPH_REFLOW: "ctrl+r",
  GRAPH_ZOOM_IN: "meta+shift+=",
  GRAPH_ZOOM_OUT: "meta+-",
  GRAPH_ZOOM_FIT: "shift+1",
  GRAPH_ZOOM_100: "shift+0",

  // Jumps in Notebook view
  JUMP_TO_TOP: "alt+up",
  JUMP_TO_BOTTOM: "alt+down",
  CELL_HISTORY: "shift+h",
  JUMP_CELL_FORWARD: "shift+]",
  JUMP_CELL_BACKWARD: "shift+[",

  ...MagicHotKeys,
};
